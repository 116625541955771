import {ToastContainer, toast} from "react-toastify";

export const alert = (msg = null, res) => {
  return toast.promise(res, {
    pending: "Loading...",
    success: msg,
    error: {
      render({data}) {
        return data.code === "auth/code-expired"
          ? "OTP Expired !"
          : "Incorrect OTP !";
      },
    },
  });
};

export const alertPromise = (res) => {
  return toast.promise(res, {
    pending: "Loading...",
    success: {
      render({data}) {
        return data?.data?.message;
      },
    },
    error: {
      render({data}) {
        return (
          data?.response?.data?.message ||
          data?.response?.data?.errorMessage ||
          data?.response?.data?.status
        );
      },
    },
  });
};

export const AlertToast = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={2}
    />
  );
};

export const alertError = (msg) => {
  return toast.error(msg);
};

export const alertSuccess = (msg) => {
  return toast.success(msg);
};
