import React, { useState } from "react";
import privateAPI from "api/privateAPI";
import { appUserStore } from "stores/appUser";
import { alertSuccess, alertError } from "components/toast/appAlert";
import "./ChangeProfilePic.css";
import { MdUploadFile } from "react-icons/md";

const ChangeProfilePicture = ({ setProfileShow }) => {
  const [photo, setPhoto] = useState("");
  const user = appUserStore((state) => state.user);
  const updateProfile = appUserStore((state) => state.updateProfileImage);

  const handleChangeProfile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("userId", user?.id);
    formData.append("oldImage", user?.profile_img);
    try {
      const res = await privateAPI.post("/profile-picture", formData);
      e.target.reset();
      updateProfile(res?.data?.appData?.imageName);
      alertSuccess(res?.data?.message);
      setProfileShow(false);
    } catch (e) {
      alertError("Invalid Profile Error Try again");
    }
  };

  return (
    <div className="change_profile">
      <form onSubmit={handleChangeProfile} encType="multipart/form-data">
        <label className="filebutton">
          <MdUploadFile />
          <small className="d-block text-secondary">
            Drag and Drop or browse to Choose a Profile Picture file
          </small>
          <span>
            <input
              type="file"
              id="myfile"
              accept=".png, .jpg, .jpeg"
              name="photo"
              onChange={(e) => setPhoto(e.target.files[0])}
              required
            />
          </span>
        </label>
        <div className="text-center">
          <button type="submit" className="app_btn primary rounded mt-3">
            Change Image
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeProfilePicture;
