import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "assets/img/new_bg_home.jpeg";
import img2 from "assets/img/carousel2.webp";
import img3 from "assets/img/carousel3.jpg";
import img4 from "assets/img/carousel5.jpg";
import "./homeslider.css";
import {NavLink} from "react-router-dom";
import useView from "hooks/useView";

const HomeCarousel = () => {
  const view = useView();
  return (
    <>
      <div className="home_bg_banner"></div>
      <div className={`home_slider ${view} position-relative container-fluid`}>
        <Carousel fade controls="false" indicators="false" interval={3000}>
          <Carousel.Item>
            <img className="d-block w-100" src={img1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img4} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
        <div className="slider_content">
          <h1 className="display-1 fw-bolder text-white">SICBMA</h1>
          <h4 className="text-white mb-4">
            South India Corrugated Box Manufacturers Association is an
            association of corrugated box manufacturers in Tamilnadu
          </h4>
          <NavLink className="join_btn" to="/user/auth/signup">
            Join Member
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default HomeCarousel;
