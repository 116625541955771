import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBinLine} from "react-icons/ri";
import {SERVER_PUBLIC} from "config/app-config";
import img from "assets/img/profile.jpg";
import "./ViewCommittee.css";
import AppModal from "components/modals/AppModal";
import MemberDelete from "components/modals/MemberDelete";
import {useDeleteCancelUser} from "stores/useDeleteCancelUser";
import {useNavigate} from "react-router-dom";
import {AiFillEye} from "react-icons/ai";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import Tooltip from "components/Tooltip/Tooltip";
import CommitteeView from "components/modals/CommitteeView";
import {useViewData} from "stores/useViewData";

const ViewCommittee = () => {
  const {data, loading, error} = useFetch(`/committee`);
  console.log(data);
  useInternalServer(error);
  const navigate = useNavigate();

  const {deleteConfirmModal, setDeleteConfirmModal, handleClick, handleDelete} =
    useDeleteCancelUser();
  const {setViewData, viewModal, setViewModal} = useViewData();

  if (loading) return <Loading />;

  const columns = [
    {
      text: "Profile",
      dataField: "profile_image",
      formatter: (col, row) => (
        <div className="profileImgTable">
          <img
            src={
              row.profile_image
                ? `${SERVER_PUBLIC}/committee_profile/${row.profile_image}`
                : img
            }
            alt=""
            className="img-fluid"
          />
          <a
            href={
              row.profile_image
                ? `${SERVER_PUBLIC}/committee_profile/${row.profile_image}`
                : img
            }
            target="_blank"
            rel="noreferrer"
          >
            view
          </a>
        </div>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Position ",
      dataField: "posting",
      sort: true,
    },
    {
      text: "Email ",
      dataField: "email",
      sort: true,
    },

    {
      text: "Mobile",
      dataField: "phone",
      sort: true,
    },
    {
      text: "Address",
      dataField: "address",
      sort: true,
    },
    {
      text: "Actions",
      dataField: "visible",
      formatter: (col, row) => (
        <div className="btn-group text-white" role="group">
          <Tooltip title="View Member">
            <span
              className="vm_btn btn success text-white"
              onClick={() => setViewData(row)}
            >
              <AiFillEye />
            </span>
          </Tooltip>
          <Tooltip title="Delete Member">
            <span
              type="button"
              className="vm_btn btn danger text-white"
              onClick={() => {
                handleClick(row.id, "delete");
              }}
            >
              <RiDeleteBinLine />
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span
              type="button"
              className="vm_btn btn bg-secondary text-white"
              onClick={() =>
                navigate(`/dashboard/create-committee?cid=${row?.id}`)
              }
            >
              <BiEdit />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="viewcommittee">
      <Breadcrumbs current="Create Committee Member">
        View Committee Member
      </Breadcrumbs>
      <AppModal
        show={deleteConfirmModal}
        changeShow={setDeleteConfirmModal}
        title="Delete Confirmation"
      >
        <MemberDelete
          title="Do you want Delete Committee Member !"
          setShow={setDeleteConfirmModal}
          handleDelete={() => handleDelete("delete-cmember")}
        />
      </AppModal>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Member Details"
      >
        <CommitteeView />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{sortCaret}}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewCommittee;

// const columns = [
//   {
//     name: "Name",
//     selector: (row) => row.name,
//     sortable: true,
//   },
//   {
//     name: "Position",
//     selector: (row) => row.posting,
//     sortable: true,
//     style: {
//       width: "10px",
//     },
//   },
//   {
//     name: "E-mail",
//     selector: (row) => row.email,
//     sortable: true,
//     hide: Media.MD,
//     style: {
//       width: "10px",
//     },
//   },
//   {
//     name: "Mobile",
//     selector: (row) => row.phone,
//     sortable: true,
//   },
//   {
//     name: "Address",
//     selector: (row) => row.address,
//     sortable: true,
//     hide: Media.MD,
//     style: {
//       width: "10px",
//     },
//   },
//   {
//     name: "Actions",
//     selector: (row) => (
//       <div>
//         <span className="vm_btn text-primary me-2 cursor-pointer">
//           <AiOutlineEye
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="View"
//           />
//         </span>
//         <span
//           type="button"
//           className="vm_btn text-danger"
//           onClick={() => {
//             handleClick(row.id, "delete");
//           }}
//         >
//           <RiDeleteBinLine
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Delete"
//           />
//         </span>
//         <span
//           type="button"
//           className="vm_btn ms-2 text-success"
//           onClick={() =>
//             navigate(`/dashboard/create-committee?cid=${row?.id}`)
//           }
//         >
//           <BiEdit
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Edit"
//           />
//         </span>
//       </div>
//     ),
//   },
// ];
