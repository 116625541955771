import React, { useState } from "react";
import { SERVER_PUBLIC } from "config/app-config";
import { NavLink } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import useUrlQuery from "hooks/useUrlQuery";
import "./Pdf.css";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";
import DashLoading from "components/loading/DashLoading";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pdf = () => {
  const [numPages, setNumPages] = useState(null);
  const query = useUrlQuery("pdf");
  const { data, loading, error } = useFetch("/pdf");
  useInternalServer(error);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (loading) return <DashLoading />;

  if (query)
    return (
      <Document
        file={`${SERVER_PUBLIC}/pdf/${query}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container text-center"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    );

  return (
    <div className="ViewMaterials  rounded-2">
      <Breadcrumbs current="PDF">PDF</Breadcrumbs>
      <div className="container text-center pb-3 px-2 rounded-2 ">
        <div className="row mt-2">
          {data?.appData?.map((pdf, i) => (
            <div className="col-12 col-md-4 col-lg-3" key={i}>
              <div className="pdf_box position-relative bg-white w-100 rounded ">
                <img
                  src={`${SERVER_PUBLIC}/pdf/${pdf?.image}`}
                  className="img-fluid "
                  alt=""
                />
                <div className="content">
                  <h6 className="app_head card_title"> {pdf?.title}</h6>
                  <p className="pdf_head">Description</p>
                  <p className="card_preview">{pdf?.pdfdesc}</p>
                  <NavLink to={`?pdf=${pdf?.pdffile}`}></NavLink>
                  <button className="app_btn primary">Click to View</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pdf;
