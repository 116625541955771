import React from "react";
import { MdUploadFile } from "react-icons/md";
import { usePictureUpdate } from "stores/usePictureUpdate";

const ChangeEventPicture = ({ handleUpdate, api, pdf = false }) => {
  const { updatePayload } = usePictureUpdate((state) => state);

  return (
    <div className="change_profile">
      <form
        onSubmit={(e) => handleUpdate(e, api)}
        encType="multipart/form-data"
      >
        <label className="filebutton">
          <MdUploadFile />
          <small className="d-block text-secondary">
            {pdf
              ? "Drag and Drop or browse to Choose a Update PDF file"
              : "Drag and Drop or browse to Choose a Update Image file"}
          </small>
          <span>
            <input
              type="file"
              id="myfile"
              accept={pdf ? ".pdf" : ".png, .jpg, .jpeg"}
              name="photo"
              onChange={(e) => updatePayload(e.target.files[0])}
              required
            />
          </span>
        </label>
        <div className="text-center">
          <button type="submit" className="app_btn primary rounded mt-3">
            {pdf ? "Change PDF" : "Change Image"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeEventPicture;
