import React from "react";
import Header from "components/main/header/Header";
import Contact from "components/main/contact/Contact";

const ContactPage = () => {
  return (
    <>
      <Header title="Contact" />
      <Contact />
    </>
  );
};

export default ContactPage;
