import React from "react";
import { useViewData } from "stores/useViewData";
import checkDateDiff from "utils/checkDateDiff";

const ViewEvent = (data) => {
  const { viewData } = useViewData((state) => state);

  return (
    <div>
      <h6 className="m-0">Name</h6>
      <p>{viewData?.name}</p>
      <h6 className="m-0">Venue</h6>
      <p>{viewData?.venue}</p>
      <h6 className="m-0">Venue Address</h6>
      <p>{viewData?.venue_address}</p>
      <h6 className="m-0">Venue City</h6>
      <p>{viewData?.venue_city}</p>
      <h6 className="m-0">Date</h6>
      <p>{checkDateDiff(viewData?.date).formatDate}</p>
      <h6 className="m-0">Price</h6>
      <p>{viewData?.price}</p>
      {viewData?.cancel_reason && (
        <>
          <h6 className="m-0">Cancel Reason</h6>
          <p>{viewData?.cancel_reason}</p>
        </>
      )}
    </div>
  );
};

export default ViewEvent;
