export const UserData = [
  {
    id: 1,
    year: "Active Member",
    userGain: 10000,
  },
  {
    id: 2,
    year: "Inactive Member",
    userGain: 9677,
  },
];
