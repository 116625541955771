import React from "react";
import HomeCarousel from "components/main/homeSlider/HomeCarousel";
import About from "components/main/about/About";
import PresidentMsg from "components/main/presidentMsg/PresidentMsg";
import Gallery from "components/main/gallery/Gallery";
import Contact from "components/main/contact/Contact";
import Classified from "components/main/classified/Classified";

const IndexPage = () => {
  return (
    <>
      <HomeCarousel />
      <About />
      <PresidentMsg />
      <Gallery />
      <Classified />
      <Contact />
    </>
  );
};

export default IndexPage;
