import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "assets/img/SPSS2636.JPG";
import img2 from "assets/img/SPSS2874.JPG";
import img3 from "assets/img/SPSS2654.JPG";
import img4 from "assets/img/SPSS3020.JPG";
import {NavLink} from "react-router-dom";
import useView from "hooks/useView";
import "./galler.css";
import {MdDoubleArrow} from "react-icons/md";

const Fade = () => {
  const view = useView();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`gal_slider ${view}`}>
      <div className="container">
        <h2>
          <span>Our</span> Gallery
        </h2>
        <Slider {...settings}>
          <div>
            <img className=" img-fluid " src={img1} alt="First slide" />
          </div>
          <div>
            <img className=" img-fluid " src={img2} alt="First slide" />
          </div>
          <div>
            <img className=" img-fluid " src={img3} alt="First slide" />
          </div>
          <div>
            <img className=" img-fluid " src={img4} alt="First slide" />
          </div>
          <div>
            <img className=" img-fluid " src={img1} alt="First slide" />
          </div>
          <div>
            <img className=" img-fluid " src={img2} alt="First slide" />
          </div>
        </Slider>
        <div className="text-end">
          <NavLink
            to="/gallery"
            className="book_btn text-decoration-none py-2 px-3 mt-4"
          >
            View more <MdDoubleArrow />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Fade;

// export default class Fade extends Component {
//   render() {
//     var settings = {
//       dots: false,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 4,
//       slidesToScroll: 1,
//       initialSlide: 0,
//       responsive: [
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 3,
//             slidesToScroll: 3,
//             infinite: true,
//             dots: false,
//           },
//         },
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 2,
//             initialSlide: 2,
//           },
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//           },
//         },
//       ],
//     };
//     return (
//       <div className={styles.gal_slider}>
//         <div className="container">
//           <h2>
//             <span>Our</span> Gallery
//           </h2>
//           <Slider {...settings}>
//             <div>
//               <img className=" img-fluid " src={img1} alt="First slide" />
//             </div>
//             <div>
//               <img className=" img-fluid " src={img2} alt="First slide" />
//             </div>
//             <div>
//               <img className=" img-fluid " src={img3} alt="First slide" />
//             </div>
//             <div>
//               <img className=" img-fluid " src={img4} alt="First slide" />
//             </div>
//             <div>
//               <img className=" img-fluid " src={img1} alt="First slide" />
//             </div>
//             <div>
//               <img className=" img-fluid " src={img2} alt="First slide" />
//             </div>
//           </Slider>
//           <NavLink to="/gallary">
//             View more
//             <MdDoubleArrow className="ms-2" />
//           </NavLink>
//         </div>
//       </div>
//     );
//   }
// }
