import React, { useState } from "react";
import "./DashSidebar.css";
import { FiGrid } from "react-icons/fi";
import img from "assets/img/profile.jpg";
import { AiFillHome } from "react-icons/ai";
import dashMenus from "config/dashMenu";
import { TbPower } from "react-icons/tb";
import { appUserStore } from "stores/appUser";
import { Link } from "react-router-dom";
import toFirstCapital from "utils/toFirstCapital";
import decryptRoleId from "utils/decryptRoleId";
import { SERVER_PUBLIC } from "config/app-config";
import logout from "utils/logout";
import AppModal from "components/modals/AppModal";
import { MdPersonAddAlt1, MdPayments } from "react-icons/md";
import ChangeProfilePicture from "../ChangeProfilePic/ChangeProfilePic";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";

const DashSidebar = () => {
  const user = appUserStore((state) => state.user);
  const { logoutUser } = appUserStore((state) => state);
  const menus = dashMenus(user?.role);
  const [profileShow, setProfileShow] = useState(false);

  const navigate = useNavigate();

  return (
    <aside id="sidebar" className="sidebar">
      <AppModal
        title="Change Profile Picture"
        show={profileShow}
        changeShow={setProfileShow}
      >
        <ChangeProfilePicture setProfileShow={setProfileShow} />
      </AppModal>
      <div className="text-center logo mb-4">
        <h4>SICBMA</h4>
      </div>
      <div className="text-center">
        <a href="#index">
          <img
            src={`${
              user?.profile_img
                ? `${SERVER_PUBLIC}/profile_pictures/${user?.profile_img}`
                : img
            }`}
            alt="img"
            className="profile_img"
            onClick={() => setProfileShow(true)}
          />
        </a>
        <h6 className="mt-3 mb-1 mb-0 text-secondary name">
          {toFirstCapital(user?.name)}
        </h6>
        <small className="mb-0 text-secondary">
          {decryptRoleId(user?.role[0])}
        </small>
        <span className="d-block">
          <Tooltip title="Home Page">
            <NavLink to="/">
              <AiFillHome className="text-success" />
            </NavLink>
          </Tooltip>
          <Tooltip title="Logout">
            <span className="text-danger ms-3 fs-5 cursor-pointer">
              <TbPower onClick={() => logout(navigate, logoutUser)} />
            </span>
          </Tooltip>
        </span>
      </div>
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-heading">Navigation</li>
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <FiGrid className="me-2" />
            <span>Dashboard</span>
          </Link>
        </li>

        {user?.role[0] === "1122" && !user?.member_id && (
          <li className="nav-item">
            <Link
              to="/dashboard/join-member"
              className="nav-link app_btn success"
            >
              <MdPersonAddAlt1 className="me-2 fs-5" />
              Join Memeber
            </Link>
          </li>
        )}

        {user?.member_id && !user?.payment_id && (
          <li className="nav-item">
            <Link
              to="/dashboard/subscription"
              className="nav-link app_btn success"
            >
              <MdPayments className="me-2 fs-5" />
              Make Payment
            </Link>
          </li>
        )}

        <li className="nav-heading">Pages</li>
        {menus?.map((menu, i) => (
          <span key={i}>
            {menu?.link ? (
              <li className="nav-item">
                <Link className="nav-link collapsed" to={menu?.link}>
                  {menu?.icon}
                  <span>{menu?.name}</span>
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target={`#${menu?.name}`}
                  data-bs-toggle="collapse"
                  href="#dropdowns"
                >
                  {menu?.icon}
                  <span>{menu?.name}</span>
                  <i className="bi bi-chevron-down ms-auto d-flex"></i>
                </a>
                <ul
                  id={menu?.name}
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  {menu?.subItem?.map((item, i) => (
                    <li key={i}>
                      <Link to={item?.link} className="active">
                        <span>{item?.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </span>
        ))}
      </ul>
    </aside>
  );
};

export default DashSidebar;
