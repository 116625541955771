import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { alertError } from "components/toast/appAlert";
import sendOTP from "services/auth/sendOTP";
import verifyOTP from "services/auth/verifyOTP";
import AppModal from "components/modals/AppModal";
import OTPModal from "components/modals/OTPModal";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";
import AdsSlider from "components/adsModals/AdsSlider";

const ForgetPass = () => {
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  const [press, setPress] = useState(false);
  const [otpResponse, setOTPResponse] = useState("");
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { data, loading, error } = useFetch("/ads/public?position=Login Page");
  useInternalServer(error);

  useEffect(() => {
    const otpPhone = localStorage.getItem("app-reset-password");
    const container = document.querySelector(".container");
    if (otpPhone && !container.classList.contains("active"))
      localStorage.removeItem("app-reset-password");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phone.length !== 10) return alertError("Invalid Phone Number !");
    try {
      const mobile = "+91" + phone;
      setPress(true);
      const otpInfo = await sendOTP(mobile);
      setShow(otpInfo?.status);
      setOTPResponse(otpInfo?.otpAuthRes);
      e.target.reset();
    } catch (err) {
      return alertError(err?.responsse?.data?.message);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      await verifyOTP(otpResponse, OTP, setShow);
      localStorage.setItem("app-reset-password", phone);
      setPress(false);
      navigate("/user/auth/reset-password");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="user signinBx">
      <AppModal show={show} changeShow={setShow} title="OTP Verification">
        <OTPModal setOTP={setOTP} handleVerify={handleVerify} />
      </AppModal>
      <div className="imgBx">
        <AdsSlider data={data?.appData} />
      </div>
      <div className="formBx">
        <form onSubmit={handleSubmit} className="w-100">
          <h2>Forget Password</h2>
          <input
            type="text"
            placeholder="Enter Mobile Number"
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <div id="recaptcha-container"></div>
          <button
            type="submit"
            className={`fw-bold btn ${press ? "disabled" : ""}`}
          >
            Send OTP
          </button>
          <p className="signup">
            Already have an account ?
            <NavLink to="/user/auth/signin"> Sign In.</NavLink>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgetPass;
