import create from "zustand";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";

export const useDeleteCancelUser = create((set) => ({
  deleteConfirmModal: false,
  cancelConfirmModal: false,
  mid: null,
  cancelReason: null,

  setDeleteConfirmModal: (value) => set({ deleteConfirmModal: value }),
  setCancelConfirmModal: (value) => set({ cancelConfirmModal: value }),

  setCancelReason: (value) => set({ cancelReason: value }),
  handleClick: (mid, type) =>
    set((state) => {
      if (type === "delete") return { deleteConfirmModal: true, mid };
      return { cancelConfirmModal: true, mid };
    }),
  handleDelete: (api) =>
    set(async (state) => {
      state.deleteConfirmModal = false;
      const res = privateAPI.delete(`/${api}/${state.mid}`);
      await alertPromise(res);
      return { deleteConfirmModal: false };
    }),

  handleCancel: (e, api) => {
    e.preventDefault();
    set(async (state) => {
      state.cancelConfirmModal = false;
      const res = privateAPI.post(api, {
        mid: state.mid,
        cancelReason: state.cancelReason,
      });
      await alertPromise(res);
      return { cancelConfirmModal: false };
    });
  },
}));
