import React from "react";
import "./Breadcrumbs.css";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight, MdHome } from "react-icons/md";

const Breadcrumbs = (props) => {
  return (
    <div className="breadcrumbs">
      <h4 className="text-secondary m-0">{props.children}</h4>
      <div className="d-flex align-items-center">
        <Link to="/dashboard" className="d-flex align-items-center">
          <MdHome className="d-flex" />
          Home
        </Link>
        <MdKeyboardArrowRight />
        <Link className="text-secondary">{props.current}</Link>
      </div>
    </div>
  );
};

export default Breadcrumbs;
