import React from "react";
import useInternalServer from "hooks/useInternalServer";
import DashLoading from "components/loading/DashLoading";
import {appUserStore} from "stores/appUser";
import useFetch from "hooks/useFetch";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import {NavLink} from "react-router-dom";
import checkDateDiff from "utils/checkDateDiff";

const ViewMemberInvoice = () => {
  const user = appUserStore((state) => state.user);
  const {data, loading, error} = useFetch(
    `/member-invoice/${user?.id}?memberId=${user?.member_id}`
  );
  if (error) return <h2>Relogin</h2>;
  if (loading) return <DashLoading />;

  console.log(data);

  const columns = [
    {
      text: "Transaction Id",
      sort: true,
      dataField: "transId",
      formatter: (col, row) => {
        return <NavLink to={`/dashboard/invoice/${col}`}>#{col}</NavLink>;
      },
    },
    {
      text: "Reason",
      sort: true,
      dataField: "reason",
    },
    {
      text: "Amount",
      sort: true,
      dataField: "amount",
    },
    {
      text: "Payment Date",
      dataField: "payment_date",
      sort: true,
      formatter: (col, row) => {
        return <span>{checkDateDiff(col).formatDate}</span>;
      },
    },
  ];

  return (
    <div className="viewmemberinvoice">
      <Breadcrumbs current="View Invoice">View Invoice</Breadcrumbs>

      <ToolkitProvider
        keyField="transId"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{sortCaret}}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewMemberInvoice;
