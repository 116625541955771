import React, { useState } from "react";
import publicAPI from "api/publicAPI";
import { alertPromise } from "components/toast/appAlert";
import logo from "assets/img/logo.jpg";
import { NavLink } from "react-router-dom";
import { appUserStore } from "stores/appUser";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";

const Login = () => {
  const { addLoginUser } = appUserStore((state) => state);
  const [showPassword, setShowPassword] = useState(false);
  const [loginUser, setLoginUser] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const { data, loading, error } = useFetch("/ads/public?position=Login Page");
  useInternalServer(error);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = publicAPI.post("/login", {
      loginUser,
      password,
    });
    const { data } = await alertPromise(res);
    localStorage.clear();
    localStorage.setItem("app-auth", data?.appData?.token);
    addLoginUser(data?.appData?.user);
    e.target.reset();
  };

  return (
    <div className="user signinBx">
      <div className="imgBx">
        <AdsSlider data={data?.appData} />
      </div>
      <div className="formBx">
        <form onSubmit={handleSubmit} className="w-100">
          <div className="logo text-center mb-3">
            <img src={logo} alt="logo" className="img-fluid" />
          </div>
          <h2>Sign In</h2>
          <input
            type="text"
            placeholder="Phone"
            onChange={(e) => setLoginUser(e.target.value)}
            required
          />
          <div className="password form-floating position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="show_hide"
              onClick={() => setShowPassword((state) => !state)}
            >
              <div className="d-flex">
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
            </span>
          </div>

          <button type="submit" className="fw-bold">
            Login
          </button>
          <p className="signup">
            Don't have an account ?
            <NavLink to="/user/auth/signup"> Sign Up.</NavLink>
          </p>
          <p className="signup">
            <NavLink to="/user/auth/forget-password">Forget Password ?</NavLink>
            <NavLink to="/" className="d-block mt-3">
              Home
            </NavLink>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
