import React from "react";
import "./Box.css";
import {BsThreeDotsVertical} from "react-icons/bs";
import {NavLink} from "react-router-dom";

const Box = (props) => {
  return (
    <div className="box">
      <div className="mycard">
        <div className="row">
          <div className="col-6">
            <div className="head">
              <h6>{props.title}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="dropdown text-end">
              <span
                className=""
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BsThreeDotsVertical />
              </span>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink to={props.to} className="dropdown-item">
                    View
                  </NavLink>
                  {/* <a className="dropdown-item" href={props.href}>
                    View
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="body align-content-center">
          <div className="row">
            <div className="col-6">
              <div className={props.color}>{props.icon}</div>
            </div>
            <div className="col-6">
              <div className="count text-end">
                <h5>{props.count}</h5>
                <p className="app_label"> {props.body}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;
