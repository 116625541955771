import React, { useState } from "react";
import MyNavbar from "components/main/navbar/MyNavbar";
import { Outlet } from "react-router-dom";
import Footer from "components/main/footer/Footer";
import MainPageAds from "components/adsModals/MainPageAds";

const MainLayout = () => {
  const [show, setshow] = useState(true);
  return (
    <div className="page_wrapper">
      <MainPageAds show={show} changeShow={setshow} />
      <MyNavbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;
