import React from "react";
import "./footer.css";
import { HiMail } from "react-icons/hi";
import { BsTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaGlobeAfrica } from "react-icons/fa";
import useView from "hooks/useView";

const Footer = () => {
  const view = useView();
  return (
    <div className={`footer ${view}`}>
      <div className="container ">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="abt">
              <h3 className="mb-4">About</h3>
              <p className="me-0 me-lg-5">
                South India Corrugated Box Manufacturers Association is an
                association of corrugated box manufacturers in Tamilnadu, which
                in short is called SICBMA . It was established in the year 1967.
                It has at present about 460+ members spread over Tamilnadu in
                places like Chennai, Coiambatur, Thirupur, Selam, Housur,
                Pondichery, Madurai, Trichi and Tutucorain etc.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3 ">
            <div className="con">
              <h3 className="mb-4">Contact</h3>
              <Link to="/about" className="d-block">
                <HiMail className="me-4" />
                sicbma@gmail.com
              </Link>
              <Link to="/about" className="mt-4 d-block">
                <BsTelephoneFill className="me-4 " />
                +91 44 43023020
              </Link>
              <Link to="/about" className="mt-4 mb-3 d-block">
                <FaGlobeAfrica className="me-4 " />
                www.scibma.com
              </Link>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3 ">
            <div className="links">
              <h3 className="mb-4">Links</h3>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                  <Link to="/">Home</Link>
                  <Link to="/about" className="mt-3">
                    About
                  </Link>
                  <Link to="/" className="mt-3">
                    Comittee
                  </Link>
                  <Link to="/" className="mt-3">
                    Members
                  </Link>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                  <Link to="/gallary" className="mt-3 mt-md-0">
                    Gallery
                  </Link>
                  <Link to="/" className="mt-3">
                    Knowledge
                  </Link>
                  <Link to="/contact" className="mt-3">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="right py-3">
          <p className="m-0 pb-3 text-center">
            Copyright All Rights Reserved to South India Corrugated Box
            Manufacturers Association.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
