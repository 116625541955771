import axios from "axios";
import { SERVER_API } from "config/app-config";

const privateAPI = axios.create({
  baseURL: SERVER_API,
});

privateAPI.interceptors.request.use((config) => {
  const appToken = localStorage.getItem("app-auth");
  if (appToken) {
    config.headers.Authorization = `Bearer ${appToken}`;
  }
  return config;
});

export default privateAPI;
