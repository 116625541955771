import React, {useRef} from "react";
import "./Certificate.css";
import img from "assets/img/certificate.jpg";
import {appUserStore} from "stores/appUser";
import useFetch from "hooks/useFetch";
import checkDateDiff from "utils/checkDateDiff";
import toFirstCapital from "utils/toFirstCapital";
import sign from "assets/img/president_sign.png";
import sign3 from "assets/img/vinoth_tearesur-removebg-preview.png";
import sign2 from "assets/img/babu_sign.png";
import DashLoading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {useReactToPrint} from "react-to-print";

const Certificate = () => {
  const componentRef = useRef();

  const user = appUserStore((state) => state.user);
  const {data, loading, error} = useFetch(`/users?uid=${user?.id}`);
  useInternalServer(error);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (loading) return <DashLoading />;
  return (
    <>
      <Breadcrumbs current="Certificate">My Certificate</Breadcrumbs>
      <button className="btn btn-primary mb-3" onClick={handlePrint}>
        Print
      </button>
      <div className="certificate" ref={componentRef}>
        <img src={img} alt="" className="img-fluid w-100" />
        <div className="name">
          <h1>
            {" "}
            {data?.appData[0] && toFirstCapital(data?.appData[0]?.company_name)}
          </h1>
        </div>
        <div className="address">
          <h5>
            {data?.appData[0] &&
              toFirstCapital(data?.appData[0]?.company_address)}
          </h5>
        </div>
        <div className="issuedate">
          <h6>
            {
              checkDateDiff(data?.appData[0]?.created_at).formatDate.split(
                " "
              )[0]
            }
          </h6>
        </div>
        <div className="validdate">
          <h6>31-03-2023</h6>
        </div>
        <div className="sign1">
          <img src={sign} alt="" className="img-fluid" />
        </div>
        <div className="sign2">
          <img src={sign2} alt="" className="img-fluid" />
        </div>
        <div className="sign3">
          <img src={sign3} alt="" className="img-fluid" />
        </div>
      </div>
    </>
  );
};

export default Certificate;
