import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { appUserStore } from "stores/appUser";
import { alertError } from "components/toast/appAlert";
import "./CardPagination.css";

const CardPagination = (props) => {
  const role = appUserStore((state) => state.user?.role[0]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(props.items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.items?.length / props.itemsPerPage));
  }, [itemOffset, props.items, props.itemsPerPage]);

  const handlePageClick = (event) => {
    if (role === "1122") return alertError("Access Denied ! (Members Only)");
    const newOffset =
      (event.selected * props.itemsPerPage) % props.items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {React.cloneElement(props.children, { currentItems })}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        className="pagination mt-2"
      />
    </>
  );
};

export default CardPagination;
