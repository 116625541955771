import React, {useEffect, useState} from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";
import {AiOutlineCloudUpload} from "react-icons/ai";
import "./CreateEvents.css";
import useUrlQuery from "hooks/useUrlQuery";
import checkDateDiff from "utils/checkDateDiff";
import ReactDatePicker from "react-datepicker";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const CreateEvent = () => {
  let q = useUrlQuery("updateId");
  const navigate = useNavigate();
  const [photo, setPhoto] = useState("");
  const [event, setEvents] = useState({
    name: "",
    venueName: "",
    venueAddress: "",
    venueCity: "",
    eventPrice: "",
    eventDate: "",
  });
  const [oldDate, setOldDate] = useState("");

  useEffect(() => {
    async function loadUpdateEvent() {
      try {
        const {data} = await privateAPI.get(`/events?eid=${q}`);
        const eventInfo = data?.appData?.[0];
        setEvents({
          name: eventInfo?.name || "",
          venueName: eventInfo?.venue || "",
          venueAddress: eventInfo?.venue_address || "",
          venueCity: eventInfo?.venue_city || "",
          eventPrice: eventInfo?.price || "",
        });
        setOldDate(eventInfo?.date);
      } catch (error) {}
    }
    if (q) loadUpdateEvent();
    else {
      setEvents({
        name: "",
        venueName: "",
        venueAddress: "",
        venueCity: "",
        eventPrice: "",
        eventDate: "",
      });
    }
  }, [q]);

  const handleOnChange = (e) => {
    const {name, value} = e.target;
    setEvents({
      ...event,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = new FormData();
    eventData.append("photo", photo);
    eventData.append("name", event.name);
    eventData.append("venueName", event.venueName);
    eventData.append("venueAddress", event.venueAddress);
    eventData.append("venueCity", event.venueCity);
    eventData.append("eventPrice", event.eventPrice);
    if (q) {
      if (event?.eventDate)
        eventData.append("eventDate", moment(event?.eventDate).format());
      else eventData.append("eventDate", moment(oldDate).format());
      const res = privateAPI.put(`/event/${q}`, eventData);
      await alertPromise(res);
      navigate("/dashboard/admin/view-events");
    } else {
      eventData.append("eventDate", moment(event?.eventDate).format());
      const res = privateAPI.post("/events", eventData);
      await alertPromise(res);
      setEvents({
        name: "",
        venueName: "",
        venueAddress: "",
        venueCity: "",
        eventPrice: "",
        eventDate: "",
      });
    }
  };

  return (
    <div className="events">
      <Breadcrumbs current="Post Events">
        {q ? "Update Events" : "Create Events"}
      </Breadcrumbs>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {!q && (
          <div className="uploadimg bg-white py-2 rounded">
            <label className="filebutton">
              <AiOutlineCloudUpload />
              <br />
              <small className="app_label">
                Select Event Brochure or Posters
              </small>
              <span>
                <input
                  type="file"
                  className="app_input"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  required={q ? false : true}
                />
              </span>
            </label>
          </div>
        )}

        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-6 ">
            <div className="bg-white px-3 ">
              <label className="app_label">Event Name</label>
              <input
                type="text"
                className="app_input"
                required={q ? false : true}
                name="name"
                value={event?.name || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bg-white px-3 ">
              <label className="app_label">Venue Name</label>
              <input
                type="text"
                className="app_input"
                required={q ? false : true}
                name="venueName"
                value={event?.venueName || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bg-white px-3 ">
              <label className="app_label">Venue Address</label>
              <input
                type="text"
                className="app_input"
                required={q ? false : true}
                name="venueAddress"
                value={event?.venueAddress || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bg-white px-3 ">
              <label className="app_label">Venue City</label>
              <input
                type="text"
                className="app_input"
                required={q ? false : true}
                name="venueCity"
                value={event?.venueCity || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bg-white px-3 pb-4">
              <label className="app_label">Event Price</label>
              <input
                type="number"
                className="app_input"
                required={q ? false : true}
                name="eventPrice"
                value={event?.eventPrice || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bg-white px-3 pb-4">
              <label className="app_label">
                Event Date
                {q &&
                  `(Old Date -
                ${checkDateDiff(oldDate).formatDate})`}
              </label>
              <ReactDatePicker
                value={moment(event?.eventDate)}
                selected={event.eventDate}
                dateFormat="yyyy-MM-dd hh:mm aa"
                showTimeSelect
                onChange={(date) =>
                  setEvents({
                    ...event,
                    eventDate: date,
                  })
                }
                minDate={moment().toDate()}
                placeholderText="Select a Event Date and Time"
                className="app_input"
                name="eventDate"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <button type="submit" className="app_btn primary rounded mt-3">
          {q ? "Update Event" : "Create Event"}
        </button>
      </form>
    </div>
  );
};

export default CreateEvent;
