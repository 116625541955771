import React from "react";
import img from "assets/img/404_page.png";
import { NavLink } from "react-router-dom";
import styles from "./errorpage.module.scss";

const ErrorPage = () => {
  return (
    <div className={styles.error}>
      <div className="text-center">
        <img src={img} alt="#" className="img-fluid my-4" />
        <div className="col-sm-12 col-md-12 col-lg-3"></div>
        <h1>
          There's <span>NOTHING</span> here...
        </h1>
        <p>maybe the page you're looking for is not found or never existed.</p>
        <NavLink to="/" className="btn btn-primary fs-4 py-2 px-5">
          Home
        </NavLink>
      </div>
    </div>
  );
};

export default ErrorPage;
