import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import img from "assets/img/createacc_bg.jpg";
import privatePage from "utils/privatePage";
import { alertError, alertSuccess } from "components/toast/appAlert";
import publicAPI from "api/publicAPI";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const otpUID = localStorage.getItem("app-reset-password");

  useEffect(() => {
    privatePage(otpUID);
  }, [otpUID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    privatePage(otpUID);
    if (password !== confirmPass)
      return alertError("Password and Confirm Password must be the same");

    try {
      const res = await publicAPI.post("/reset-password", {
        password,
        phone: otpUID,
      });
      alertSuccess(res?.data?.message);
      localStorage.removeItem("app-reset-password");
      navigate("/user/auth/signin");
    } catch (err) {
      console.log(err);
      return alertError(err?.response?.data?.errorMessage);
    }
  };

  return (
    <div className="user signupBx">
      <div className="imgBx">
        <img src={img} alt="" />
      </div>
      <div className="formBx">
        <form onSubmit={handleSubmit} className="w-100">
          <h2>Create New Password</h2>
          <div className="password form-floating position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter New Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="show_hide"
              onClick={() => setShowPassword((state) => !state)}
            >
              <div className="d-flex">
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
            </span>
          </div>

          <input
            type="password"
            placeholder="Enter Confirm Password"
            onChange={(e) => setConfirmPass(e.target.value)}
            required
          />
          <button type="submit" className="fw-bold">
            Reset Password
          </button>
          <p className="signup">
            Already have an account ?
            <NavLink to="/user/auth/signin"> Sign In.</NavLink>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
