import React, {useState} from "react";
import "./DashNavbar.css";
import img from "assets/img/profile.jpg";
import {FiMenu} from "react-icons/fi";
import {BiSearch} from "react-icons/bi";
import {appUserStore} from "stores/appUser";
import {SERVER_PUBLIC} from "config/app-config";
import toFirstCapital from "utils/toFirstCapital";
import decryptRoleId from "utils/decryptRoleId";
import logout from "utils/logout";
import {NavLink} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import ChangePassword from "components/modals/ChangePassword";
import AppModal from "components/modals/AppModal";

const DashNavbar = () => {
  const [show, setShow] = useState(false);
  const user = appUserStore((state) => state.user);
  const navigate = useNavigate();
  const {logoutUser} = appUserStore((state) => state);

  const handleToggle = () => {
    document.querySelector("body").classList.toggle("toggle-sidebar");
  };

  const handleSearchToggle = () => {
    document.querySelector(".search-bar").classList.toggle("search-bar-show");
  };

  return (
    <header id="header" className="header d-flex align-items-center">
      <AppModal show={show} changeShow={setShow} title="Change Password">
        <ChangePassword setShow={setShow} />
      </AppModal>
      <div className="d-flex align-items-center justify-content-between">
        <FiMenu
          className="bi bi-list toggle-sidebar-btn d-flex"
          onClick={handleToggle}
        />
        <h5 className="text-secondary ms-3 mb-0">Dashboard</h5>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li>
            <div className="search-bar">
              <form
                className="search-form d-flex align-items-center"
                method="POST"
                action="#"
              >
                <input
                  type="text"
                  autoFocus
                  name="query"
                  placeholder="Search Pages..."
                />

                <button type="submit" title="Search" className="text-secondary">
                  <BiSearch className="bi bi-search" />
                </button>
              </form>
            </div>
          </li>
          <li className="nav-item d-none search_icon">
            <a
              className="nav-link nav-icon search-bar-toggle"
              onClick={handleSearchToggle}
              href="#i"
            >
              <BiSearch />
            </a>
          </li>

          <li className="nav-item dropdown pe-4 ms-0 ms-lg-4 me-0 me-lg-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#index"
              data-bs-toggle="dropdown"
            >
              <img
                src={`${
                  user?.profile_img
                    ? `${SERVER_PUBLIC}/profile_pictures/${user?.profile_img}`
                    : img
                }`}
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {toFirstCapital(user?.name)}
              </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6 className="mb-1"> {toFirstCapital(user?.name)}</h6>
                <span className="badge bg-success">
                  {decryptRoleId(user?.role[0])}
                </span>
              </li>
              {user?.admin_status !== "1" && (
                <li>
                  <NavLink
                    className="dropdown-item d-flex align-items-center"
                    to="/dashboard/view-profile"
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </NavLink>
                </li>
              )}
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#i"
                >
                  <i className="bi bi-gear"></i>
                  <span onClick={() => setShow(true)}>Change Password</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#index"
                  onClick={() => logout(navigate, logoutUser)}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default DashNavbar;
