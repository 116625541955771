import React from "react";
import "./Videos.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {Player} from "video-react";
import "video-react/dist/video-react.css";
import v1 from "assets/videos/01 Grammage Test-Paper.mp4";
import v2 from "assets/videos/02 Thickness Test.mp4";
import v3 from "assets/videos/04 Bursting Strength  and Burst Factor (1).mp4";

const Videos = () => {
  return (
    <div className="video">
      <Breadcrumbs current="Videos">Videos</Breadcrumbs>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Player>
            <source src={v1} />
          </Player>
          <h5 className="head_video">01 Grammage Test Paper</h5>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Player>
            <source src={v2} />
          </Player>
          <h5 className="head_video">02 Thickness Test</h5>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Player>
            <source src={v3} />
          </Player>
          <h5 className="head_video">04 Bursting Strength</h5>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3"></div>
      </div>
    </div>
  );
};

export default Videos;
