import React from "react";
import Table from "react-bootstrap/Table";
import useFetch from "hooks/useFetch";
import checkDateDiff from "utils/checkDateDiff";
import useInternalServer from "hooks/useInternalServer";
import Loading from "components/loading/DashLoading";
import useView from "hooks/useView";
import "./viewproduction.css";
import { AiFillDelete } from "react-icons/ai";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { appUserStore } from "stores/appUser";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";

const Viewproduction = () => {
  const view = useView();
  const user = appUserStore((state) => state.user);
  const { data, loading, error } = useFetch(`/productions?uid=${user?.id}`);
  useInternalServer(error);

  if (loading) return <Loading />;
  var splitTime = checkDateDiff(data?.appData[0]?.date)
    .formatDate?.split(" ")[1]
    .split(":");

  const handleDelete = async (rid, uid) => {
    const res = privateAPI.delete(`/production/${rid}/${uid}`);
    await alertPromise(res);
  };

  return (
    <>
      <div className={`viewmill ${view}`}>
        <Breadcrumbs current="View Production">View Production</Breadcrumbs>
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-12 p-lg-3 bg-white rounded-2 ">
                <Table borderless>
                  <thead className="mb-5">
                    <tr>
                      <th colSpan={3} className="heading">
                        {user?.name}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="headbody">
                    <tr>
                      <td>Last Updated On</td>
                      <td>
                        {
                          checkDateDiff(
                            data?.appData[data?.appData?.length - 1]?.date
                          ).formatDate?.split(" ")[0]
                        }
                      </td>
                      <td>{`${splitTime[0]}:${splitTime[1]} ${
                        checkDateDiff(
                          data?.appData[data?.appData?.length - 1]?.date
                        ).formatDate?.split(" ")[2]
                      }`}</td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>BF</th>
                        <th>COL</th>
                        <th>Prod Date</th>
                        <th>Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.appData?.map((mill, i) => (
                        <tr key={i}>
                          <td>{mill?.bf}</td>
                          <td>{mill?.col}</td>
                          <td>
                            {
                              checkDateDiff(mill?.date).formatDate?.split(
                                " "
                              )[0]
                            }
                          </td>
                          <td>
                            {`${splitTime[0]}:${splitTime[1]}` +
                              " " +
                              checkDateDiff(mill?.date).formatDate?.split(
                                " "
                              )[2]}
                          </td>
                          <td>
                            <span
                              className="text-danger fs-4 cursor-pointer"
                              onClick={() => handleDelete(mill?.id, mill?.uid)}
                            >
                              <AiFillDelete />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewproduction;
