import React from "react";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import "./userauth.css";
import {useParams} from "react-router";
import ForgetPassword from "./ForgetPassword";
import useView from "hooks/useView";

const UserAuthPage = () => {
  const {page} = useParams();
  const view = useView();

  if (page === "forget-password" || page === "reset-password")
    return <ForgetPassword />;

  return (
    <div className={`user_auth ${view}`}>
      <div className={`container ${page === "signup" ? "active" : ""}`}>
        <Login />
        <Register />
      </div>
    </div>
  );
};

export default UserAuthPage;
