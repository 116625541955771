import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import privateAPI from "api/privateAPI";
import { useState } from "react";
import { alertPromise } from "components/toast/appAlert";
import useFetch from "hooks/useFetch";
import AdsSlider from "components/adsModals/AdsSlider";

const CreateAccount = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const [bankName, setBankName] = useState("");
  const [accNo, setAccNo] = useState("");
  const [openingBalance, setOeningBalance] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankAddress, setBankAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = privateAPI.post("/create-accounts", {
      bankName,
      accNo,
      openingBalance,
      ifscCode,
      bankAddress,
    });
    await alertPromise(res);
    e.target.reset();
  };

  return (
    <div className="createaccounts">
      <Breadcrumbs current="Create Bank Accounts">Create Accounts</Breadcrumbs>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="content">
              <div className="bg-white px-3 py-2">
                <label className="app_label">Bank Name</label>
                <input
                  type="text"
                  className="app_input"
                  required
                  onChange={(e) => setBankName(e.target.value)}
                  autoFocus
                />
                <label className="app_label">Account Number</label>
                <input
                  type="number"
                  className="app_input"
                  required
                  onChange={(e) => setAccNo(e.target.value)}
                />
                <label className="app_label">Opening Balance</label>
                <input
                  type="number"
                  className="app_input"
                  required
                  onChange={(e) => setOeningBalance(e.target.value)}
                />
                <label className="app_label">Bank Address</label>
                <input
                  type="text"
                  className="app_input"
                  required
                  onChange={(e) => setBankAddress(e.target.value)}
                />
                <label className="app_label">IFSC code</label>
                <input
                  type="text"
                  className="app_input"
                  required
                  onChange={(e) => setIfscCode(e.target.value)}
                />
                <div className="text-start py-2">
                  <button type="submit" className="app_btn primary">
                    Create Account
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="ads">
              <AdsSlider data={adsInfo?.data?.appData} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
