import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import toFirstCapital from "utils/toFirstCapital";
import useInternalServer from "hooks/useInternalServer";
import { NavLink } from "react-router-dom";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import { useViewData } from "stores/useViewData";
import InsertPayment from "components/modals/InsertPayment";
import AppModal from "components/modals/AppModal";

const ViewAccounts = () => {
  const { data, loading, error } = useFetch("/accounts");
  useInternalServer(error);
  const { setViewData, viewModal, setViewModal } = useViewData();

  if (loading) return <DashLoading />;

  const handleDefault = async (account_id) => {
    const res = privateAPI.put(`/make-default/${account_id}`);
    await alertPromise(res);
  };

  const columns = [
    {
      text: "Bank Name",
      dataField: "bank_name",
      sort: true,
    },
    {
      text: "Account Number",
      dataField: "account_no",
    },
    {
      text: "Balance",
      sort: true,
      dataField: "opening_balance",
    },
    {
      text: "Bank Address",
      dataField: "bank_address",
    },
    {
      text: "IFSC code",
      dataField: "ifsc_code",
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      formatter: (col, row) => {
        if (col === "active")
          return <span className="badge success">{toFirstCapital(col)}</span>;
        return <span className="badge danger">{toFirstCapital(col)}</span>;
      },
    },
    {
      text: "Actions",
      dataField: "account_id",
      formatter: (col, row) => {
        return (
          <div className="btn-group text-white" role="group">
            <NavLink
              to={`/dashboard/view-transaction/${row?.id}`}
              className="btn btn-info info text-white"
            >
              Transactions
            </NavLink>
            {row?.status === "inactive" && (
              <span
                className="btn btn-success success"
                onClick={() => handleDefault(row?.id)}
              >
                Make as Default
              </span>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Breadcrumbs current="View Accounts">View Accounts</Breadcrumbs>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Insert Payment"
      >
        <InsertPayment />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps}>
              <span
                className="app_btn success text-white fw-bolder cursor-pointer"
                onClick={() => setViewData()}
              >
                Add Note
              </span>
            </TableSearch>
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{ sortCaret }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewAccounts;
