import React from "react";
import "./ViewMembers.css";
import { AiFillEye } from "react-icons/ai";
import { SERVER_PUBLIC } from "config/app-config";
import useInternalServer from "hooks/useInternalServer";
import Loading from "components/loading/DashLoading";
import useFetch from "hooks/useFetch";
import img from "assets/img/profile.jpg";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { RiDeleteBinLine } from "react-icons/ri";
import AppModal from "components/modals/AppModal";
import MemberDelete from "components/modals/MemberDelete";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import checkDateDiff from "utils/checkDateDiff";
import { useViewData } from "stores/useViewData";
import MemberView from "components/modals/MemberView";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import Tooltip from "components/Tooltip/Tooltip";

const ViewMembers = () => {
  const { data, loading, error } = useFetch("/users");
  useInternalServer(error);
  const {
    deleteConfirmModal,
    setDeleteConfirmModal,
    handleClick,
    handleDelete,
  } = useDeleteCancelUser();
  const navigate = useNavigate();
  const { setViewData, viewModal, setViewModal } = useViewData();

  if (loading) return <Loading />;
  const columns = [
    {
      text: "Profile",
      dataField: "profile_img",
      formatter: (col, row) => (
        <div className="profileImgTable">
          <img
            src={
              row.profile_img
                ? `${SERVER_PUBLIC}/profile_pictures/${row.profile_img}`
                : img
            }
            alt=""
            className="img-fluid"
          />
          <a
            href={
              row.profile_img
                ? `${SERVER_PUBLIC}/profile_pictures/${row.profile_img}`
                : img
            }
            target="_blank"
            rel="noreferrer"
          >
            view
          </a>
        </div>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Company Name",
      dataField: "company_name",
      sort: true,
    },
    {
      text: "Company City",
      dataField: "company_city",
      sort: true,
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Mobile",
      dataField: "phone",
      sort: true,
    },
    {
      text: "Joining Date",
      dataField: "created_at",
      formatter: (col, row) => checkDateDiff(row.created_at).formatDate,
      sort: true,
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (col, row) => {
        if (row?.status === "active")
          return <span className="badge success">Active</span>;
        return <span className="badge danger">Inactive</span>;
      },
      sort: true,
    },
    {
      text: "Actions",
      dataField: "visible",
      formatter: (col, row) => (
        <div className="btn-group text-white" role="group">
          <Tooltip title="View Member">
            <span
              className="vm_btn btn success text-white"
              onClick={() => setViewData(row)}
            >
              <AiFillEye />
            </span>
          </Tooltip>
          <Tooltip title="Delete Member">
            <span
              className="vm_btn btn danger text-white"
              onClick={() => {
                handleClick(row.member_id, "delete");
              }}
            >
              <RiDeleteBinLine />
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span
              className="vm_btn btn bg-secondary text-white"
              onClick={() => {
                localStorage.setItem("update-mid", row?.id);
                navigate("/dashboard/update-profile");
              }}
            >
              <BiEdit />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="viewmember">
      <Breadcrumbs current="View Members">View Members</Breadcrumbs>
      <AppModal
        show={deleteConfirmModal}
        changeShow={setDeleteConfirmModal}
        title="Delete Confirmation"
      >
        <MemberDelete
          title="Do you want Delete Member !"
          setShow={setDeleteConfirmModal}
          handleDelete={() => handleDelete("delete-member", "delete")}
        />
      </AppModal>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Member Details"
      >
        <MemberView />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{ sortCaret }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewMembers;
