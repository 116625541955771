import React, { useCallback } from "react";
import useRazorpay from "react-razorpay";
import makePayment from "services/razorpay/memberPayment.js";
import { appUserStore } from "stores/appUser.js";
import { useNavigate } from "react-router-dom";
import "./Subscription.css";
import useFetch from "hooks/useFetch.js";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const Subscription = () => {
  const { data, loading, error } = useFetch("/subscription");
  useInternalServer(error);
  if (loading) return <Loading />;

  return (
    <div className="sub">
      <Breadcrumbs current="Subscription">Member Subscriptions </Breadcrumbs>
      <div className="row">
        {data?.appData?.map((plan) => (
          <div className="col-sm-12 col-md-6 col-lg-3" key={plan.id}>
            <Plan info={plan} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Plan = ({ info }) => {
  const user = appUserStore((state) => state.user);
  const changePaymentId = appUserStore((state) => state.updatePaymentID);
  const { updateRole, updatePaymentDate } = appUserStore((state) => state);
  const Razorpay = useRazorpay();
  const navigate = useNavigate();

  const handlePayment = useCallback(
    (subscriptionId, price) => {
      const mid = localStorage.getItem("member-id");
      const mPhone = localStorage.getItem("member-phone");
      makePayment(Razorpay, subscriptionId, price, mid || user?.member_id, !mid && changePaymentId, !mid && updateRole, updatePaymentDate, navigate);
      if (mid && mPhone) {
        localStorage.removeItem("member-phone");
        localStorage.removeItem("member-id");
      }
    },

    [Razorpay, changePaymentId, navigate, updateRole, user?.member_id, updatePaymentDate]
  );
  return (
    <div className="sub_plan rounded mt-4 mt-lg-0">
      <h5 className="mb-3 app_btn ribbon-pop">{info.name}</h5>
      <small className="d-block fw-bold text_danger mb-3">{info.area}</small>
      <h3 className="mb-3">INR{info.price}</h3>
      <h6>{info.recurring}</h6>
      <small className="d-block mb-3 text_danger">{info.description}</small>
      <span className="app_btn info rounded cursor-pointer" onClick={() => handlePayment(info.id, info.price)}>
        Proceed Payment
      </span>
    </div>
  );
};

export default Subscription;
