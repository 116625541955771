import React from "react";
import "./Modal.css";

const MemberDelete = (props) => {
  return (
    <div className="MemberDelete">
      <p>{props.title}</p>
      <div className="buttons text-end">
        <span
          type="button"
          onClick={() => props.setShow(false)}
          className="app_btn outline danger"
        >
          Close
        </span>
        <span
          type="button"
          className="app_btn success ms-2"
          onClick={props.handleDelete}
        >
          Confirm
        </span>
      </div>
    </div>
  );
};

export default MemberDelete;
