import { Navigate } from "react-router-dom";
import { appUserStore } from "stores/appUser";
import { ROLES } from "config/app-config";
import { alertError } from "components/toast/appAlert";

const RequireAuth = (props) => {
  const auth = appUserStore((state) => state.user);

  if (props?.onlyMember && !(auth?.role[0] === ROLES.MEMBER)) {
    alertError("only Members can update profile");
    return <Navigate to="/user/auth/login" />;
  }

  return auth?.role?.find((role) => props.allowedRoles?.includes(role)) ? (
    props.children
  ) : (
    <Navigate to="/user/auth/login" />
  );
};

export default RequireAuth;
