import React from "react";
import { useViewData } from "stores/useViewData";

const GuestView = () => {
  const { viewData } = useViewData((state) => state);
  return (
    <div>
      <h6>Name</h6>
      <p>{viewData?.name}</p>
      <h6>Industry</h6>
      <p>{viewData?.category_name}</p>
      <h6>Mobile</h6>
      <p>{viewData?.phone}</p>
    </div>
  );
};

export default GuestView;
