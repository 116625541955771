import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import React, { useState } from "react";

const AddEmail = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = privateAPI.post("/emails", { email, password });
    await alertPromise(result);
  };

  return (
    <div className="insertpayment">
      <form onSubmit={onSubmit}>
        <label className="app_label ">Email (gmail)</label>
        <input type="email" required className="app_input" autoFocus onChange={(e) => setEmail(e.target.value)} />
        <label className="app_label ">Password (App Password)</label>
        <input type="password" required className="app_input" onChange={(e) => setPassword(e.target.value)} />
        <button type="submit" className="app_btn primary rounded px-5 mt-3">
          Save
        </button>
      </form>
    </div>
  );
};

export default AddEmail;
