import React from "react";
import Table from "react-bootstrap/Table";
import "../MainDash/Tables/MyTables.css";
import {SERVER_PUBLIC} from "config/app-config";
import img from "assets/img/profile.jpg";

const GuestTable = ({data}) => {
  return (
    <div className="mytable h-100">
      <h6 className="mb-3">Total Users</h6>
      <Table responsive>
        <thead>
          <tr>
            <th>Profile</th>
            <th>Name</th>
            <th>Mobile </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td>
                <img
                  src={
                    items.profile_img
                      ? `${SERVER_PUBLIC}/profile_pictures/${items.profile_img}`
                      : img
                  }
                  alt=""
                  className="img-fluid w-25"
                />
              </td>
              <td>{items.name}</td>
              <td>{items.phone}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GuestTable;
