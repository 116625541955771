import React from "react";
import img1 from "assets/img/logo.jpg";
import "./about.css";
import { NavLink } from "react-router-dom";
import useView from "hooks/useView";
import { MdDoubleArrow } from "react-icons/md";

const About = () => {
  const view = useView();
  return (
    <div className={`about ${view}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <img className="d-block img-fluid" src={img1} alt="First slide" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="content">
              <div className="he">
                <h4>
                  About<span> SICBMA </span>
                </h4>
                <p>South India Corrugated Box Manufacturers Association</p>
              </div>
              <div className="blur">
                <p>
                  South India Corrugated Box Manufacturers Association is an
                  association of corrugated box manufacturers in Tamilnadu,
                  which in short is called <span> SICBMA </span>. It was
                  established in the year 1967. It has at present about 460+
                  members spread over Tamilnadu in places like Chennai,
                  Coiambatur, Thirupur, Selam, Housur, Pondichery, Madurai,
                  Trichi and Tutucorain etc.
                  <br />
                  <span> SICBMA </span> is affiliated to the Federation of
                  Corrugated Box Manufacturers of India; an apex body of
                  corrugated manufacturers all over India having at present a
                  membership of about 2000+. The annual turnover from this
                  industry is about 4,500 Crores. <span> SICBMA </span> was
                  established with the main objectives of promoting trade,
                  commerce and industry connected with corrugated boards and
                  boxes; to promote and advance commercial and technical
                  education of corrugated packaging
                </p>
                <div className="mt-5 text-end">
                  <NavLink
                    to="/about"
                    className="text-decoration-none book_btn "
                  >
                    View More <MdDoubleArrow />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
