import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine, RiImageAddFill } from "react-icons/ri";
import { ImCancelCircle } from "react-icons/im";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import "./AdminViewEvents.css";
import { SERVER_PUBLIC } from "config/app-config";
import checkDateDiff from "utils/checkDateDiff";
import AppModal from "components/modals/AppModal";
import Reason from "components/modals/Reason";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import MemberDelete from "components/modals/MemberDelete";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { usePictureUpdate } from "stores/usePictureUpdate";
import ChangeEventPicture from "components/modals/ChangeEventPicture";
import { useViewData } from "stores/useViewData";
import ViewEvent from "components/modals/ViewEvent";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import Tooltip from "components/Tooltip/Tooltip";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const AdminViewEvents = () => {
  const { data, loading, error } = useFetch("/events");
  useInternalServer(error);
  const navigate = useNavigate();
  const {
    deleteConfirmModal,
    cancelConfirmModal,
    setCancelConfirmModal,
    setDeleteConfirmModal,
    handleClick,
    handleCancel,
    handleDelete,
  } = useDeleteCancelUser();

  const {
    showPictureModal,
    setShowPictureModal,
    updateEventPicture,
    openShowPictureModal,
  } = usePictureUpdate();

  const { setViewData, viewModal, setViewModal } = useViewData();

  if (loading) return <Loading />;

  const columns = [
    {
      text: "Posters",
      dataField: "image",
      formatter: (col, row) => (
        <div className="profileImgTable">
          <img
            src={`${SERVER_PUBLIC}/events/${row?.image}`}
            className="img-fluid"
            alt=""
          />
          <a
            href={`${SERVER_PUBLIC}/events/${row?.image}`}
            target="_blank"
            rel="noreferrer"
          >
            v
          </a>
        </div>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Venue",
      dataField: "venue",
      sortable: true,
    },
    {
      text: "Price",
      dataField: "price",
      sort: true,
    },
    {
      text: "Event Date",
      dataField: "date",
      formatter: (col, row) => checkDateDiff(row.date).formatDate,
      sort: true,
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (col, row) => {
        if (row?.status === "cancelled") {
          return <span className="badge warning">Cancelled</span>;
        } else if (row?.status === "active") {
          return <span className="badge success">Active</span>;
        }
        return <span className="badge danger">Expire</span>;
      },
      sort: true,
    },
    {
      text: "Actions",
      dataField: "updated_at",
      formatter: (col, row) => {
        const { isValidity } = checkDateDiff(row?.date);
        return (
          <div className="btn-group" role="group">
            <Tooltip title="Show Details">
              <span
                className="vm_btn success text-white btn"
                onClick={() => setViewData(row)}
              >
                <AiFillEye />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span
                className="vm_btn danger text-white btn"
                onClick={() => handleClick(row?.id, "delete")}
              >
                <RiDeleteBinLine />
              </span>
            </Tooltip>
            {!row?.cancel_reason && isValidity && (
              <>
                <Tooltip title="Change Image">
                  <span
                    className="vm_btn bg-info btn text-white"
                    onClick={() => openShowPictureModal(row?.image, row?.id)}
                  >
                    <RiImageAddFill />
                  </span>
                </Tooltip>
                <Tooltip title="Cancel">
                  <span
                    className="vm_btn text-white btn bg-warning"
                    onClick={() => handleClick(row?.id, "cancel")}
                  >
                    <ImCancelCircle />
                  </span>
                </Tooltip>
                <Tooltip title="Edit">
                  <span
                    className="vm_btn text-white bg-secondary btn"
                    onClick={() =>
                      navigate(`/dashboard/create-event?updateId=${row?.id}`)
                    }
                  >
                    <BiEdit />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="adminviewevents">
      <Breadcrumbs current="Admin View Events">View Events</Breadcrumbs>
      <AppModal
        show={cancelConfirmModal}
        changeShow={setCancelConfirmModal}
        title="Cancelling Reason"
      >
        <Reason
          handlleCancel={handleCancel}
          api="/event-cancel"
          placeholder="Reason for cancelling event ..."
        />
      </AppModal>
      <AppModal
        show={deleteConfirmModal}
        changeShow={setDeleteConfirmModal}
        title="Delete Confirmation"
      >
        <MemberDelete
          title="Do you want Delete Event !"
          setShow={setDeleteConfirmModal}
          handleDelete={() => handleDelete("event")}
        />
      </AppModal>
      <AppModal
        show={showPictureModal}
        changeShow={setShowPictureModal}
        title="Change Event Picture"
      >
        <ChangeEventPicture
          handleUpdate={updateEventPicture}
          api="/event-picture"
        />
      </AppModal>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Event Details"
      >
        <ViewEvent />
      </AppModal>

      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{ sortCaret }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default AdminViewEvents;
