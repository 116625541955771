import React, { useState } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import { useNavigate } from "react-router";

const CreatePO = () => {
  const navigate = useNavigate();
  const [createpo, setCreatePO] = useState({
    companyname: "",
    companyaddress: "",
    ponumber: "",
    podate: "",
    poenddate: "",
    sellername: "",
    selleraddress: "",
    buyername: "",
    buyeraddress: "",
    itemdesc: "",
    quantity: "",
    unitprice: "",
    amount: "",
    shippingmethod: "",
    paymentterm: "",
    datereq: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setCreatePO({ ...createpo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = privateAPI.post("/po", createpo);
    await alertPromise(res);
    navigate("/dashboard/view-po");
  };

  return (
    <div className="createpo">
      <Breadcrumbs current="Create PO">Create Purchase Order</Breadcrumbs>
      <form onSubmit={handleSubmit}>
        <div className="bg-white px-3 pt-2 pb-3 mt-2">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="content">
                <label className="app_label">PO Number</label>
                <input
                  type="text"
                  name="ponumber"
                  onChange={handleOnChange}
                  className="app_input"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="content">
                <label className="app_label">PO Date</label>
                <ReactDatePicker
                  selected={createpo.podate}
                  dateFormat="yyyy-MM-dd hh:mm aa"
                  showTimeSelect
                  onChange={(date) =>
                    setCreatePO({
                      ...createpo,
                      podate: date,
                    })
                  }
                  minDate={moment().toDate()}
                  placeholderText="Select a PO Date and Time"
                  className="app_input"
                  name="podate"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="content">
                <label className="app_label">PO End Date</label>
                <ReactDatePicker
                  selected={createpo.poenddate}
                  dateFormat="yyyy-MM-dd hh:mm aa"
                  showTimeSelect
                  onChange={(date) =>
                    setCreatePO({
                      ...createpo,
                      poenddate: date,
                    })
                  }
                  minDate={moment().toDate()}
                  placeholderText="Select a PO End Date and Time"
                  className="app_input"
                  name="poenddate"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="content">
                <label className="app_label">Name of the Company</label>
                <input
                  type="text"
                  className="app_input"
                  name="companyname"
                  onChange={handleOnChange}
                  required
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="content">
                <label className="app_label">Address of the Company</label>
                <input
                  type="text"
                  className="app_input"
                  name="companyaddress"
                  onChange={handleOnChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className="content bg-white px-3 pt-2 pb-3 mt-3">
              <h6 className="my-2 app_label fw-bolder">Purchase From</h6>
              <label className="app_label">Name of the Seller</label>
              <input
                type="text"
                className="app_input"
                name="sellername"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Address of the Seller</label>
              <input
                type="text"
                className="app_input"
                name="selleraddress"
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className="content bg-white px-3 pt-2 pb-3 mt-3">
              <h6 className="my-2 app_label fw-bolder">Ship To</h6>
              <label className="app_label">Name of the Buyer</label>
              <input
                type="text"
                className="app_input"
                name="buyername"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Address of the Buyer</label>
              <input
                type="text"
                className="app_input"
                name="buyeraddress"
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
        </div>
        {/* row 3 */}
        <div className="row ">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className="content bg-white px-3 pt-2 pb-3 mt-3 h-100">
              <label className="app_label">Item Description</label>
              <textarea
                type="text"
                className="app_input"
                name="itemdesc"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Quantity</label>
              <input
                type="number"
                className="app_input"
                name="quantity"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Unit Price</label>
              <input
                type="number"
                className="app_input"
                name="unitprice"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Amount</label>
              <input
                type="number"
                className="app_input"
                name="amount"
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="content bg-white px-3 pt-2 pb-3 mt-3 h-100">
              <label className="app_label">Shipping Method</label>
              <input
                type="text"
                className="app_input"
                name="shippingmethod"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Payment Terms</label>
              <input
                type="text"
                className="app_input"
                name="paymentterm"
                onChange={handleOnChange}
                required
              />
              <label className="app_label">Date Required</label>
              <ReactDatePicker
                selected={createpo.datereq}
                dateFormat="yyyy-MM-dd hh:mm aa"
                showTimeSelect
                onChange={(date) =>
                  setCreatePO({
                    ...createpo,
                    datereq: date,
                  })
                }
                minDate={moment().toDate()}
                placeholderText="Select a Required Date"
                className="app_input"
                name="datereq"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
        <button type="submit" className="app_btn primary rounded px-5 mt-4">
          Create PO
        </button>
      </form>
    </div>
  );
};

export default CreatePO;
