import {
  TbArrowsSort,
  TbArrowNarrowDown,
  TbArrowNarrowUp,
} from "react-icons/tb";

const sortCaret = (order, column) => {
  if (order === "asc")
    return <TbArrowNarrowUp className="ms-2 text-secondary" />;
  else if (order === "desc")
    return <TbArrowNarrowDown className="ms-2 text-secondary" />;
  else return <TbArrowsSort className="ms-2 text-secondary" />;
};

export default sortCaret;
