import React, { useState } from "react";
import DashNavbar from "components/dashboard/DashNavbar/DashNavbar";
import { Outlet } from "react-router-dom";
import DashSidebar from "components/dashboard/DashSidebar/DashSidebar";
import "./DashUtils.css";
import { appUserStore } from "stores/appUser";
import AdsDashboard from "components/adsModals/AdsDashboard";

const DashLayout = () => {
  const [view, setview] = useState(true);
  const user = appUserStore((state) => state.user);
  return (
    <>
      {user?.role[0] === "1122" && (
        <AdsDashboard show={view} changeShow={setview} />
      )}
      <DashSidebar />
      <main id="main" className="main">
        <DashNavbar />
        <div className="pb-3 pt-1 px-3 px-lg-4">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default DashLayout;
