import privateAPI from "api/privateAPI";

const logout = async (navigate, logoutUser) => {
  await privateAPI.put("/logout");
  localStorage.removeItem("app-auth");
  logoutUser();
  navigate("/");
};

export default logout;
