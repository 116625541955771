import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {appServer} from "stores/appServer";
import {AlertToast} from "components/toast/appAlert";
import IndexPage from "pages/main/index/Index";
import MainLayout from "pages/layout/MainLayout";
import ProtectedRoute from "./ProtectedRoute";
import UserAuthPage from "pages/auth/UserAuthPage";
import AboutPages from "pages/main/aboutPage/AboutPages";
import MembersPage from "pages/main/mamberPage/MembersPage";
import GalleryPages from "pages/main/galleryPage/GalleryPages";
import ContactPages from "pages/main/contactPage/ContactPages";
import ErrorPage from "components/ErrorPage/ErrorPage";
import DashLayout from "pages/layout/DashLayout";
import CommitteePage from "pages/main/committeePage/CommitteePage";
import RequireAuth from "./RequireAuth";
import {ROLES} from "config/app-config";
import MyProfile from "components/dashboard/MyProfile/MyProfile";
import JoinMember from "components/dashboard/JoinMember/JoinMember";
import ViewEvents from "components/dashboard/ViewEvents/ViewEvents";
import Pdf from "components/dashboard/MaterialPDF/Pdf";
import Videos from "components/dashboard/MaterialPDF/Videos";
import Production from "components/dashboard/Productions/Production";
import PostAds from "components/dashboard/PostAds/PostAds";
import UpdateProfile from "components/dashboard/UpdateProfile/UpdateProfile";
import Subscription from "components/dashboard/Subscription/Subscription";
import Certificate from "components/dashboard/Certificate/Certificate";
import ViewAds from "components/dashboard/ViewAds/ViewAds";
import Viewproduction from "components/dashboard/ViewProduction/Viewproduction";
import OnlineId from "components/dashboard/OnlineId/OnlineId";
import MyEvents from "components/dashboard/MyEvents/MyEvents";
import ViewMembers from "components/dashboard/ViewMembers/ViewMembers";
import ViewGuests from "components/dashboard/ViewGuests/ViewGuests";
import CreateEvents from "components/dashboard/CreateEvents/CreateEvents";
import Invoice from "components/dashboard/Invoice/Invoice";
import UploadPdf from "components/dashboard/UploadPdf/UploadPdf";
import ClassifiedPage from "pages/main/classifiedpage/ClassifiedPage";
import Mills from "pages/main/Mills/Mills";
import CreateCommittee from "components/dashboard/CreateCommittee/CreateCommittee";
import ViewCommittee from "components/dashboard/ViewCommittee/ViewCommittee";
import AdminViewEvents from "components/dashboard/AdminViewEvents/AdminViewEvents";
import AdminViewPDF from "components/dashboard/AdminViewPdf/AdminViewPDF";
import AdminViewMill from "components/dashboard/AdminPaperMill/AdminViewMill";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DashboardPage from "pages/dashboard/DashboardPage";
import CreateAccount from "components/dashboard/CreateBankAccount/CreateAccount";
import ViewTransaction from "components/dashboard/ViewTransaction/ViewTransaction";
import ViewAccounts from "components/dashboard/ViewAccounts/ViewAccounts";
import InvoiceReports from "components/InvoiceReports/InvoiceReports";
import CreatePO from "components/dashboard/CreatePO/CreatePO";
import ViewPO from "components/dashboard/ViewPO/ViewPO";
import ViewGuestInvoice from "components/dashboard/ViewGuestInvoice/ViewGuestInvoice";
import ViewMemberInvoice from "components/dashboard/ViewMemberInvoice/ViewMemberInvoice";
import AdminViewAds from "components/dashboard/ViewAds/AdminViewAds";
import EmailCampaign from "components/dashboard/EmailCampaign/EmailCampaign";
import ViewEmail from "components/dashboard/ViewEmail/ViewEmail";
import SMSCamapign from "components/dashboard/SMS Campaign/SMSCamapign";
import PoInvoice from "components/dashboard/POInvoice/poInvoice";

const AppRoutes = () => {
  const {appClose} = appServer((state) => state);

  if (appClose) return <h1>Internal Server Error 500</h1>;

  return (
    <>
      <AlertToast />
      <BrowserRouter>
        <Routes>
          {/* Authentication */}
          <Route
            path="/user/auth/:page"
            element={
              <ProtectedRoute>
                <UserAuthPage />
              </ProtectedRoute>
            }
          />

          {/* Main Application */}

          <Route path="/" element={<MainLayout />}>
            <Route index element={<IndexPage />} />
            <Route path="about" element={<AboutPages />} />
            <Route path="members" element={<MembersPage />} />
            <Route path="gallery" element={<GalleryPages />} />
            <Route path="committee" element={<CommitteePage />} />
            <Route path="mills" element={<Mills />} />
            <Route path="contact" element={<ContactPages />} />
            <Route path="classified" element={<ClassifiedPage />} />
          </Route>

          {/* Dashboard */}

          <Route
            path="/dashboard"
            element={
              <RequireAuth
                allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
              >
                <DashLayout />
              </RequireAuth>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route
              path="join-member"
              element={
                <RequireAuth allowedRoles={ROLES.GUEST}>
                  <JoinMember />
                </RequireAuth>
              }
            />
            <Route
              path="guest/invoice"
              element={
                <RequireAuth allowedRoles={ROLES.GUEST}>
                  <ViewGuestInvoice />
                </RequireAuth>
              }
            />
            <Route
              path="member/invoice"
              element={
                <RequireAuth allowedRoles={ROLES.MEMBER}>
                  <ViewMemberInvoice />
                </RequireAuth>
              }
            />
            <Route
              path="subscription"
              element={
                <RequireAuth allowedRoles={[ROLES.GUEST, ROLES.ADMIN]}>
                  <Subscription />
                </RequireAuth>
              }
            />
            <Route
              path="create-event"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <CreateEvents />
                </RequireAuth>
              }
            />
            <Route
              path="view-events"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <ViewEvents />
                </RequireAuth>
              }
            />
            <Route
              path="my-events"
              element={
                <RequireAuth onlyMember allowedRoles={ROLES.MEMBER}>
                  <MyEvents />
                </RequireAuth>
              }
            />

            <Route path="pdf" element={<Pdf />} />
            <Route path="videos" element={<Videos />} />
            <Route
              path="my-ads"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <ViewAds />
                </RequireAuth>
              }
            />
            <Route
              path="post-ads"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <PostAds />
                </RequireAuth>
              }
            />
            <Route
              path="view-ads"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <AdminViewAds />
                </RequireAuth>
              }
            />
            <Route
              path="production"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <Production />
                </RequireAuth>
              }
            />
            <Route
              path="view-production"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.ADMIN, ROLES.MEMBER, ROLES.GUEST]}
                >
                  <Viewproduction />
                </RequireAuth>
              }
            />
            <Route
              path="create-committee"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <CreateCommittee />
                </RequireAuth>
              }
            />
            <Route
              path="view-committee"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewCommittee />
                </RequireAuth>
              }
            />
            <Route
              path="create-accounts"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <CreateAccount />
                </RequireAuth>
              }
            />
            <Route
              path="view-transaction/:accountId"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewTransaction />
                </RequireAuth>
              }
            />
            <Route
              path="view-accounts"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewAccounts />
                </RequireAuth>
              }
            />
            <Route
              path="admin/invoice"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <Invoice />
                </RequireAuth>
              }
            />
            <Route
              path="create-po"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <CreatePO />
                </RequireAuth>
              }
            />
            <Route
              path="view-po"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewPO />
                </RequireAuth>
              }
            />
            <Route
              path="admin/view-events"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <AdminViewEvents />
                </RequireAuth>
              }
            />
            <Route
              path="view-guest"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewGuests />
                </RequireAuth>
              }
            />
            <Route
              path="admin/view-pdf"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <AdminViewPDF />
                </RequireAuth>
              }
            />
            <Route
              path="admin/view-mill"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <AdminViewMill />
                </RequireAuth>
              }
            />
            <Route
              path="email-campaign"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <EmailCampaign />
                </RequireAuth>
              }
            />
            <Route
              path="view-email"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewEmail />
                </RequireAuth>
              }
            />
            <Route
              path="sms-campaign"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <SMSCamapign />
                </RequireAuth>
              }
            />
            <Route
              path="po-invoice/:poid"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <PoInvoice />
                </RequireAuth>
              }
            />

            <Route
              path="certificate"
              element={
                <RequireAuth onlyMember allowedRoles={ROLES.MEMBER}>
                  <Certificate />
                </RequireAuth>
              }
            />
            <Route
              path="update-profile"
              element={
                <RequireAuth allowedRoles={[ROLES.MEMBER, ROLES.ADMIN]}>
                  <UpdateProfile />
                </RequireAuth>
              }
            />
            <Route
              path="view-profile"
              element={
                <RequireAuth onlyMember allowedRoles={ROLES.MEMBER}>
                  <MyProfile />
                </RequireAuth>
              }
            />
            <Route
              path="online-id"
              element={
                <RequireAuth onlyMember allowedRoles={ROLES.MEMBER}>
                  <OnlineId />
                </RequireAuth>
              }
            />
            <Route
              path="invoice"
              element={
                <RequireAuth onlyMember allowedRoles={ROLES.MEMBER}>
                  <Invoice />
                </RequireAuth>
              }
            />
            <Route
              path="view-members"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewMembers />
                </RequireAuth>
              }
            />
            <Route
              path="view-guests"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <ViewGuests />
                </RequireAuth>
              }
            />
            <Route
              path="upload-pdf"
              element={
                <RequireAuth allowedRoles={ROLES.ADMIN}>
                  <UploadPdf />
                </RequireAuth>
              }
            />
            <Route
              path="invoice/:transId"
              element={
                <RequireAuth
                  allowedRoles={[ROLES.GUEST, ROLES.MEMBER, ROLES.ADMIN]}
                >
                  <InvoiceReports />
                </RequireAuth>
              }
            />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
