import { useEffect } from "react";
import { appServer } from "../stores/appServer";
import { APP_ENV } from "../config/app-config";

const useInternalServer = (error) => {
  const raisedInternalServer = appServer((state) => state.raisedInternalServer);
  useEffect(() => {
    let isDone = false;
    if (!isDone && error && APP_ENV === "production") {
      raisedInternalServer(true);
    }
    return () => {
      isDone = true;
    };
  }, [error, raisedInternalServer]);
};

export default useInternalServer;
