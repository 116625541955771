import React from "react";
import {useViewData} from "stores/useViewData";

const CommitteeView = () => {
  const {viewData} = useViewData((state) => state);
  return (
    <div>
      <h6>Name</h6>
      <p>{viewData?.name}</p>
      <h6>Position</h6>
      <p>{viewData?.posting}</p>
      <h6>Email</h6>
      <p>{viewData?.email}</p>
      <h6>Mobile</h6>
      <p>{viewData?.phone}</p>
      <h6>Address</h6>
      <p>{viewData?.address}</p>
      <h6>City</h6>
      <p>{viewData?.city}</p>
    </div>
  );
};

export default CommitteeView;
