import React from "react";
import "./TableSearch.css";

const TableSearch = (props) => {
  return (
    <div className="table_search">
      <div>{props.children}</div>
      <div>
        <input
          type="search"
          placeholder="Search Anything in Table ..."
          onChange={(e) => props.onSearch(e.target.value)}
          autoFocus
          className="mt-2 mt-md-0"
        />
      </div>
    </div>
  );
};

export default TableSearch;
