import React from "react";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";

const Reason = ({ handlleCancel, placeholder, api }) => {
  const { setCancelReason } = useDeleteCancelUser((state) => state);

  return (
    <form onSubmit={(e) => handlleCancel(e, api)}>
      <textarea
        className="app_input mt-0"
        placeholder={placeholder}
        required
        onChange={(e) => setCancelReason(e.target.value)}
      ></textarea>
      <button type="submit" className="app_btn danger">
        Confirm
      </button>
    </form>
  );
};

export default Reason;
