import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const useView = () => {
  const [view, setView] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    let isDone = false;
    if (!isDone) {
      if (isDesktop) setView("lg");
      else if (isTablet) setView("md");
      else if (isMobile) setView("sm");
    }

    return () => {
      isDone = true;
    };
  }, [isDesktop, isMobile, isTablet]);

  return view;
};

export default useView;
