import React from "react";
import Table from "react-bootstrap/Table";
import useFetch from "hooks/useFetch";
import checkDateDiff from "utils/checkDateDiff";
import useInternalServer from "hooks/useInternalServer";
import Loading from "components/loading/Loading";
import useView from "hooks/useView";
import Header from "components/main/header/Header";
import CardPagination from "components/CardPagination/CardPagination";
import "./Mills.css";

const Mills = () => {
  const view = useView();
  const { data, loading, error } = useFetch(`/productions`);
  useInternalServer(error);
  if (loading) return <Loading />;

  return (
    <>
      <div className={`mill ${view}`}>
        <Header title="PRODUCTION" />
        <div className="container">
          <div className="content">
            <div className="row">
              <CardPagination items={data?.appData} itemsPerPage={8}>
                <Events />
              </CardPagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Events = (props) => {
  return (
    <>
      {props?.currentItems?.map((mill, i) => (
        <div className="col-sm-12 col-lg-6 mt-3" key={i}>
          <div className="tbl_scrool bg-white p-3 rounded-2">
            <Table bordered className="m-0">
              <thead>
                <tr>
                  <th colSpan={4} className="heading text-center">
                    {mill?.name}
                  </th>
                </tr>
                <tr>
                  <td className="fw-bold">Last Updated On</td>
                  <td>
                    {
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      ).formatDate?.split(" ")[0]
                    }
                  </td>
                  <td>
                    {checkDateDiff(
                      mill?.details[mill?.details.length - 1]?.date
                    )
                      .formatDate?.split(" ")[1]
                      .split(":")[0] +
                      ":" +
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      )
                        .formatDate?.split(" ")[1]
                        .split(":")[1] +
                      " " +
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      ).formatDate?.split(" ")[2]}
                  </td>
                </tr>
                <tr className="head">
                  <th>BF</th>
                  <th>COL</th>
                  <th>Production Date and Time</th>
                </tr>
              </thead>
              <tbody>
                {mill?.details?.map((detail, i) => (
                  <tr key={i}>
                    <td>{detail?.bf}</td>
                    <td>{detail?.col}</td>
                    <td>{checkDateDiff(detail?.date).formatDate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ))}
    </>
  );
};

export default Mills;
