import React from "react";
import ForgetPass from "components/auth/ForgetPass";
import ChangePassword from "components/auth/ChangePassword";
import {useParams} from "react-router";
import "./userauth.css";
import useView from "hooks/useView";

const ForgetPassword = () => {
  const {page} = useParams();
  const view = useView();
  return (
    <div className={`user_auth ${view}`}>
      <div
        className={`container forget_change ${
          page === "reset-password" ? "active" : ""
        } `}
      >
        <ForgetPass />
        <ChangePassword />
      </div>
    </div>
  );
};

export default ForgetPassword;
