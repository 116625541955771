import React from "react";

const OTPModal = ({ setOTP, handleVerify }) => {
  return (
    <div className="otp_alert">
      <p className="text-center">
        The <b>OTP</b> has been Send to Your Mobile Number.
      </p>
      <form onSubmit={handleVerify}>
        <div className="row">
          <div className="col-12 col-md-7">
            <input
              type="text"
              onChange={(e) => setOTP(e.target.value)}
              required
              autoFocus
              placeholder="Enter Your OTP"
              className="w-100 app-input"
            />
          </div>
          <div className="col-12 col-md-5">
            <button
              type="submit"
              className="w-100 mt-3 mt-md-0 app-button text-white"
            >
              Verify
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OTPModal;
