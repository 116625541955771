import create from "zustand";
import { persist } from "zustand/middleware";

export const appUserStore = create(
  persist(
    (set) => ({
      user: null,
      addLoginUser: (user) => set({ user }),
      updateMemberId: (mid) =>
        set((state) => ({ user: { ...state.user, member_id: mid } })),
      updateProfileImage: (imageName) =>
        set((state) => ({ user: { ...state.user, profile_img: imageName } })),
      updatePaymentID: (pid) =>
        set((state) => ({ user: { ...state.user, payment_id: pid } })),
      updateRole: (role) =>
        set((state) => ({ user: { ...state.user, role: role } })),
      updatePaymentDate: (date) =>
        set((state) => ({ user: { ...state.user, payment_date: date } })),
      logoutUser: () => set((state) => ({ user: null })),
    }),
    {
      name: "app-user",
    }
  )
);
