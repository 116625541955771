import React, { useState, useEffect } from "react";
import { appUserStore } from "stores/appUser";
import useFetch from "hooks/useFetch";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import { useNavigate } from "react-router-dom";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const UpdateProfile = () => {
  const user = appUserStore((state) => state.user);
  const { data, loading, error } = useFetch(
    `/users?uid=${localStorage.getItem("update-mid") || user?.id}`
  );
  const navigate = useNavigate("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCat] = useState("");
  const [cname, setcname] = useState("");
  const [caddress, setcaddress] = useState("");
  const [ccity, setccity] = useState("");
  const [resaddress, setresaddress] = useState("");
  const [rescity, setRescity] = useState("");
  const [gstno, setgstno] = useState("");
  const [msme, setMsme] = useState("");
  const [website, setWebsite] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");
  const [state, setState] = useState("");
  const [lanMobile, setLanMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [resState, setResState] = useState("");
  const [resPincode, setResPincode] = useState("");
  const catRes = useFetch("/categories");
  useInternalServer(error);
  useEffect(() => {
    setName(data?.appData[0]?.name);
    setEmail(data?.appData[0]?.email);
    setCat("1");
    setcname(data?.appData[0]?.company_name);
    setcaddress(data?.appData[0]?.company_address);
    setccity(data?.appData[0]?.company_city);
    setgstno(data?.appData[0]?.gstno);
    setresaddress(data?.appData[0]?.res_address);
    setRescity(data?.appData[0]?.res_city);
    setMsme(data?.appData[0]?.msme_no);
    setWebsite(data?.appData[0]?.website);
    setAlternateMobile(data?.appData[0]?.alternate_mobile);
    setLanMobile(data?.appData[0]?.lan_mobile);
    setState(data?.appData[0]?.company_state);
    setPincode(data?.appData[0]?.company_pincode);
    setResState(data?.appData[0]?.res_state);
    setResPincode(data?.appData[0]?.res_pincode);
  }, [data?.appData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = privateAPI.put(
      `/users?uid=${localStorage.getItem("update-mid") || user?.id}`,
      {
        name,
        email,
        category,
        cname,
        caddress,
        ccity,
        resaddress,
        rescity,
        gstno,
        msme,
        website,
        alternateMobile,
        lanMobile,
        state,
        pincode,
        resState,
        resPincode,
      }
    );
    await alertPromise(res);
    navigate("/dashboard");
  };
  if (loading) return <Loading />;
  return (
    <>
      <Breadcrumbs current="Profile">Update Profile</Breadcrumbs>
      <form className="pb-3 " onSubmit={handleSubmit}>
        <div className="row bg-white rounded pb-4 px-1 px-lg-3">
          <h6 className="mt-3">Personal Info</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label">Name</label>
            <input
              type="text"
              className="app_input"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Email</label>
            <input
              type="email"
              className="app_input"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Industry</label>
            <select
              onChange={(e) => setCat(e.target.value)}
              className="app_input"
            >
              <option value="">Select Industry</option>
              {catRes?.data?.appData.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-4 bg-white rounded pb-4 px-1 px-lg-3">
          <h6 className="mt-3">Company Details</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label">Name</label>
            <input
              type="text"
              className="app_input"
              value={cname || ""}
              onChange={(e) => setcname(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Address</label>
            <input
              type="text"
              className="app_input"
              required
              value={caddress || ""}
              onChange={(e) => setcaddress(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">State</label>
            <input
              type="text"
              className="app_input"
              required
              value={state || ""}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">City</label>
            <input
              type="text"
              className="app_input"
              required
              value={ccity || ""}
              onChange={(e) => setccity(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Pincode</label>
            <input
              type="text"
              className="app_input"
              required
              value={pincode || ""}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
        </div>
        <div className="row bg-white mt-4 pb-4 px-1 px-lg-3">
          <h6 className="mt-3">Residential Details</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label">Address</label>
            <input
              type="text"
              className="app_input"
              value={resaddress || ""}
              onChange={(e) => setresaddress(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">State</label>
            <input
              type="text"
              className="app_input"
              value={resState || ""}
              onChange={(e) => setResState(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">City</label>
            <input
              type="text"
              className="app_input"
              value={rescity || ""}
              onChange={(e) => setRescity(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Pincode</label>
            <input
              type="text"
              className="app_input"
              value={resPincode || ""}
              onChange={(e) => setResPincode(e.target.value)}
            />
          </div>
        </div>
        <div className="row bg-white rounded mt-4 pb-4 px-1 px-lg-3">
          <h6 className="mt-3">Other Info</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label">Alternate Mobile Number</label>
            <input
              type="text"
              className="app_input"
              value={alternateMobile || ""}
              onChange={(e) => setAlternateMobile(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Website</label>
            <input
              type="text"
              className="app_input"
              value={website || ""}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Lan Mobile Number</label>
            <input
              type="text"
              className="app_input"
              value={lanMobile || ""}
              onChange={(e) => setLanMobile(e.target.value)}
            />
          </div>
        </div>
        <button type="submit" className="app_btn primary rounded mt-3">
          Save Changes
        </button>
      </form>
    </>
  );
};

export default UpdateProfile;
