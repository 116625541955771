import React from "react";
import { Navigate } from "react-router";
import { appUserStore } from "../stores/appUser";

const ProtectedRoute = ({ children }) => {
  const auth = appUserStore((state) => state.user);
  return auth ? <Navigate to="/dashboard" /> : children;
};

export default ProtectedRoute;
