import {BsFillPersonFill} from "react-icons/bs";
import {BsFillCalendarEventFill} from "react-icons/bs";
import {AiFillFile} from "react-icons/ai";
import {AiTwotoneSafetyCertificate} from "react-icons/ai";
import {SiGoogleads} from "react-icons/si";
import {FaIdCardAlt} from "react-icons/fa";
import {BsFillPeopleFill} from "react-icons/bs";
import {FaSitemap} from "react-icons/fa";
import {FaCashRegister} from "react-icons/fa";
import {SiCampaignmonitor} from "react-icons/si";
import {FaBoxes} from "react-icons/fa";
import {RiBillFill} from "react-icons/ri";

const dashMenus = (role) => {
  const menus = {
    // Admin
    "0011": [
      {
        name: "Members",
        subItem: [
          {
            name: "View Member",
            link: "/dashboard/view-members",
          },
        ],
        icon: <BsFillPeopleFill className="me-2" />,
      },
      {
        name: "Guest",
        subItem: [
          {
            name: "View Guest",
            link: "/dashboard/view-guest",
          },
        ],
        icon: <BsFillPersonFill className="me-2" />,
      },

      {
        name: "Events",
        subItem: [
          {
            name: "Create Events",
            link: "/dashboard/create-event",
          },
          {
            name: "View Events",
            link: "/dashboard/admin/view-events",
          },
        ],
        icon: <BsFillCalendarEventFill className="me-2" />,
      },
      {
        name: "Advertisement",
        subItem: [
          {
            name: "My Ads",
            link: "/dashboard/my-ads",
          },
          {
            name: "Post Ads",
            link: "/dashboard/post-ads",
          },
          {
            name: "View Ads",
            link: "/dashboard/view-ads",
          },
        ],
        icon: <SiGoogleads className="me-2" />,
      },
      {
        name: "PDF",
        subItem: [
          {
            name: "Upload PDF",
            link: "/dashboard/upload-pdf",
          },
          {
            name: "View PDF",
            link: "/dashboard/admin/view-pdf",
          },
        ],
        icon: <BsFillCalendarEventFill className="me-2" />,
      },
      {
        name: "Videos",
        link: "/dashboard/videos",
        icon: <BsFillCalendarEventFill className=" me-2" />,
      },
      {
        name: "Purchase",
        subItem: [
          {
            name: "Create PO",
            link: "/dashboard/create-po",
          },
          {
            name: "View PO",
            link: "/dashboard/view-po",
          },
        ],
        icon: <FaBoxes className="me-2" />,
      },
      {
        name: "Produtions",
        subItem: [
          {
            name: "Create Produtions",
            link: "/dashboard/production",
          },
          {
            name: "View All Produtions",
            link: "/dashboard/admin/view-mill",
          },
          {
            name: "My Produtions",
            link: "/dashboard/view-production",
          },
        ],
        icon: <FaBoxes className="me-2" />,
      },
      {
        name: "Committee",
        subItem: [
          {
            name: "Create Member",
            link: "/dashboard/create-committee",
          },
          {
            name: "View Member",
            link: "/dashboard/view-committee",
          },
        ],
        icon: <BsFillPeopleFill className="me-2" />,
      },

      {
        name: "Accounts",
        subItem: [
          {
            name: "Create Bank Account ",
            link: "/dashboard/create-accounts",
          },
          {
            name: "View Accounts",
            link: "/dashboard/view-accounts",
          },
        ],
        icon: <FaCashRegister className="me-2" />,
      },
      {
        name: "Campaign",
        subItem: [
          {
            name: "Email Campaign",
            link: "/dashboard/email-campaign",
          },
          {
            name: "View Email",
            link: "/dashboard/view-email",
          },
          // {
          //   name: "WhatsApp Campaign",
          //   link: "/dashboard/",
          // },
          {
            name: "SMS Campaign",
            link: "/dashboard/sms-campaign",
          },
        ],
        icon: <SiCampaignmonitor className="me-2" />,
      },
      // {
      //   name: "Invoice",
      //   link: "/dashboard/admin/invoice",
      //   icon: <RiBillFill className=" me-2" />,
      // },

      // {
      //   name: "Reports",
      //   subItem: [
      //     {
      //       name: "Membership",
      //       link: "/dashboard/",
      //     },
      //     {
      //       name: "Ads sales",
      //       link: "/dashboard/",
      //     },
      //     {
      //       name: "Event Sales",
      //       link: "/dashboard/",
      //     },
      //     {
      //       name: "Paper Mills Income",
      //       link: "/dashboard/",
      //     },
      //     {
      //       name: "Lab Income",
      //       link: "/dashboard/",
      //     },
      //     {
      //       name: "Expenses",
      //       link: "/dashboard/",
      //     },
      //   ],
      //   icon: <GoFileSubmodule className="me-2" />,
      // },
    ],
    // GUEST
    1122: [
      {
        name: "Profile",
        link: "/dashboard/view-profile",
        icon: <BsFillPersonFill className=" me-2" />,
      },
      {
        name: "Events",
        link: "/dashboard/view-events",
        icon: <BsFillCalendarEventFill className="me-2" />,
      },
      {
        name: "Knowledge",
        subItem: [
          {
            name: "PDF",
            link: "/dashboard/pdf",
          },
          {
            name: "Videos",
            link: "/dashboard/videos",
          },
        ],
        icon: <AiFillFile className="me-2" />,
      },
      {
        name: "Advertisement",
        subItem: [
          {
            name: "Post Ads",
            link: "/dashboard/post-ads",
            icon: <BsFillPersonFill className=" me-2" />,
          },
          {
            name: "View Ads",
            link: "/dashboard/my-ads",
            icon: "",
          },
        ],
        icon: <SiGoogleads className="me-2" />,
      },
      {
        name: "Invoice",
        link: "/dashboard/guest/invoice",
        icon: <RiBillFill className="me-2" />,
      },
      {
        name: "Productions",
        subItem: [
          {
            name: "Add Production",
            link: "/dashboard/production",
            icon: <BsFillPersonFill className=" me-2" />,
          },
          {
            name: "View Production",
            link: "/dashboard/view-production",
            icon: "",
          },
        ],
        icon: <FaSitemap className="me-2" />,
      },
    ],
    // MEMBER
    2233: [
      {
        name: "Profile",
        subItem: [
          {
            name: "View Profile",
            link: "/dashboard/view-profile",
          },
          {
            name: "Update Profile",
            link: "/dashboard/update-profile",
          },
        ],
        icon: <BsFillPersonFill className="me-2" />,
      },
      {
        name: "Events",
        subItem: [
          {
            name: "View Events",
            link: "/dashboard/view-events",
          },
          {
            name: "My Events",
            link: "/dashboard/my-events",
          },
        ],
        icon: <BsFillCalendarEventFill className="me-2" />,
      },
      {
        name: "Knowledge",
        subItem: [
          {
            name: "PDF",
            link: "/dashboard/pdf",
          },
          {
            name: "Videos",
            link: "/dashboard/videos",
          },
        ],
        icon: <AiFillFile className="me-2" />,
      },
      {
        name: "Certificate",
        link: "/dashboard/certificate",
        icon: <AiTwotoneSafetyCertificate className="me-2" />,
      },
      {
        name: "Advertisement",
        subItem: [
          {
            name: "Post Ads",
            link: "/dashboard/post-ads",
            icon: <BsFillPersonFill className=" me-2" />,
          },
          {
            name: "View Ads",
            link: "/dashboard/my-ads",
            icon: "",
          },
        ],
        icon: <SiGoogleads className="me-2" />,
      },
      {
        name: "Invoice",
        link: "/dashboard/member/invoice",
        icon: <RiBillFill className="me-2" />,
      },
      {
        name: "Online ID",
        link: "/dashboard/online-id",
        icon: <FaIdCardAlt className="me-2" />,
      },
    ],
  };

  let menusItems = menus[role?.[0]];

  return menusItems;
};

export default dashMenus;
