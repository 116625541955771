import moment from "moment";

const checkMemberShip = (joinDate) => {
  var expireDate = moment(joinDate, "YYYY-MM-DD hh:mm:ss A")
    .add(1, "years")
    .format("YYYY-MM-DD hh:mm:ss A");
  return expireDate;
};

export default checkMemberShip;
