import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import {useViewData} from "stores/useViewData";
import AppModal from "components/modals/AppModal";
import AddEmail from "components/modals/AddEmail";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import toFirstCapital from "utils/toFirstCapital";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";

const ViewEmail = () => {
  const {setViewData, viewModal, setViewModal} = useViewData();
  const {data, loading} = useFetch("/emails");

  const handleDefault = async (email_id) => {
    const res = privateAPI.put(`/email-active/${email_id}`);
    await alertPromise(res);
  };

  const columns = [
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (col, row) => {
        if (col === "active")
          return <span className="badge success">{toFirstCapital(col)}</span>;
        return <span className="badge danger">{toFirstCapital(col)}</span>;
      },
    },

    {
      text: "Actions",
      dataField: "account_id",
      formatter: (col, row) => {
        return (
          <div>
            {row?.status === "inactive" && (
              <span
                className="btn btn-success success"
                onClick={() => handleDefault(row?.id)}
              >
                Make as Default
              </span>
            )}
          </div>
        );
      },
    },
  ];

  if (loading) return <DashLoading />;
  return (
    <div>
      <Breadcrumbs current="View Email">View Email</Breadcrumbs>
      <AppModal show={viewModal} changeShow={setViewModal} title="Add Email">
        <AddEmail />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps}>
              <span
                className="app_btn success text-white fw-bolder cursor-pointer"
                onClick={() => setViewData()}
              >
                Add Email
              </span>
            </TableSearch>
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData.length)}
              sort={{sortCaret}}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewEmail;
