import moment from "moment";

const checkDateDiff = (date) => {
  const eventDate = moment
    .utc(date)
    .utcOffset("+05:30")
    .format("YYYY-MM-DD hh:mm:ss A");

  const nowDate = moment().format("YYYY-MM-DD hh:mm:ss A");

  const eventValidity = moment(eventDate).diff(nowDate);

  return {
    isStatus: moment(eventDate).fromNow(),
    isValidity: eventValidity > 0 ? true : false,
    formatDate: moment(eventDate).format("DD-MM-YYYY hh:mm:ss A"),
  };
};

export default checkDateDiff;
