export const UNEXPECTED_ERROR = "Something went wrong Try Again !";

export const RAZORPAY_ID = "rzp_test_jdyqMS9dEpUKjr";

export const APP_ENV = "production";

export const SERVER_PUBLIC = APP_ENV === "development" ? "http://localhost:5000/assets" : "http://15.206.127.109:5000/assets";

export const SERVER_API = APP_ENV === "development" ? "http://localhost:5000/api" : "http://15.206.127.109:5000/api";

export const RAZORPAY_SCREET = "2qDdhIeNXQQ5G3KSpKhwcbwb";

export const ROLES = {
  ADMIN: "0011",
  MEMBER: "2233",
  GUEST: "1122",
};
