import React from "react";
import "./MyEvents.css";
import { ImLocation } from "react-icons/im";
import { FaRupeeSign } from "react-icons/fa";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import CardPagination from "components/CardPagination/CardPagination";
import { appUserStore } from "stores/appUser";
import useFetch from "hooks/useFetch";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import { SERVER_PUBLIC } from "config/app-config";
import checkDateDiff from "utils/checkDateDiff";
import { MdAccessTimeFilled } from "react-icons/md";

const MyEvents = () => {
  const user = appUserStore((state) => state.user);
  const { data, loading, error } = useFetch(
    `/events-payments?mid=${user?.member_id}`
  );
  useInternalServer(error);

  if (loading) return <Loading />;

  return (
    <>
      <div className="myevents">
        <Breadcrumbs current="My Events">My Events</Breadcrumbs>
        <div className="row ">
          <CardPagination items={data?.appData} itemsPerPage={8}>
            <Events />
          </CardPagination>
        </div>
      </div>
    </>
  );
};

const Events = (props) => {
  return (
    <>
      {props?.currentItems?.map((event, i) => (
        <div
          className="col-12 col-md-6 col-lg-3 mt-3 mt-lg-0 view-head"
          key={i}
        >
          <div className="app_card p-2">
            <div className="image">
              <img
                src={`${SERVER_PUBLIC}/events/${event?.image}`}
                alt=""
                className="img-fluid mt-1"
              />
            </div>

            <div className="mydetails">
              <h5 className="my-2 mt-3">{event?.name}</h5>
              <div className="date text-center mt-3">
                <h5>
                  <MdAccessTimeFilled /> {checkDateDiff(event?.date).formatDate}
                </h5>
              </div>
              <div className="address">
                <p className="m-0">
                  <ImLocation /> {event?.venue},{event?.venue_address}
                </p>
              </div>
              <div className="add">
                <p className="m-0 ms-4">{event?.venue_city}</p>
              </div>
              <h5 className="text-danger price">
                <FaRupeeSign />
                {event?.price}
              </h5>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MyEvents;
