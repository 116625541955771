import React from "react";
import {useViewData} from "stores/useViewData";
import checkDateDiff from "utils/checkDateDiff";

const ViewPoModal = () => {
  const {viewData} = useViewData((state) => state);
  return (
    <div>
      <h6>PO No</h6>
      <p>{viewData?.po_no}</p>
      <h6>Item Description</h6>
      <p>{viewData?.item_desc}</p>
      <h6>Quantity</h6>
      <p>{viewData?.quantity}</p>
      <h6>Unit Price</h6>
      <p>{viewData?.unit_price}</p>
      <h6>Total Amount</h6>
      <p>{viewData?.amount}</p>
      <h6>Po Date</h6>
      <p>{checkDateDiff(viewData?.po_date).formatDate?.split(" ")[0]}</p>
      <h6>Po End Date</h6>
      <p>{checkDateDiff(viewData?.po_enddate).formatDate?.split(" ")[0]}</p>
      <h6>Company Name</h6>
      <p>{viewData?.company_name}</p>
      <h6>Company Address</h6>
      <p>{viewData?.company_address}</p>
      <h6>Buyer Name</h6>
      <p>{viewData?.buyer_name}</p>
      <h6>Buyer Address</h6>
      <p>{viewData?.buyer_address}</p>
      <h6>Seller Name</h6>
      <p>{viewData?.seller_name}</p>
      <h6>Seller Address</h6>
      <p>{viewData?.seller_address}</p>
      <h6>Shipping Method</h6>
      <p>{viewData?.shipping_method}</p>
      {/* <h6>Created at</h6>
      <p>{checkDateDiff(viewData?.created_at).formatDate?.split(" ")[0]}</p> */}
    </div>
  );
};

export default ViewPoModal;
