import React from "react";
import {Modal} from "react-bootstrap";
import {MdOutlineClose} from "react-icons/md";
import "./Modal.css";

const AppModal = (props) => {
  return (
    <Modal
      show={props.show}
      centered
      onHide={() => props.changeShow(false)}
      backdrop="static"
      className="height-control"
    >
      {!props.noHeader && (
        <Modal.Header className="align-items-center">
          <Modal.Title>
            <h5 className="m-0 border-0 text-secondary text-truncate">
              {props.title}
            </h5>
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{props.children}</Modal.Body>
      <span className="modal_closeBtn" onClick={() => props.changeShow(false)}>
        <MdOutlineClose />
      </span>
    </Modal>
  );
};

export default AppModal;
