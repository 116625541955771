import React from "react";
import {useViewData} from "stores/useViewData";

const MemberView = () => {
  const {viewData} = useViewData((state) => state);
  return (
    <div>
      <h6>Name</h6>
      <p>{viewData?.name}</p>
      <h6>Mobile</h6>
      <p>{viewData?.phone}</p>
      <h6>Email</h6>
      <p>{viewData?.email}</p>
      <h6>Company Name</h6>
      <p>{viewData?.company_name}</p>
      <h6>Company Address</h6>
      <p>{viewData?.company_address}</p>
      <h6>Company City</h6>
      <p>{viewData?.company_city}</p>
      <h6>Company State</h6>
      <p>{viewData?.company_state}</p>
      <h6>Company Pincode</h6>
      <p>{viewData?.company_pincode}</p>
      <h6>MSME No</h6>
      <p>{viewData?.msme_no}</p>
      <h6>GST no</h6>
      <p>{viewData?.gstno}</p>
      <h6>Residential Address</h6>
      <p>{viewData?.res_address}</p>
      <h6>Residential City</h6>
      <p>{viewData?.city}</p>
      <h6>Residential State</h6>
      <p>{viewData?.res_state}</p>
      <h6>Residential Pincode</h6>
      <p>{viewData?.res_pincode}</p>
      <h6>Alternative Mobile</h6>
      <p>{viewData?.alternative_mobile}</p>
    </div>
  );
};

export default MemberView;
