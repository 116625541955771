import React from "react";
import "./MyProfile.css";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";
import {appUserStore} from "stores/appUser";
import Loading from "components/loading/DashLoading";
import {SERVER_PUBLIC} from "config/app-config";
import toFirstCapital from "utils/toFirstCapital";
import img from "assets/img/profile.jpg";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const MyProfile = () => {
  const user = appUserStore((state) => state.user);
  const {data, loading, error} = useFetch(`/users?uid=${user?.id}`);
  useInternalServer(error);
  if (loading) return <Loading />;

  return (
    <>
      <Breadcrumbs current="My Profile">My Profile</Breadcrumbs>
      <div className="myprofile bg-white p-3">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <img
              src={`${
                user?.profile_img
                  ? `${SERVER_PUBLIC}/profile_pictures/${user?.profile_img}`
                  : img
              }`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-9">
            <h1>
              {data?.appData[0] && toFirstCapital(data?.appData[0]?.name)}
            </h1>
          </div>
        </div>
        {/* row 2 */}
        <div className="row mt-3">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="personal">
              <h5>Personal Detail</h5>
              <h6 className="mt-3">Email</h6>
              <p>
                {data?.appData[0] && toFirstCapital(data?.appData[0]?.email)}
              </p>
              <h6>Mobile</h6>
              <p>
                {data?.appData[0] && toFirstCapital(data?.appData[0]?.phone)}
              </p>
              <h6>MSME no</h6>
              <p>
                {data?.appData[0] && toFirstCapital(data?.appData[0]?.msme_no)}
              </p>
              <h6>GST no </h6>
              <p>
                {data?.appData[0] && toFirstCapital(data?.appData[0]?.gstno)}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="company">
              <h5>Company Detail</h5>
              <h6 className="mt-3">Company Name</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.company_name)}
              </p>
              <h6>Company Address</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.company_address)}
              </p>
              <h6>Company City</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.company_city)}
              </p>
              <h6>Company State</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.company_state)}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="resident">
              <h5>Residential Detail</h5>
              <h6 className="mt-3">Residential Address</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.res_address)}
              </p>
              <h6>Residential City</h6>
              <p>
                {data?.appData[0] && toFirstCapital(data?.appData[0]?.res_city)}
              </p>
              <h6>Residential State</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.res_state)}
              </p>
              <h6>Residential Pincode</h6>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.res_pincode)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
