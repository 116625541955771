import React, {useRef} from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import img from "assets/img/poinvoice2.jpg";
import "./poInvoice.css";
import {useReactToPrint} from "react-to-print";
import useFetch from "hooks/useFetch";
import {useInRouterContext} from "react-router";
import DashLoading from "components/loading/DashLoading";
import checkDateDiff from "utils/checkDateDiff";
import {useParams} from "react-router";

const PoInvoice = () => {
  const {poid} = useParams();
  const {data, loading, error} = useFetch(`/po?poid=${poid}`);
  useInRouterContext(error);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  if (loading) return <DashLoading />;
  return (
    <>
      <Breadcrumbs current="PO Invoice">PO Invoice</Breadcrumbs>
      <button className="btn btn-primary mb-3" onClick={handlePrint}>
        Print
      </button>
      <div className="poinvoice " ref={componentRef}>
        <img src={img} alt="" className="img-fluid w-100" />
        <div className="cmpname">
          <h4>{data?.appData[0]?.company_name}</h4>
        </div>
        <div className="cmpadd">
          <h5>{data?.appData[0]?.company_address}</h5>
        </div>
        <div className="ponum">
          <h4>{data?.appData[0]?.po_no}</h4>
        </div>
        <div className="podate">
          <h5>{checkDateDiff(data?.appData[0]?.po_date).formatDate}</h5>
        </div>
        <div className="venid">
          <h4>02</h4>
        </div>
        <div className="sellername">
          <h4>{data?.appData[0]?.seller_name}</h4>
        </div>
        <div className="selleradd">
          <h5>{data?.appData[0]?.seller_address}</h5>
        </div>
        <div className="buyername">
          <h4>{data?.appData[0]?.buyer_name}</h4>
        </div>
        <div className="buyeradd">
          <h5>{data?.appData[0]?.buyer_address}</h5>
        </div>
        <div className="ship">
          <h5>{data?.appData[0]?.shipping_method}</h5>
        </div>
        <div className="payterm">
          <h5>{data?.appData[0]?.payment_terms}</h5>
        </div>
        <div className="datereq">
          <h5>{checkDateDiff(data?.appData[0]?.required_date).formatDate}</h5>
        </div>
        <div className="desc">
          <h4>{data?.appData[0]?.item_desc}</h4>
        </div>
        <div className="quant">
          <h4>{data?.appData[0]?.quantity}</h4>
        </div>
        <div className="unitprice">
          <h4>{data?.appData[0]?.unit_price}</h4>
        </div>
        <div className="amt">
          <h4>{data?.appData[0]?.amount}</h4>
        </div>
        <div className="total">
          <h4>{data?.appData[0]?.amount}</h4>
        </div>
      </div>
    </>
  );
};

export default PoInvoice;
