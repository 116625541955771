import {firebase, auth} from "./firebase/config";

const sendOTP = async (phone) => {
  try {
    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
    const otpAuthRes = await auth.signInWithPhoneNumber(phone, verify);
    return {status: true, otpAuthRes};
  } catch (err) {
    console.error(err);
    return {status: false, error: err};
  }
};

export default sendOTP;
