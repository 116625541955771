import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { SERVER_PUBLIC } from "config/app-config";
import loginads from "assets/img/login_img.jpeg";

const AdsSlider = ({ data }) => {
  if (!data || data?.length === 0)
    return (
      <div className="h-100 d-flex align-items-center justify-content-center flex-column dashboard_modal">
        <img src={loginads} alt="" className="img-fluid" />
      </div>
    );

  return (
    <div className="ads_slider h-100">
      <Carousel fade>
        {data?.map((img) => (
          <Carousel.Item key={img.images}>
            <img
              className="d-block w-100 modal_ads "
              src={`${SERVER_PUBLIC}/ads/${img.images}`}
              alt="First slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default AdsSlider;
