import React from "react";
import Box from "../Box/Box";
import {FaBoxes} from "react-icons/fa";
import {SiGoogleads} from "react-icons/si";
import {BsFillCalendarEventFill} from "react-icons/bs";
import {HiUserGroup} from "react-icons/hi";
import BarChart from "../Chart/BarChart";
import {UserData} from "../Chart/TestData";
import {chartOne} from "../AdminDash/ChartConfig";
import {PolarArea} from "react-chartjs-2";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import AdsSlider from "components/adsModals/AdsSlider";
import GuestTable from "../GuestTable";
import MemberTable from "../MemberTable";
import AdsTable from "../AdsTable";
import EventTable from "../EventTable";

const MemberDash = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const {data, loading, error} = useFetch(`/dashboard/member`);
  useInternalServer(error);
  const adminCount = data?.appData;
  const chartOneData = chartOne(UserData);

  const polardata = {
    labels: ["Member", "Guest", "Advertiser"],
    datasets: [
      {
        label: "Total Count",
        data: [45, 78, 37],
        backgroundColor: ["#ff5b5b", "#ff8acc", "#f5c851"],
      },
    ],
  };
  if (loading) return <DashLoading />;
  return (
    <>
      <div className="guestdash">
        <div className="boxrow1">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Total Events"
                count={adminCount?.totalEvents?.data?.[0]?.total_events}
                body="Events"
                icon={<BsFillCalendarEventFill />}
                color="icon"
                to="/dashboard/view-events"
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Running Ads"
                count={adminCount?.totalAds?.data?.[0]?.total_ads}
                body="Ads Running"
                icon={<SiGoogleads />}
                color="icon green"
                to="/"
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Total Users"
                count={adminCount?.totalUsers?.data?.[0]?.total_users}
                body="Users"
                icon={<HiUserGroup />}
                color="icon yellow"
                to="/"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Productions"
                count={adminCount?.totalNonMebers?.data?.[0]?.total_nonmebers}
                body="Mills"
                icon={<FaBoxes />}
                color="icon pink"
                to="/"
              />
            </div>
          </div>
        </div>

        <div className="boxrow2 mt-lg-3">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="left">
                <div className="pie mt-3">
                  <div className="bg-white p-3 rounded-0 chart">
                    <GuestTable data={adminCount?.lastGuest?.data} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="right">
                <div className="ads h-100 mt-lg-3">
                  <AdsSlider data={adsInfo?.data?.appData} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* row3 */}
        <div className="boxrow2 mt-3">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <MemberTable data={adminCount?.lastMembers?.data} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <EventTable data={adminCount?.lastEvents?.data} />
            </div>
          </div>
        </div>
        {/* <div className="chart">
          <div className="row mt-3">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="bg-white p-3 rounded-2">
                <BarChart chartData={chartOneData} />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="bg-white p-3 rounded-2 polar text-center">
                <PolarArea data={polardata} />
              </div>
            </div>
          </div>
        </div> */}
        {/* row4 */}
        <div className="boxrow2 mt-3">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <AdsTable data={adminCount?.lastAds?.data} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6"></div>
          </div>
        </div>

        {/* row5 */}
      </div>
    </>
  );
};

export default MemberDash;
