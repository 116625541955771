import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine, RiImageAddFill } from "react-icons/ri";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import { SERVER_PUBLIC } from "config/app-config";
import "./AdminViewPDF.css";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { usePictureUpdate } from "stores/usePictureUpdate";
import AppModal from "components/modals/AppModal";
import ChangeEventPicture from "components/modals/ChangeEventPicture";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import MemberDelete from "components/modals/MemberDelete";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import Tooltip from "components/Tooltip/Tooltip";

const AdminViewPDF = () => {
  const { data, loading, error } = useFetch("/pdf");
  useInternalServer(error);
  const navigate = useNavigate();
  const {
    showPictureModal,
    setShowPictureModal,
    updateEventPicture,
    openShowPictureModal,
    showPdfModal,
    setShowPdfModal,
  } = usePictureUpdate();
  const {
    deleteConfirmModal,
    setDeleteConfirmModal,
    handleDelete,
    handleClick,
  } = useDeleteCancelUser();

  if (loading) return <Loading />;

  const columns = [
    {
      text: "Relavent Image",
      dataField: "profile_img",
      formatter: (col, row) => (
        <div className="profileImgTable">
          <img
            src={`${SERVER_PUBLIC}/pdf/${row?.image}`}
            className="img-fluid w-25"
            alt=""
          />
          <a
            href={`${SERVER_PUBLIC}/pdf/${row?.image}`}
            target="_blank"
            rel="noreferrer"
          >
            view
          </a>
        </div>
      ),
    },
    {
      text: "Title",
      dataField: "title",
      sort: true,
    },
    {
      text: "Description ",
      dataField: "pdfdesc",
      sort: true,
    },

    {
      text: "Actions",
      dataField: "visible",
      formatter: (col, row) => (
        <div className="btn-group text-white" role="group">
          <Tooltip title="View PDF">
            <span
              className="vm_btn cursor-pointer success text-white btn"
              onClick={() => navigate(`/dashboard/pdf?pdf=${row.pdffile}`)}
            >
              <AiFillEye />
            </span>
          </Tooltip>
          <Tooltip title="Change Image">
            <span
              className="vm_btn bg-info btn cursor-pointe text-white"
              onClick={() => openShowPictureModal(row?.image, row?.id)}
            >
              <RiImageAddFill />
            </span>
          </Tooltip>
          <Tooltip title="Delete PDF">
            <span
              className="vm_btn danger cursor-pointer text-white btn"
              onClick={() => handleClick(row?.id, "delete")}
            >
              <RiDeleteBinLine />
            </span>
          </Tooltip>
          <Tooltip title="Change PDF">
            <span
              className="vm_btn text-white bg-secondary cursor-pointer btn"
              onClick={() => openShowPictureModal(row?.pdffile, row?.id, true)}
            >
              <BsFillFileEarmarkPdfFill />
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span
              className="vm_btn text-white cursor-pointer btn bg-warning"
              onClick={() =>
                navigate(`/dashboard/upload-pdf?updateId=${row.id}`)
              }
            >
              <BiEdit />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="adminviewpdf">
      <Breadcrumbs current="Admin View PDF">Admin View PDF</Breadcrumbs>
      <AppModal
        show={showPictureModal}
        changeShow={setShowPictureModal}
        title="Change PDF Relavent Picture"
      >
        <ChangeEventPicture
          handleUpdate={updateEventPicture}
          api="/updatepdf-img"
        />
      </AppModal>
      <AppModal
        show={showPdfModal}
        changeShow={setShowPdfModal}
        title="Change PDF File"
      >
        <ChangeEventPicture
          handleUpdate={updateEventPicture}
          api="/update-pdf"
          pdf={true}
        />
      </AppModal>
      <AppModal
        show={deleteConfirmModal}
        changeShow={setDeleteConfirmModal}
        title="Delete Confirmation"
      >
        <MemberDelete
          title="Do you want Delete PDF !"
          setShow={setDeleteConfirmModal}
          handleDelete={() => handleDelete("pdf")}
        />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{ sortCaret }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default AdminViewPDF;

// const columns = [
//   {
//     name: "Relavent Image",
//     selector: (row) => (
//       <div className="imgbx w-25">
//         <img
//           src={`${SERVER_PUBLIC}/pdf/${row?.image}`}
//           className="img-fluid w-25"
//           alt=""
//         />
//         <a
//           href={`${SERVER_PUBLIC}/pdf/${row?.image}`}
//           target="_blank"
//           rel="noreferrer"
//         >
//           view
//         </a>
//       </div>
//     ),
//   },

//   {
//     name: "Title",
//     selector: (row) => row.title,
//   },
//   {
//     name: "Description",
//     selector: (row) => row.pdfdesc,
//     style: {
//       width: "10px",
//       hide: Media.MD,
//     },
//   },
//   {
//     name: "Actions",
//     selector: (row) => (
//       <div>
//         <span
//           className="vm_btn text-primary me-2 cursor-pointer"
//           onClick={() => navigate(`/dashboard/pdf?pdf=${row.pdffile}`)}
//         >
//           <AiFillEye
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="View"
//           />
//         </span>
//         <span
//           className="vm_btn text-info me-2 cursor-pointer"
//           onClick={() => openShowPictureModal(row?.image, row?.id)}
//         >
//           <RiImageAddFill
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Change Image"
//           />
//         </span>
//         <span
//           className="vm_btn text-danger cursor-pointer"
//           onClick={() => handleClick(row?.id, "delete")}
//         >
//           <RiDeleteBinLine
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Delete"
//           />
//         </span>
//         <span
//           className="vm_btn ms-3 text-success cursor-pointer"
//           onClick={() => openShowPictureModal(row?.pdffile, row?.id, true)}
//         >
//           <BsFillFileEarmarkPdfFill
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="View PDF"
//           />
//         </span>
//         <span
//           className="vm_btn ms-3 text-success cursor-pointer"
//           onClick={() => navigate(`/dashboard/upload-pdf?updateId=${row.id}`)}
//         >
//           <BiEdit
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Edit"
//           />
//         </span>
//       </div>
//     ),
//   },
// ];
