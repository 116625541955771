import React, { useEffect } from "react";
import AdminDash from "components/dashboard/MainDash/AdminDash/AdminDash";
import GuestDash from "components/dashboard/MainDash/GuestDash/GuestDash";
import MemberDash from "components/dashboard/MainDash/MemberDash/MemberDash";
import { appUserStore } from "stores/appUser";
import { ROLES } from "config/app-config";
import privateAPI from "api/privateAPI";
import checkMemberShip from "utils/checkMemberShip";
import checkDateDiff from "utils/checkDateDiff";
import { alertPromise } from "components/toast/appAlert";
import "./dashboard.css";

const DashboardPage = () => {
  const { role, payment_date, member_id } = appUserStore((state) => state.user);
  const { updatePaymentID, updateRole } = appUserStore((state) => state);

  useEffect(() => {
    async function verifyMemberExpire() {
      const expireDate = checkMemberShip(payment_date);
      const { isValidity } = checkDateDiff(expireDate);
      if (!isValidity) {
        const res = privateAPI.put(`/member-expire/${member_id}`);
        await alertPromise(res);
        updatePaymentID(null);
        updateRole(["1122"]);
      }
    }
    if (payment_date) {
      verifyMemberExpire();
    }
  }, [payment_date, member_id, updatePaymentID, updateRole]);


  if (role[0] === ROLES.ADMIN) return <AdminDash />;
  else if (role[0] === ROLES.MEMBER) return <MemberDash />;
  else return <GuestDash />;
};

export default DashboardPage;
