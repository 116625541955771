import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import React, { useState } from "react";

const InsertPayment = () => {
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = privateAPI.post("/post-payment", {
      reason,
      amount,
      type,
      companyName,
    });
    await alertPromise(res);
    e.target.reset();
  };

  return (
    <div className="insertpayment">
      <form onSubmit={handleSubmit}>
        <label className="app_label ">Company Name</label>
        <input
          type="text"
          required
          onChange={(e) => setCompanyName(e.target.value)}
          className="app_input"
          autoFocus
        />
        <label className="app_label ">Reason for Transaction</label>
        <textarea
          className="app_input"
          required
          onChange={(e) => setReason(e.target.value)}
        ></textarea>
        <label className="app_label ">Transaction Amount</label>
        <input
          type="number"
          required
          onChange={(e) => setAmount(e.target.value)}
          className="app_input"
        />
        <div className="d-flex mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="CREDIT"
              onChange={(e) => setType(e.target.value)}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Credit
            </label>
          </div>
          <div className="form-check ms-3">
            <input
              className="form-check-input"
              type="radio"
              value="DEBIT"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={(e) => setType(e.target.value)}
            />
            <label className="form-check-label " htmlFor="flexRadioDefault2">
              Debit
            </label>
          </div>
        </div>
        <button type="submit" className="app_btn primary rounded px-5 mt-3">
          Insert
        </button>
      </form>
    </div>
  );
};

export default InsertPayment;
