import React from "react";
import { Modal } from "react-bootstrap";
import AdsSlider from "./AdsSlider";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import useFetch from "hooks/useFetch";
import Loading from "components/loading/Loading";
import useInternalServer from "hooks/useInternalServer";
const AdsDashboard = (props) => {
  const { data, loading, error } = useFetch("/ads/public?position=Dashboard Popup");

  useInternalServer(error);

  if (loading) return <Loading />;

  return (
    <Modal show={props.show} centered backdrop="static">
      <div
        className="close_modal_btn cursor-pointer"
        onClick={() => props.changeShow(false)}
      >
        <MdClose className="d-flex" />
      </div>
      <div className="ms-2 py-3">
        <NavLink to="join-member" className="ads_modal join_btn">
          Become a Member
        </NavLink>
      </div>
      <AdsSlider data={data?.appData} />
    </Modal>
  );
};

export default AdsDashboard;
