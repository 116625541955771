import React from "react";
import {useViewData} from "stores/useViewData";
import checkDateDiff from "utils/checkDateDiff";

const ViewAds = () => {
  const {viewData} = useViewData((state) => state);
  return (
    <div>
      <h6>Ads Position</h6>
      <p>{viewData?.position}</p>
      <h6>Ads Duration</h6>
      <p>{viewData?.duration}</p>
      <h6>Ads Price</h6>
      <p>{viewData?.price}</p>
      <h6>Created at</h6>
      <p>{checkDateDiff(viewData?.created_at).formatDate?.split(" ")[0]}</p>
      {viewData?.decline_reason && (
        <>
          <h6>Decline Reason</h6>
          <p>{viewData?.decline_reason}</p>
        </>
      )}
      <h6>Payment Status</h6>
      <p>
        {viewData?.expire_status === "active"
          ? "Paid"
          : viewData?.expire_status === "expire"
          ? "Expired"
          : "Pending"}
      </p>
    </div>
  );
};

export default ViewAds;
