import React from "react";
import img from "assets/img/president_bg.png";
import "./presidentmsg.css";
import useView from "hooks/useView";

const PresidentMsg = () => {
  const view = useView();
  return (
    <div className={`presidentmsg ${view}`}>
      <div className="container">
        <h4>
          <span>President</span> Message
        </h4>
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="content">
              <p>
                <span>Dear Friends,</span> <br />
                We have all come around to living our lives and managing our
                businesses around covid. While it has not been a pleasant
                journey we have to think out of the box and be mentally prepared
                to face the ever-evolving challenges. Businesses have become
                very competitive and operating with optimum efficiency always
                has become the only mantra to stay afloat.We are again facing
                the onslaught of rampant increase in raw materials and other
                ancillary costs and given the extreme competitiveness within our
                industry we will all face very challenging times ahead. I hope
                all of us are able to overcome this critical phase & emerge
                stronger from this crisis.At SICBMA we are very hopeful that
                slowly as the economy opens up the volume of business will grow
                and we can develop more avenues for our product. The global
                awareness on the hazards of single use plastic should give our
                industry a chance to leverage ourselves to augment of business
                to the changing needs. Over the past two years we have tried
                hard to reach each one of our members through the online medium
                and we have been fairly successful in the same. However, we miss
                the personal one on one interaction which has been the hallmark
                of SICBMA activities. With the lockdowns easing in almost all
                areas within our region we look forward to more physical
                interactive programs and seminars in the year ahead.The quarter
                ahead is the festivity season in our country which traditionally
                boosts our industry too. I am hopeful that this quarter will put
                all of us on the path of recovery and growth and will help
                augment our business.
                <br />
                Warm Regards,
                <br />
                <span>S.Ramesh</span>
                <br />
                President
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <img
              src={img}
              alt="img"
              className="img-fluid bg-white p-2 rounded-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresidentMsg;
