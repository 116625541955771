import React from "react";
import "./header.css";
import useView from "hooks/useView";
import { NavLink } from "react-router-dom";

const Header = ({ title }) => {
  const view = useView();
  return (
    <div className={`page_header ${view}`}>
      <div className="content">
        <h1>{title}</h1>
        <NavLink className="join_btn" to="/user/auth/signup">
          Join Member
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
