const imageSize = {
  dashboard: {
    width: 400,
    height: 500,
  },
  dashboardFixed: {
    width: 588,
    height: 508
  },
  login: {
    width: 400,
    height: 650,
  },
  webPage: {
    width: 400,
    height: 500,
  },
};

const adsImageWidthHeight = (img, type) => {
  if (
    type === "Dashboard Popup" &&
    imageSize.dashboard.width === img.width &&
    imageSize.dashboard.height === img.height
  ) {
    return true;
  } else if (
    type === "Login Page" &&
    imageSize.login.width === img.width &&
    imageSize.login.height === img.height
  ) {
    return true;
  } else if (
    type === "Web Page Popup" &&
    imageSize.webPage.width === img.width &&
    imageSize.webPage.height === img.height
  ) {
    return true;
  } else if (
    type === "Dashboard Fixed" &&
    imageSize.dashboardFixed.width === img.width &&
    imageSize.dashboardFixed.height === img.height
  ) {
    return true;
  }
  else {
    return false;
  }
};

export default adsImageWidthHeight;
