import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import "./navbar.css";
import useView from "hooks/useView";
import logo from "assets/img/logo_hor_png.png";
import {NavLink} from "react-router-dom";
import {appUserStore} from "stores/appUser";

const MyNavbar = () => {
  const view = useView();
  const user = appUserStore((state) => state.user);

  return (
    <div className={`mynav ${view} container-fluid`}>
      <Navbar collapseOnSelect expand="lg" className="nav_warpper">
        <Navbar.Brand href="#home" className="w-25 p-0 m-0">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="w-100">
          <Nav>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/committee">Committee</NavLink>
            <NavLink to="/members">Membership</NavLink>
            <NavLink to="/mills">Paper Mill Production Plan</NavLink>
            <NavLink to="/classified">Classified</NavLink>
            <NavLink to="/gallery">Gallery</NavLink>

            <NavLink to="/contact">Contact</NavLink>
          </Nav>
          <div className="auth_btn">
            {user?.id ? (
              <Nav className="ms-lg-3">
                <NavLink to="/dashboard" className="login shadow">
                  My Dashboard
                </NavLink>
              </Nav>
            ) : (
              <>
                <NavLink
                  className="reg text-decoration-underline"
                  to="/user/auth/signup"
                >
                  Create Account
                </NavLink>
                <NavLink
                  className="ms-lg-3 login shadow"
                  to="/user/auth/signin"
                >
                  Login
                </NavLink>
              </>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MyNavbar;
