import React, {useState} from "react";
import privateAPI from "api/privateAPI";
import {appUserStore} from "stores/appUser";
import {alertSuccess, alertError} from "components/toast/appAlert";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";

const Production = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const user = appUserStore((state) => state.user);
  const [bf, setBf] = useState("");
  const [col, setCol] = useState("");
  const [date, setDate] = useState("");
  const [formatDate, setFormatDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setFormatDate(moment(date).format());
      const res = await privateAPI.post("/production", {
        uid: user?.id,
        bf,
        col,
        date: formatDate,
      });
      alertSuccess(res?.data?.message);
      e.target.reset();
    } catch (e) {
      alertError(e?.response?.data?.message);
    }
  };

  return (
    <div className="join_member">
      <Breadcrumbs current="Production">Production</Breadcrumbs>
      <div className="container ">
        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-6 bg-white rounded pb-4 px-1 px-lg-3 p-3 px-3">
            <form onSubmit={handleSubmit}>
              <label className="app_label ">Select BF</label>
              <select
                id="cars"
                className="app_input"
                required
                onChange={(e) => setBf(e.target.value)}
              >
                <option value="">Select BF</option>

                <option>16BF</option>
                <option>18BF</option>
                <option>20BF</option>
                <option>22BF</option>
                <option>24BF</option>
                <option>26BF</option>
                <option>28BF</option>
                <option>30BF</option>
                <option>32BF</option>
              </select>

              <label className="app_label">Select COL</label>
              <select
                className="app_input"
                required
                onChange={(e) => setCol(e.target.value)}
              >
                <option value="">Select COL</option>
                <option>NS</option>
                <option>GYT</option>
              </select>
              <label className="app_label ">Product Date</label>
              <ReactDatePicker
                selected={date}
                dateFormat="yyyy-MM-dd hh:mm aa"
                showTimeSelect
                onChange={(date) => setDate(date)}
                minDate={moment().toDate()}
                placeholderText="Select a Production Date and Time"
                className="app_input"
                name="eventDate"
                autoComplete={false}
              />
              <button
                type="submit"
                className="app_btn primary rounded px-5 mt-3"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="ads h-100">
              <AdsSlider data={adsInfo?.data?.appData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
