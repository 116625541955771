import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import checkDateDiff from "utils/checkDateDiff";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";

const ViewTransaction = () => {
  const { accountId } = useParams();
  const { data, loading, error } = useFetch(`/transections/${accountId}`);
  useInternalServer(error);
  if (loading) return <DashLoading />;

  const columns = [
    {
      text: "Transaction Id",
      sort: true,
      dataField: "id",
      formatter: (col, row) => {
        return <NavLink to={`/dashboard/invoice/${col}`}>#{col}</NavLink>;
      },
    },
    {
      text: "Reason",
      sort: true,
      dataField: "reason",
    },
    {
      text: "Available Amount",
      sort: true,
      dataField: "avail_amount",
    },
    {
      text: "Transaction Amount",
      sort: true,
      dataField: "amount",
    },
    {
      text: "Balance Amount",
      sort: true,
      dataField: "balance_amount",
    },
    {
      text: "Date of Transaction",
      dataField: "payment_date",
      sort: true,
      formatter: (col, row) => {
        return <span>{checkDateDiff(col).formatDate}</span>;
      },
    },
    {
      text: "Account Type",
      sort: true,
      dataField: "account_type",
      formatter: (col, row) => {
        if (col === "CREDIT")
          return <span className="badge success">{col}</span>;
        return <span className="badge danger">{col}</span>;
      },
    },
  ];
  return (
    <div>
      <Breadcrumbs current="View Transaction">View Transaction</Breadcrumbs>

      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps}></TableSearch>
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{ sortCaret }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewTransaction;
