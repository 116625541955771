import React, { useCallback } from "react";
import "./ViewEvents.css";
import { ImLocation } from "react-icons/im";
import { FaRupeeSign } from "react-icons/fa";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import CardPagination from "components/CardPagination/CardPagination";
import { appUserStore } from "stores/appUser";
import { alertError } from "components/toast/appAlert";
import { useNavigate } from "react-router-dom";
import makePayment from "services/razorpay/eventPayment";
import useRazorpay from "react-razorpay";
import useFetch from "hooks/useFetch";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import { SERVER_PUBLIC } from "config/app-config";
import checkDateDiff from "utils/checkDateDiff";
import { MdAccessTimeFilled } from "react-icons/md";
import { useViewData } from "stores/useViewData";
import AppModal from "components/modals/AppModal";
import ViewEvent from "components/modals/ViewEvent";

const ViewEvents = () => {
  const { data, loading, error } = useFetch("/events");
  useInternalServer(error);
  const { setViewData, viewModal, setViewModal } = useViewData();
  if (loading) return <Loading />;

  return (
    <div className="viewevent ">
      <Breadcrumbs current="Events">Events</Breadcrumbs>
      <AppModal show={viewModal} changeShow={setViewModal} title="Event Info">
        <ViewEvent />
      </AppModal>
      <div className="row mt-3">
        <CardPagination items={data?.appData} itemsPerPage={8}>
          <Events setViewData={setViewData} />
        </CardPagination>
      </div>
    </div>
  );
};

const Events = (props) => {
  return (
    <>
      {props?.currentItems?.map((event, i) => (
        <div className="col-12 col-md-6 col-lg-3 view-head" key={i}>
          <div className="app_card p-2">
            <div className="image">
              <img
                src={`${SERVER_PUBLIC}/events/${event.image}`}
                alt=""
                className="img-fluid mt-1"
              />
            </div>

            <h5 className="my-2 mt-3">{event?.name}</h5>
            <div className="date text-center mt-3">
              <div className="time">
                <h5>
                  <MdAccessTimeFilled /> {checkDateDiff(event?.date).formatDate}
                </h5>
              </div>
            </div>
            <div className="address">
              <p className="m-0">
                <ImLocation /> {event?.venue},{event?.venue_address}
              </p>
            </div>
            <div className="add">
              <p className="m-0 ms-4">{event?.venue_city}</p>
            </div>

            <div className="row mt-3 justify-content-between ">
              <div className="col-6">
                <div className="text-start">
                  <EventBookBtn
                    event={event}
                    setViewData={props?.setViewData}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="price mt-1 text-end">
                  <h5>
                    <FaRupeeSign />
                    {event?.price}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const EventBookBtn = ({ event, setViewData }) => {
  // eslint-disable-next-line no-unused-vars
  const { data, loading, error } = useFetch(
    `/events-payments?eid=${event?.event_id}`
  );
  const { isValidity } = checkDateDiff(event?.date);
  const user = appUserStore((state) => state.user);
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const handlePayment = useCallback(
    (price, eid) => {
      if (!user?.member_id) {
        alertError("Access denied Only Member can Access");
        return navigate("/dashboard");
      }
      makePayment(Razorpay, price, user?.member_id, eid, navigate);
    },
    [Razorpay, navigate, user?.member_id]
  );

  if (user?.role[0] === "1122")
    return (
      <div className="get-now">
        <button
          type="button"
          className="app_btn success"
          onClick={() => alertError("Memeber can only Book Events !")}
        >
          Book Now
        </button>
      </div>
    );
  else if (isValidity) {
    if (error) {
      return (
        <div className="booked">
          <button type="button" className="app_btn info">
            Booked
          </button>
        </div>
      );
    } else if (event?.cancel_reason) {
      return (
        <div className="finished">
          <button
            type="button"
            className="app_btn warning"
            onClick={() => setViewData(event)}
          >
            Cancelled
          </button>
        </div>
      );
    } else {
      return (
        <button
          type="button"
          className="app_btn success"
          onClick={() => handlePayment(event?.price, event?.event_id)}
        >
          Book Now
        </button>
      );
    }
  } else {
    return (
      <div className="finished">
        <button type="button" className="app_btn danger">
          Finished
        </button>
      </div>
    );
  }
};

export default ViewEvents;
