import React, { useState } from "react";
import { alertPromise } from "components/toast/appAlert";
import { useNavigate } from "react-router-dom";
import privateAPI from "api/privateAPI";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { appUserStore } from "stores/appUser";

const JoinMember = () => {
  const auth = appUserStore((state) => state.user);
  const updateMid = appUserStore((state) => state.updateMemberId);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [cname, setcname] = useState("");
  const [caddress, setcaddress] = useState("");
  const [ccity, setccity] = useState("");
  const [resaddress, setresaddress] = useState("");
  const [rescity, setRescity] = useState("");
  const [gstno, setgstno] = useState("");
  const [reg, setReg] = useState("");
  const [est, setEst] = useState("");
  const [msme, setMsme] = useState("");
  const [website, setWebsite] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");
  const [lanMobile, setLanMobile] = useState("");
  const [state, setState] = useState("");
  const [comPincode, setComPincode] = useState("");
  const [resState, setResState] = useState("");
  const [resPincode, setResPincode] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = privateAPI.post("/join-member", {
      email,
      cname,
      caddress,
      ccity,
      resaddress,
      rescity,
      gstno,
      reg,
      est,
      msme,
      website,
      alternateMobile,
      lanMobile,
      state,
      comPincode,
      resState,
      resPincode,
      phone: localStorage.getItem("member-phone") || auth?.phone,
    });
    const data = await alertPromise(res);
    if (localStorage.getItem("member-phone")) {
      localStorage.setItem("member-id", data?.data?.appData?.mid);
    } else {
      updateMid(data?.data?.appData?.mid);
    }
    e.target.reset();
    navigate("/dashboard/subscription");
  };
  return (
    <>
      <Breadcrumbs current="Join Member">Join Member</Breadcrumbs>
      <form className="pb-3 w-100" onSubmit={handleSubmit}>
        <div className="row bg-white rounded pb-4 px-1 px-lg-3">
          <h6 className="my-4">Company Details</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Name</label>
            <input
              type="text"
              className="app_input order-1"
              onChange={(e) => setcname(e.target.value)}
              required
              autoFocus
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Address</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setcaddress(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">State</label>
            <input
              type="text"
              placeholder=" "
              className="app_input"
              onChange={(e) => setState(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">City</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setccity(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Pincode</label>
            <input
              type="number"
              className="app_input"
              onChange={(e) => setComPincode(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Email</label>
            <input
              type="email"
              placeholder=" "
              className="app_input order-0"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">MSME Reg.No.</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setMsme(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Estabilishment Date</label>
            <input
              type="date"
              className="app_input "
              required
              onChange={(e) => setEst(e.target.value)}
            />
          </div>

          <div className="col-12 col-lg-6">
            <label className="app_label">GST Number</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setgstno(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Registration Date</label>
            <input
              type="date"
              className="app_input"
              onChange={(e) => setReg(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row bg-white px-1 px-lg-3 pb-4 mt-4 rounded">
          <h6 className="my-4">Residential Details</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Residential Address</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setresaddress(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Residential State</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setResState(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Residential City</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setRescity(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Residential Pincode</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setResPincode(e.target.value)}
            />
          </div>
        </div>
        <div className="row bg-white mt-4 px-1 px-lg-3 pb-4 rounded">
          <h6 className="my-4">Other Details</h6>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Alternative Mobile Number</label>
            <input
              type="number"
              className="app_input"
              onChange={(e) => setAlternateMobile(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label m-0">Website address</label>
            <input
              type="text"
              className="app_input"
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <label className="app_label">Landline Number</label>
            <input
              type="number"
              className="app_input"
              onChange={(e) => setLanMobile(e.target.value)}
            />
          </div>
        </div>
        <button type="submit" className="app_btn primary rounded px-5 mt-3">
          Join Now
        </button>
      </form>
    </>
  );
};

export default JoinMember;
