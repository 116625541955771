import privateAPI from "../../api/privateAPI";
import { RAZORPAY_ID } from "../../config/app-config";
import { alertPromise } from "../../components/toast/appAlert";

const adsPayment = async (Razorpay, amount, uid, aid, duration, navigate) => {
  const order = await privateAPI.post("/make-payment", {
    amount,
    reason: "ADVERTISEMENT",
    productId: aid,
  });
  const RazorpayOptions = {
    key: RAZORPAY_ID,
    amount: amount + "00",
    currency: "INR",
    name: "SICBMA",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: order.id,
    handler: async (res) => {
      const { razorpay_payment_id } = res;
      const paymentRes = privateAPI.put("/ads-payment", {
        payment_id: razorpay_payment_id,
        uid,
        aid,
        duration,
      });
      await alertPromise(paymentRes);
      navigate("/dashboard");
    },
    prefill: {
      name: "Piyush Garg",
      email: "youremail@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzpay = new Razorpay(RazorpayOptions);
  rzpay.open();
};

export default adsPayment;
