import React from "react";
import Table from "react-bootstrap/Table";
import "../MainDash/Tables/MyTables.css";
import {SERVER_PUBLIC} from "config/app-config";

const AdsTable = ({data}) => {
  return (
    <div className="mytable h-100">
      <h6 className="mb-3">Total Ads</h6>
      <Table responsive>
        <thead>
          <tr>
            <th>Image</th>
            <th>Position</th>
            <th>Duration</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td>
                <img
                  src={`${SERVER_PUBLIC}/ads/${items.images}`}
                  className="img-fluid  adimg"
                  alt=""
                />
              </td>
              <td>{items.position}</td>
              <td>{items.duration}</td>
              <td>{items.price}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdsTable;
