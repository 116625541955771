import React, {useState} from "react";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";
import {appUserStore} from "stores/appUser";

const ChangePassword = ({setShow}) => {
  const [currentPassword, setCurrentPass] = useState("");
  const [newPassword, setNewPass] = useState("");
  const user = appUserStore((state) => state.user);
  const onSubmit = async (e) => {
    e.preventDefault();
    const result = privateAPI.post("/update-password", {
      currentPassword,
      newPassword,
      uid: user.id,
    });
    await alertPromise(result);
    e.target.reset();
    setShow(false);
  };
  return (
    <div>
      <div className="insertpayment">
        <form onSubmit={onSubmit}>
          <label className="app_label ">Current Password</label>
          <input
            type="text"
            required
            className="app_input"
            autoFocus
            onChange={(e) => setCurrentPass(e.target.value)}
          />
          <label className="app_label ">New Password</label>
          <input
            type="password"
            required
            className="app_input"
            onChange={(e) => setNewPass(e.target.value)}
          />
          <button type="submit" className="app_btn primary rounded px-5 mt-3">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
