import React, { useState } from "react";
import publicAPI from "api/publicAPI";
import { alertError, alertSuccess } from "components/toast/appAlert";
import AppModal from "components/modals/AppModal";
import sendOTP from "services/auth/sendOTP";
import verifyOTP from "services/auth/verifyOTP";
import logo from "assets/img/logo.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";
import OTPModal from "./OTPModal";
import { auth } from "services/auth/firebase/config";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import AdsSlider from "components/adsModals/AdsSlider";
import Loading from "components/loading/Loading";

const Register = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [show, setShow] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpResponse, setOTPResponse] = useState("");
  const [login, setLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { data, loading } = useFetch("/categories");
  const sliderData = useFetch("/ads/public?position=Login Page");

  if (loading) return <Loading />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPass)
      return alertError("Password and Confirm Password must be the same");
    if (phone.length !== 10) return alertError("Invalid Phone Number !");
    try {
      const mobile = "+91" + phone;
      setLogin(true);
      const otpInfo = await sendOTP(mobile);
      setShow(otpInfo?.status);
      setOTPResponse(otpInfo?.otpAuthRes);
      e.target.reset();
    } catch (err) {
      alertError(err?.response?.data?.message);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      await verifyOTP(otpResponse, OTP, setShow);
      const res = await publicAPI.post("/register", {
        name,
        phone,
        email,
        password,
        category_id,
        otp_status: auth?.currentUser?.uid,
      });
      alertSuccess(res?.data?.message);
      setLogin(false);
      navigate("/user/auth/signin");
    } catch (err) {
      alertError(err?.response?.data?.message);
    }
  };

  return (
    <div className="user signupBx">
      <AppModal show={show} changeShow={setShow} title="OTP Verification">
        <OTPModal setOTP={setOTP} handleVerify={handleVerify} />
      </AppModal>
      <div className="formBx">
        <form onSubmit={handleSubmit} className="w-100">
          <div className="logo text-center mb-3">
            <img src={logo} alt="logo" className="img-fluid" />
          </div>
          <h2 className="mt-3">Create an account</h2>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
          <input
            type="number"
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number"
            required
          />
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <select onChange={(e) => setCategoryId(e.target.value)} required>
            <option value="">Select Industry</option>
            {data?.appData?.map((category) => (
              <option value={category.id} key={category.id}>
                {category.category_name}
              </option>
            ))}
          </select>
          <div className="password form-floating position-relative">
            <input
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Create Password"
              required
              value={password}
            />
            <span
              className="show_hide"
              onClick={() => setShowPassword((state) => !state)}
            >
              <div className="d-flex">
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
            </span>
          </div>
          <input
            type="password"
            onChange={(e) => setConfirmPass(e.target.value)}
            placeholder="Confirm Password"
            required
          />
          <div id="recaptcha-container"></div>
          <button
            type="submit"
            className={`fw-bolder btn ${login ? "disabled" : ""} `}
          >
            Sign Up
          </button>
          <p className="signup">
            Already have an account ?
            <NavLink to="/user/auth/signin"> Sign in.</NavLink>
          </p>
        </form>
      </div>
      <div className="imgBx">
        <AdsSlider data={sliderData?.data?.appData} />
      </div>
    </div>
  );
};

export default Register;
