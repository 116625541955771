import React, { useRef } from "react";
import IdBg from "assets/img/idcardBg.png";
import "./OnlineId.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { SERVER_PUBLIC } from "config/app-config";
import { appUserStore } from "stores/appUser";
import img from "assets/img/profile.jpg";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";
import DashLoading from "components/loading/DashLoading";
import toFirstCapital from "utils/toFirstCapital";
import Table from "react-bootstrap/Table";
import { useReactToPrint } from "react-to-print";
import useView from "hooks/useView";

const OnlineId = () => {
  const componentRef = useRef();
  const view = useView();
  const user = appUserStore((state) => state.user);
  const { data, loading, error } = useFetch(`/users?uid=${user?.id}`);
  useInternalServer(error);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  if (loading) return <DashLoading />;

  return (
    <>
      <Breadcrumbs current="Online Id">Online Id Card</Breadcrumbs>
      <button className="btn btn-success mb-2" onClick={handlePrint}>
        Print
      </button>
      <div className={`onlineId ${view}`} ref={componentRef}>
        <div>
          <div className="position-relative">
            <img src={IdBg} alt="" className="img-fluid card_bg" />
            <div className="profile_pic">
              <img
                src={`${
                  user?.profile_img
                    ? `${SERVER_PUBLIC}/profile_pictures/${user?.profile_img}`
                    : img
                }`}
                className="img-fluid "
                alt=""
              />
            </div>
            <div className="user_info">
              <Table borderless>
                <tbody>
                  <tr>
                    <td className="id_head">Name</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="id_head">Company Name</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.company_name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="id_head">Member ID</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.email)}
                    </td>
                  </tr>
                  <tr>
                    <td className="id_head">Mobile</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.phone)}
                    </td>
                  </tr>
                  <tr>
                    <td className="id_head">City</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.company_city)}
                    </td>
                  </tr>
                  <tr>
                    <td className="id_head">Adress</td>
                    <td>
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.company_address)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineId;
