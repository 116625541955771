import React, {useState, useEffect} from "react";
import privateAPI from "api/privateAPI";
import {alertError, alertPromise} from "components/toast/appAlert";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import "./UploadPdf.css";
import {AiOutlineCloudUpload} from "react-icons/ai";
import useUrlQuery from "hooks/useUrlQuery";
import {useNavigate} from "react-router";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";

const UploadPdf = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const updateId = useUrlQuery("updateId");
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function loadPdfUpdate() {
      const res = await privateAPI.get(`/pdf?rid=${updateId}`);
      const pdfInfo = res?.data?.appData[0];
      setTitle(pdfInfo?.title);
      setDesc(pdfInfo?.pdfdesc);
    }
    if (updateId) loadPdfUpdate();
    else {
      setTitle("");
      setDesc("");
    }
  }, [updateId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imageFormat = ["image/jpeg", "image/jpg", "image/png"];
    const formData = new FormData();
    formData.append("imageFile", image);
    formData.append("pdfFile", file);
    formData.append("title", title);
    formData.append("desc", desc);
    if (
      !updateId &&
      (file.type !== "application/pdf" || !imageFormat.includes(image.type))
    ) {
      return alertError("Incorrect File Format try again");
    }
    if (updateId) {
      const res = privateAPI.put(`/pdf/${updateId}`, {title, desc});
      await alertPromise(res);
      navigate("/dashboard/admin/view-pdf");
    } else {
      const res = privateAPI.post("/pdf", formData);
      await alertPromise(res);
      e.target.reset();
    }
  };

  return (
    <>
      <Breadcrumbs current="Upload PDF">
        {updateId ? "Update PDF" : "Upload PDF"}
      </Breadcrumbs>
      <div className="uploadpdf">
        <form onSubmit={handleSubmit} encType="multipart/form-data ">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="content">
                {!updateId && (
                  <div className="row">
                    <div className="col-6">
                      <div className="uploadimg bg-white py-2 rounded">
                        <label className="filebutton">
                          <AiOutlineCloudUpload />
                          <br />
                          <small className="app_label">
                            Select PDF File to Upload
                          </small>
                          <span>
                            <input
                              type="file"
                              className="app_input"
                              accept=".pdf"
                              onChange={(e) => setFile(e.target.files[0])}
                              required={updateId ? false : true}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="uploadimg bg-white py-2 rounded">
                        <label className="filebutton">
                          <AiOutlineCloudUpload />
                          <br />
                          <small className="app_label">
                            Upload Relevent Image
                          </small>
                          <span>
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              name="imageFile"
                              onChange={(e) => setImage(e.target.files[0])}
                              required={updateId ? false : true}
                              className="app_input"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="bg-white px-3 mt-2">
                  <label className="app_label">Title</label>
                  <input
                    type="text"
                    value={title || ""}
                    onChange={(e) => setTitle(e.target.value)}
                    required={updateId ? false : true}
                    className="app_input"
                  />
                  <label className="app_label">Description</label>
                  <textarea
                    type="text"
                    value={desc || ""}
                    onChange={(e) => setDesc(e.target.value)}
                    required={updateId ? false : true}
                    className="app_input"
                  />
                  <div className="text-start py-2">
                    <button type="submit" className="app_btn primary">
                      {updateId ? "Update Pdf" : "Upload PDF"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="ads">
                <AdsSlider data={adsInfo?.data?.appData} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadPdf;
