import React, { useState } from "react";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";
import { useNavigate } from "react-router";
import DashLoading from "components/loading/DashLoading";
import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import { SERVER_PUBLIC } from "config/app-config";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { GiSandsOfTime } from "react-icons/gi";
import { FaRupeeSign } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import checkDateDiff from "utils/checkDateDiff";
import "./AdminViewAds.css";
import AppModal from "components/modals/AppModal";
import AdsConform from "components/modals/AdsConform";
import toFirstCapital from "utils/toFirstCapital";
import CardPagination from "components/CardPagination/CardPagination";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import Reason from "components/modals/Reason";
import { AiFillEye } from "react-icons/ai";
import { useViewData } from "stores/useViewData";
import ViewAds from "components/modals/ViewAds";
import Tooltip from "components/Tooltip/Tooltip";

const AdminViewAds = () => {
  const [show, setShow] = useState(false);
  const [uid, setUid] = useState("");
  const [aid, setAid] = useState("");
  const { data, loading, error } = useFetch(`/ads/admin`);
  const navigate = useNavigate();
  useInternalServer(error);

  const {
    cancelConfirmModal,
    setCancelConfirmModal,
    handleClick,
    handleCancel,
  } = useDeleteCancelUser();
  const { setViewData, viewModal, setViewModal } = useViewData();

  if (loading) return <DashLoading />;

  const handleConfirm = (uid, aid) => {
    setShow(true);
    setAid(aid);
    setUid(uid);
  };

  const handleApprove = async () => {
    setShow(false);
    const res = privateAPI.put(`/ads`, { uid, aid });
    const { data } = await alertPromise(res);
    if (!data?.error) {
      navigate("/dashboard/view-ads");
    }
  };

  const ApproveAds = ({ ads }) => {
    if (ads?.pid && ads?.expire_status === "active") {
      return <div className="app_btn success">Active</div>;
    } else if (
      ads?.is_visible === "1" &&
      (ads?.expire_status === "active" || ads?.expire_status === "make payment")
    ) {
      return <div className="app_btn warning">Approved</div>;
    } else if (ads?.expire_status === "expire")
      return <div className="app_btn danger">Expired</div>;
    else {
      return (
        <div
          className="app_btn success cursor-pointer"
          onClick={() => handleConfirm(ads?.user_id, ads?.id)}
        >
          Approve
        </div>
      );
    }
  };

  const Viewads = (props) => {
    return (
      <>
        {props?.currentItems?.map((ads, i) => (
          <div className="col-sm-12 col-md-6 col-lg-3" key={i}>
            <div className="mycard1">
              <div className="photo">
                <img
                  src={`${SERVER_PUBLIC}/ads/${ads.images}`}
                  className="img-fluid card-img-top"
                  alt=""
                />
                <a
                  href={`${SERVER_PUBLIC}/ads/${ads?.images}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  view
                </a>
              </div>
              <div className="details mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="badge bg-success">
                    {toFirstCapital(ads?.name)}
                  </span>
                  <Tooltip title="View">
                    <span
                      className="fs-5 vm_btn px-2 py-0 success text-white btn"
                      onClick={() => setViewData(ads)}
                    >
                      <AiFillEye />
                    </span>
                  </Tooltip>
                </div>
                <div className="position">
                  <h5> {ads?.position}</h5>
                </div>

                <div className="row mt-3">
                  <div className="col-6">
                    <h6 className="text_danger d-flex align-items-center">
                      <GiSandsOfTime className="me-1" /> {ads.duration}
                    </h6>
                  </div>

                  <div className="col-6">
                    <h6 className="text_danger d-flex align-items-center">
                      <FaRupeeSign className="me-1" /> {ads?.price}
                    </h6>
                  </div>
                  <div className="col-6">
                    <h6 className="text_danger d-flex align-items-center mt-2">
                      <MdDateRange className="me-1" />
                      {checkDateDiff(ads?.created_at).formatDate?.split(" ")[0]}
                    </h6>
                  </div>

                  {ads?.payment_date && (
                    <div className="col-6">
                      <h6 className="text_danger d-flex align-items-center mt-2">
                        <FaMoneyCheckAlt className="me-1" />
                        {
                          checkDateDiff(ads?.payment_date).formatDate?.split(
                            " "
                          )[0]
                        }
                      </h6>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-6">
                    <ApproveAds ads={ads} />
                  </div>
                  <div className="col-6">
                    {!ads?.payment_id && ads?.is_visible !== "1" && (
                      <div
                        className={`app_btn ${
                          ads?.decline_reason ? "" : "outline"
                        } danger cursor-pointer`}
                        onClick={() => handleClick(ads?.id, "cancel")}
                      >
                        {ads?.decline_reason ? "Declined" : "Decline"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="adminviewads">
      <AppModal show={show} changeShow={setShow} title="Ads Confirmation">
        <AdsConform
          title="Do you want to Approve this ad ?"
          handleApprove={handleApprove}
          setShow={setShow}
        />
      </AppModal>
      <AppModal
        show={cancelConfirmModal}
        changeShow={setCancelConfirmModal}
        title="Decline Reason"
      >
        <Reason
          handlleCancel={handleCancel}
          placeholder="Reason for decline advertisement..."
          api="/ads-decline"
        />
      </AppModal>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Ads Information"
      >
        <ViewAds />
      </AppModal>

      <Breadcrumbs current="App Advertisement">Advertisement</Breadcrumbs>
      <div className="row">
        <CardPagination items={data?.appData} itemsPerPage={8}>
          <Viewads />
        </CardPagination>
      </div>
    </div>
  );
};

export default AdminViewAds;
