import React, { useCallback } from "react";
import "./viewads.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import useFetch from "hooks/useFetch";
import { appUserStore } from "stores/appUser";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import { SERVER_PUBLIC } from "config/app-config";
import useRazorpay from "react-razorpay";
import adsPayment from "services/razorpay/adsPayment";
import { useNavigate } from "react-router";
import CardPagination from "components/CardPagination/CardPagination";
import { GiSandsOfTime } from "react-icons/gi";
import { FaRupeeSign } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import checkDateDiff from "utils/checkDateDiff";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useViewData } from "stores/useViewData";
import AppModal from "components/modals/AppModal";
import ViewAdsModal from "components/modals/ViewAds";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import MemberDelete from "components/modals/MemberDelete";

const ViewAds = () => {
  const user = appUserStore((state) => state.user);
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const { setViewData, viewModal, setViewModal } = useViewData();
  const {
    deleteConfirmModal,
    setDeleteConfirmModal,
    handleClick,
    handleDelete,
  } = useDeleteCancelUser();
  const { data, loading, error } = useFetch(`/ads/public?uid=${user?.id}`);
  const handlePayment = useCallback(
    (price, uid, aid, duration) => {
      adsPayment(Razorpay, price, uid, aid, duration, navigate);
    },
    [Razorpay, navigate]
  );
  useInternalServer(error);

  if (loading) return <Loading />;

  const MakePaymentBtn = ({ row }) => {
    if (row?.payment_id && row?.expire_status === "active") {
      return <span className="app_btn success">Paid</span>;
    } else if (row.is_visible === "1") {
      return (
        <button
          type="button"
          className="app_btn success mt-1"
          onClick={() =>
            handlePayment(row?.price, row?.user_id, row?.id, row?.duration)
          }
        >
          Make Payment
        </button>
      );
    } else if (row?.decline_reason)
      return (
        <button
          className="app_btn danger mt-1"
          onClick={() => setViewData(row)}
        >
          Declined
        </button>
      );
    else {
      return <button className="app_btn info mt-1">Not Approved</button>;
    }
  };
  const Ads = (props) => {
    return (
      <>
        {props?.currentItems?.map((ad, i) => (
          <div className="col-sm-12 col-md-6 col-lg-3 " key={i}>
            <div className="viewcard">
              <img
                src={`${SERVER_PUBLIC}/ads/${ad.images}`}
                className="img"
                alt=""
              />
              <div className="details mt-3">
                <div className="position">
                  <h5 className="mb-0"> {ad?.position}</h5>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="duration">
                      <h5>
                        <GiSandsOfTime /> {ad.duration}
                      </h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="cost">
                      <h5>
                        <FaRupeeSign /> {ad?.price}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="date">
                      <h5>
                        <MdDateRange className="me-1" />
                        {
                          checkDateDiff(ad?.created_at).formatDate?.split(
                            " "
                          )[0]
                        }
                      </h5>
                    </div>
                  </div>

                  {ad?.payment_date && (
                    <div className="col-6">
                      <div className="cost">
                        <h5>
                          <FaMoneyCheckAlt />
                          {
                            checkDateDiff(ad?.payment_date).formatDate?.split(
                              " "
                            )[0]
                          }
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
                {ad?.expire_status === "expire" && (
                  <span className="badge danger"> {ad.expire_status}</span>
                )}

                <div className="d-flex align-items-center justify-content-between">
                  <MakePaymentBtn row={ad} />
                  <div className="d-flex align-items-center">
                    {ad.is_visible !== "1" && (
                      <span
                        className="text-success me-3 fs-4 cursor-pointer"
                        onClick={() =>
                          navigate(`/dashboard/post-ads?aid=${ad?.id}`)
                        }
                      >
                        <BiEdit />
                      </span>
                    )}
                    <span onClick={() => handleClick(ad?.id, "delete")}>
                      <RiDeleteBinLine className="text_danger fs-4 cursor-pointer" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };
  return (
    <div className="viewads">
      <Breadcrumbs current="View Advertisement">View Advertisement</Breadcrumbs>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Ads Information"
      >
        <ViewAdsModal />
      </AppModal>
      <AppModal
        show={deleteConfirmModal}
        changeShow={setDeleteConfirmModal}
        title="Delete Confirmation"
      >
        <MemberDelete
          title="Do you want Delete Advertisement !"
          setShow={setDeleteConfirmModal}
          handleDelete={() => handleDelete("ad")}
        />
      </AppModal>
      <div className="row pb-4">
        <CardPagination items={data?.appData} itemsPerPage={8}>
          <Ads />
        </CardPagination>
      </div>
    </div>
  );
};

export default ViewAds;
