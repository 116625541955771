import React, {useState} from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {AiOutlineCloudUpload} from "react-icons/ai";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";

const EmailCampaign = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const [image, setImage] = useState("");
  const [initiatives, setInitiatives] = useState("");
  const [subject, setSubject] = useState("");
  const [desc, setDesc] = useState("");

  const handelSubmit = async (e) => {
    e.preventDefault();
    const emaildata = new FormData();
    emaildata.append("image", image);
    emaildata.append("initiatives", initiatives);
    emaildata.append("subject", subject);
    emaildata.append("desc", desc);
    const result = privateAPI.post("/campaign/email", emaildata);
    await alertPromise(result);
  };

  return (
    <div className="emailcampaign">
      <Breadcrumbs current="Email Campaign">Email Campaign</Breadcrumbs>
      <div className="row">
        <div className="col-12 col-lg-6">
          <form onSubmit={handelSubmit} encType="multipart/form-data">
            <div className="bg-white rounded pb-4 px-2 px-lg-3 mt-3 mt-md-0">
              <div className="uploadimg bg-white py-2 rounded">
                <label className="filebutton">
                  <AiOutlineCloudUpload />
                  <br />
                  <small className="app_label">
                    Select Posters or Invitations
                  </small>
                  <span>
                    <input
                      type="file"
                      className="app_input"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </span>
                </label>
              </div>
              <label className="app_label">Select Initiatives</label>
              <select
                name="Select Position"
                className="app_input"
                onChange={(e) => setInitiatives(e.target.value)}
                required
              >
                <option value="">Select Initiatives</option>
                <option value="ALL">All Users</option>
                <option value="MEMBER">Members</option>
                <option value="GUEST">Guests</option>
                <option value="COMMITTEE">Committee</option>
                <option value="NON-MEMBER">Non-Member</option>
              </select>
              <label className="app_label">Subject</label>
              <input
                type="text"
                className="app_input"
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <label className="app_label">Description</label>
              <textarea
                type="text"
                className="app_input"
                onChange={(e) => setDesc(e.target.value)}
                required
              />
              <button
                type="submit"
                className="app_btn primary rounded px-5 mt-3"
              >
                Send
              </button>
            </div>
          </form>
        </div>

        <div className="col-12 col-md-12 col-lg-6">
          <div className="ads h-100">
            <AdsSlider data={adsInfo?.data?.appData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaign;
