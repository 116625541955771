import React, {useEffect, useState} from "react";
import privateAPI from "api/privateAPI";
import {appUserStore} from "stores/appUser";
import {alertError, alertPromise} from "components/toast/appAlert";
import {useNavigate} from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {MdUploadFile} from "react-icons/md";
import adsImageWidthHeight from "utils/AdsImageWidthHeight";
import "./PostAds.css";
import useUrlQuery from "hooks/useUrlQuery";

const PRICES = {
  threeMonth: 8000,
  sixMonth: 15000,
  oneYear: 25000,
};

const PostAds = () => {
  const user = appUserStore((state) => state.user);
  const aid = useUrlQuery("aid");
  const [image, setImage] = useState("");
  const [position, setposition] = useState("");
  const [duration, setDuration] = useState("");
  const [oldImage, setOldImage] = useState(null);
  const [imageStatus, setImageStatus] = useState();
  const [billingAddress, setBillAddress] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function loadAdsInfo() {
      const {data} = await privateAPI.get(`/ads/public?aid=${aid}`);
      const adsInfo = data?.appData[0];
      setposition(adsInfo?.position);
      setDuration(adsInfo?.duration);
      setOldImage(adsInfo?.images);
    }
    if (aid) loadAdsInfo();
    else {
      setposition("");
      setDuration("");
    }
  }, [aid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!imageStatus && !aid)
      return alertError("Invalid Image Width and Height !");

    const createAdsForm = new FormData();
    createAdsForm.append("image", image);
    createAdsForm.append("mid", user?.member_id);
    createAdsForm.append("uid", user?.id);
    createAdsForm.append("position", position);
    createAdsForm.append("duration", duration);
    createAdsForm.append("billingAddress", billingAddress);
    createAdsForm.append(
      "price",
      duration === "3 Months"
        ? PRICES.threeMonth
        : duration === "6 Months"
        ? PRICES.sixMonth
        : duration === "1 Year" && PRICES.oneYear
    );

    let res = null;

    if (aid) {
      createAdsForm.append("oldImage", oldImage);
      res = privateAPI.put(`/ads-update/${aid}`, createAdsForm);
    } else {
      res = privateAPI.post("/ads", createAdsForm);
    }
    await alertPromise(res);
    navigate("/dashboard/my-ads");
  };

  const verifyImage = (e) => {
    const adsImage = new Image();
    let imgSrc = URL.createObjectURL(e.target.files[0]);
    adsImage.src = imgSrc;
    adsImage.onload = () => {
      setImageStatus(adsImageWidthHeight(adsImage, position));
      setImage(e.target.files[0]);
    };
  };

  return (
    <div className="join_member addform  rounded-2 ">
      <Breadcrumbs current="Advertisement">
        {aid ? "Update Advertisement" : "Post Advertisement"}{" "}
      </Breadcrumbs>
      <div className="row">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="bg-white rounded pb-4 px-2 px-lg-3 mt-3 mt-md-0">
                {!aid && (
                  <>
                    <label className="app_label">Billing Address</label>
                    <input
                      type="text"
                      className="app_input"
                      name="name"
                      onChange={(e) => setBillAddress(e.target.value)}
                    />
                  </>
                )}

                <label className="app_label">Select Position</label>
                <select
                  name="Select Position"
                  className="app_input"
                  value={position || ""}
                  onChange={(e) => setposition(e.target.value)}
                  required={aid ? false : true}
                >
                  <option value="">Select Position</option>
                  <option value="Dashboard Popup">Dashboard Popup</option>
                  <option value="Dashboard Fixed">Dashboard Fixed</option>
                  <option value="Login Page">Login Page</option>
                  <option value="Web Page Popup">Web Page Popup</option>
                </select>
                <label className="app_label">Select Duration</label>
                <select
                  name="Select Position"
                  required={aid ? false : true}
                  className="app_input"
                  value={duration || ""}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option value="">Select Duration</option>
                  <option value="3 Months">3 Month</option>
                  <option value="6 Months">6 Month</option>
                  <option value="1 Year">1 Year</option>
                </select>
                <label className="app_label">Amount </label>
                <select className="app_input" disabled>
                  {duration === "3 Months" ? (
                    <option>{PRICES.threeMonth}</option>
                  ) : duration === "6 Months" ? (
                    <option>{PRICES.sixMonth}</option>
                  ) : duration === "1 Year" ? (
                    <option>{PRICES.oneYear}</option>
                  ) : (
                    <option value=""></option>
                  )}
                </select>

                <label className="app_label">Image Size </label>
                <select className="app_input" disabled>
                  {position === "Dashboard Popup" ? (
                    <option>400 x 500</option>
                  ) : position === "Dashboard Fixed" ? (
                    <option>588 x 508</option>
                  ) : position === "Login Page" ? (
                    <option>400 x 650</option>
                  ) : position === "Web Page Popup" ? (
                    <option>400 x 500</option>
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center ">
              <div className="bg-white  rounded  pb-4 px-1 px-lg-3 h-100 down">
                <label className="filebutton">
                  <MdUploadFile />
                  <small className="d-block text-secondary ">
                    Drag and Drop or browse to Choose a Advertisement Picture
                  </small>
                  <span>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      name="photo"
                      onChange={verifyImage}
                      required={aid ? false : true}
                    />
                  </span>
                </label>
                <button
                  type="submit"
                  className="app_btn primary rounded px-5 mt-3"
                >
                  {aid ? "Update Ads" : "Post Ads"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostAds;
