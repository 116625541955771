import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import checkDateDiff from "utils/checkDateDiff";
import Loading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import CardPagination from "components/CardPagination/CardPagination";
import Table from "react-bootstrap/Table";
import "./AdminViewMill.css";

const AdminViewMill = () => {
  const {data, loading, error} = useFetch("/productions");
  useInternalServer(error);
  if (loading) return <Loading />;

  return (
    <div className="adminviewmill">
      <Breadcrumbs current="Admin View Mill">Admin View Mill</Breadcrumbs>
      <div className="row">
        <CardPagination items={data?.appData} itemsPerPage={8}>
          <Events />
        </CardPagination>
      </div>
    </div>
  );
};

const Events = (props) => {
  return (
    <>
      {props?.currentItems?.map((mill, i) => (
        <div className="col-sm-12 col-lg-6 move" key={i}>
          <div className="tbl_scrool bg-white p-3 rounded-2">
            <Table bordered className="m-0">
              <thead>
                <tr>
                  <th colSpan={4} className="heading text-center">
                    {mill?.name}
                  </th>
                </tr>
                <tr>
                  <td className="fw-bold">Last Updated On</td>
                  <td>
                    {
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      ).formatDate?.split(" ")[0]
                    }
                  </td>
                  <td>
                    {checkDateDiff(
                      mill?.details[mill?.details.length - 1]?.date
                    )
                      .formatDate?.split(" ")[1]
                      .split(":")[0] +
                      ":" +
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      )
                        .formatDate?.split(" ")[1]
                        .split(":")[1] +
                      " " +
                      checkDateDiff(
                        mill?.details[mill?.details.length - 1]?.date
                      ).formatDate?.split(" ")[2]}
                  </td>
                </tr>
                <tr>
                  <th>BF</th>
                  <th>COL</th>
                  <th>Production Date and Time</th>
                </tr>
              </thead>
              <tbody>
                {mill?.details?.map((detail, i) => (
                  <tr key={i}>
                    <td>{detail?.bf}</td>
                    <td>{detail?.col}</td>
                    <td>{checkDateDiff(detail?.date).formatDate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ))}
    </>
  );
};

export default AdminViewMill;
