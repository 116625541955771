import React, {useState} from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";

const SMSCamapign = () => {
  const [initiatives, setInitiatives] = useState("");
  const [desc, setDesc] = useState("");

  const handelSubmit = async (e) => {
    e.preventDefault();
    const result = privateAPI.post("/campaign/sms", {initiatives, desc});
    await alertPromise(result);
  };

  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  return (
    <div className="emailcampaign">
      <Breadcrumbs current="SMS Campaign">SMS Campaign</Breadcrumbs>
      <div className="row">
        <div className="col-12 col-lg-6">
          <form onSubmit={handelSubmit}>
            <div className="bg-white rounded pb-4 px-2 px-lg-3 mt-3 mt-md-0">
              <label className="app_label">Select Initiatives</label>
              <select
                name="Select Position"
                className="app_input"
                required
                onChange={(e) => setInitiatives(e.target.value)}
              >
                <option value="">Select Initiatives</option>
                <option value="ALL">All Users</option>
                <option value="MEMBER">Members</option>
                <option value="GUEST">Guests</option>
                <option value="COMMITTEE">Committee</option>
              </select>

              <label className="app_label">Description</label>
              <textarea
                type="text"
                className="app_input"
                required
                onChange={(e) => setDesc(e.target.value)}
              />
              <button
                type="submit"
                className="app_btn primary rounded px-5 mt-3"
              >
                Send
              </button>
            </div>
          </form>
        </div>

        <div className="col-12 col-md-12 col-lg-6">
          <div className="ads h-100">
            <AdsSlider data={adsInfo?.data?.appData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSCamapign;
