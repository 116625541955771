export const chartOne = (chartData) => {
  return {
    labels: chartData?.map((data) => data.year),
    datasets: [
      {
        label: "Active Member and Inactive Member",
        data: chartData?.map((data) => data.userGain),
        backgroundColor: ["#10c469", "#ff5b5b"],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };
};
