import React from "react";
import img0 from "assets/img/SPSS2636.JPG";
import img1 from "assets/img/SPSS2650.JPG";
import img2 from "assets/img/SPSS2654.JPG";
import img3 from "assets/img/SPSS2657.JPG";
import img4 from "assets/img/SPSS2666.JPG";
import img5 from "assets/img/SPSS2703.JPG";
import img6 from "assets/img/SPSS2716.JPG";
import img7 from "assets/img/SPSS2752.JPG";
import img8 from "assets/img/SPSS2863.JPG";
import img9 from "assets/img/SPSS2869.JPG";
import img10 from "assets/img/SPSS2873.JPG";
import img11 from "assets/img/SPSS2874.JPG";
import img12 from "assets/img/SPSS2890.JPG";
import img13 from "assets/img/SPSS2892.JPG";
import img14 from "assets/img/SPSS2902.JPG";
import img15 from "assets/img/SPSS2907.JPG";
import img16 from "assets/img/SPSS2909.JPG";
import img17 from "assets/img/SPSS2911.JPG";
import img18 from "assets/img/SPSS2917.JPG";
import useView from "hooks/useView";
import Header from "components/main/header/Header";
import "./gallarypage.css";

const GallaryPage = () => {
  const view = useView();
  return (
    <>
      <Header title="Our Gallery" />
      <div className={`gallary ${view}`}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img0} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img1} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img2} alt="Third slide" />
              </div>
            </div>
          </div>
          {/* row 2 */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img3} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img4} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img5} alt="Third slide" />
              </div>
            </div>
          </div>

          {/* row 3 */}

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img6} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img7} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img8} alt="Third slide" />
              </div>
            </div>
          </div>
          {/* row 4 */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img9} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img10} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img11} alt="Third slide" />
              </div>
            </div>
          </div>
          {/* row 5 */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img12} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img13} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img14} alt="Third slide" />
              </div>
            </div>
          </div>
          {/* row 6 */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img15} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img16} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img17} alt="Third slide" />
              </div>
            </div>
          </div>
          {/* row 7 */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img18} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img10} alt="Third slide" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <img className="d-block w-100" src={img11} alt="Third slide" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GallaryPage;
