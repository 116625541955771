import React from "react";
import Header from "components/main/header/Header";
import {IoArrowRedoSharp} from "react-icons/io5";
import {NavLink} from "react-router-dom";
import img from "assets/img/member_bg.png";
import "./memberpage.css";
import useView from "hooks/useView";

const MembersPage = () => {
  const view = useView();
  return (
    <div>
      <Header title="MEMBERSHIP" />
      <div className={`members ${view}`}>
        <div className="container">
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="abt_mem shadow">
                <h3>MEMBERS</h3>
                <p>
                  Join us to make an excellent team to exchange ideas, views,
                  and perspectives with other corrugators for the development of
                  the industry. You will receive our meeting invitations to get
                  connected to the network and improve the knowledge quality by
                  your valuable participation.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="benefits shadow">
                <h3>MEMBERSHIP BENEFITS</h3>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    Grants rights to the members to acquire the free membership
                    of SICBMA
                  </p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    Allows the association members to pass industry-related
                    queries related to taxation and other technical terms
                  </p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    Provides special permissions to publications i.e,
                    Corrugator, Magazines, journals, and newspapers regarding
                    the variations from time to time
                  </p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    Facilitates the members to claim complete Paper testing
                    facilities, board, and boxes with discounts from the R & D
                    Centre of the Association
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* r2 */}
          <div className="row mt-lg-3">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="eligibility shadow">
                <h3>ELIGIBILITY TO BECOME A MEMBER</h3>
                <div className="d-flex red">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    The person can be a member of any manufacturing
                    organization. The eligibility applies to a person with or
                    without a corrugation machine who shall apply for the
                    admission
                  </p>
                </div>
                <div className="d-flex red">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    The Governing Body shall decide any question concerning a
                    candidate's eligibility or otherwise for admission as a
                    member, and their decision shall be final.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="enrollment shadow">
                <h3>ENROLLMENT & APPROVAL PROCESS</h3>
                <p>
                  The candidate once after getting enrolled must wait for legal
                  approval. The process involves the validation of the details,
                  and finally, the membership is allocated.
                </p>
              </div>
            </div>
          </div>
          {/* r3 */}
          <div className="row mt-lg-3">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="fee shadow">
                <h3>MEMBERSHIP FEES</h3>
                <h6>Every member shall pay the following subscription </h6>
                <p>
                  An <span>Admission fee</span> of Rs.
                  <span>500 only.</span>
                </p>
                <p>
                  An <span>Annual fee</span> of Rs.
                  <span>3540 only.(Outstation)</span>
                </p>
                <p>
                  An <span>Admission fee</span> of Rs.<span>500 only.</span>
                </p>
                <p>
                  An <span>Annual fee</span> of Rs.
                  <span>4720 only.( Chennai)</span>
                </p>
                <h6> * GST applicable @ 18%</h6>
                <p>
                  (Including Rs./- for enabling "View Details" In Web Membership
                  Directory)
                </p>
                <div className="button">
                  <NavLink
                    to="/user/auth/signup"
                    className="text-decoration-none"
                  >
                    Click to Become a Member
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="text-center mt-3 pic">
                <img src={img} alt="#" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersPage;
