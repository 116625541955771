import privateAPI from "api/privateAPI";
import { alertPromise } from "components/toast/appAlert";
import create from "zustand";

export const usePictureUpdate = create((set) => ({
  oldImage: null,
  newImage: null,
  uid: null,
  payloadFormData: null,
  showPictureModal: false,
  showPdfModal: false,
  setShowPictureModal: (value) => set({ showPictureModal: value }),
  setShowPdfModal: (value) => set({ showPdfModal: value }),
  updatePayload: (ni) =>
    set((state) => {
      const payloadForm = new FormData();
      payloadForm.append("oldImage", state.oldImage);
      payloadForm.append("newImage", ni);
      payloadForm.append("uid", state.uid);
      return { payloadFormData: payloadForm };
    }),
  openShowPictureModal: (oldImage, uid, pdf = false) =>
    set(() => {
      if (pdf) {
        return { oldImage, uid, showPdfModal: true };
      } else {
        return { oldImage, uid, showPictureModal: true };
      }
    }),
  updateEventPicture: (e, api) =>
    set(async (state) => {
      e.preventDefault();
      const res = privateAPI.put(api, state.payloadFormData);
      console.log(state.showPictureModal);
      await alertPromise(res);
      state.setShowPictureModal(false);
      state.setShowPdfModal(false);
      return { showPictureModal: false, showPdfModal: false };
    }),
}));
