import useFetch from "hooks/useFetch";
import React, {useRef} from "react";
import {useParams} from "react-router";
import useInternalServer from "hooks/useInternalServer";
import Loading from "components/loading/Loading";
import Table from "react-bootstrap/Table";
import "./InvoiceReport.css";
import checkDateDiff from "utils/checkDateDiff";
import {useReactToPrint} from "react-to-print";

const InvoiceReports = () => {
  const componentRef = useRef();
  const {transId} = useParams();
  const {data, loading, error} = useFetch(`/invoice/${transId}`);
  const invoiceData = data?.appData?.[0];
  useInternalServer(error);
  console.log(data);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (loading) return <Loading />;

  return (
    <>
      <button className="app_btn success" onClick={handlePrint}>
        Print
      </button>
      <div className="invoice bg-white rounded mt-3" ref={componentRef}>
        <div className="row">
          <div className="col-6">
            <div className="logo">
              <h3>SCIBMA</h3>
            </div>
          </div>
          <div className="col-6">
            <div className="head">
              <h2 className="text-end">INVOICE</h2>
            </div>
          </div>
        </div>
        <hr />

        {/* row 3 */}
        <div className="row">
          <div className="col-1"></div>
          <div className="col-2">
            <div className="detail">
              <h6>Invoice No</h6>
              <p>{transId}</p>
            </div>
          </div>
          <div className="col-2">
            <div className="detail">
              <h6>Invoice Date</h6>
              <p>
                {
                  checkDateDiff(invoiceData?.payment_date).formatDate.split(
                    " "
                  )[0]
                }
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="detail">
              <h6>Due Date</h6>
              <p>
                {
                  checkDateDiff(invoiceData?.payment_date).formatDate.split(
                    " "
                  )[0]
                }
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="detail">
              <h6>Payment Status</h6>
              <p>PAID</p>
            </div>
          </div>
          <div className="col-2">
            <div className="detail">
              <h6>Amount</h6>
              <p>{invoiceData?.amount}</p>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
        {/* <div className="row mt-3">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="billto">
              <h5>Invoice To</h5>
              <h4>
                {invoiceData?.user_name
                  ? invoiceData?.user_name
                  : invoiceData?.companyName}
              </h4>
              <h6>{invoiceData?.company_name}</h6>
              <p className="phone">
                <GiRotaryPhone className="me-1" />
                {invoiceData?.user_phone}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="invoicenum">
              <div className="detail text-end">
                <h6>Invoice No</h6>
                <p>01</p>

                <h6>Invoice Date</h6>
                <p>01/12/2022</p>
              </div>
            </div>
          </div>
        </div> */}

        {/* bill tooo */}

        <div className="row mt-5">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="billto">
              <h5>Bill To</h5>
              <p>SICBMA</p>
              <p>5/502,kambar street, Anna nagar,Madurai-655 020</p>
              <p>ramdhanush972003@gmail.com</p>
              <p className="phone">
                Phone:<span className="ms-1">908756635</span>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="shipto">
              <h5>Ship To</h5>
              <p>{invoiceData?.company_name}</p>
              <p>
                {invoiceData?.company_address}
                <br />
                {invoiceData?.company_city}
                <br />
                {invoiceData?.company_state}
              </p>
              <p>{invoiceData?.email}</p>
              <p className="phone">
                Phone:<span className="ms-1">{invoiceData?.phone}</span>
              </p>
            </div>
          </div>
        </div>

        {/* row 4 */}
        <div className="total mt-4">
          <Table bordered responsive>
            <thead>
              {invoiceData?.reason === "MEMBER" ? (
                <tr>
                  <th>Description</th>
                  <th>Payment Date</th>
                  <th>Expire Date</th>
                  <th>Amount</th>
                </tr>
              ) : invoiceData?.reason === "EVENT" ? (
                <tr>
                  <th>Description</th>
                  <th>Event Name</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                </tr>
              ) : invoiceData?.reason === "ADVERTISEMENT" ? (
                <tr>
                  <th>Description</th>
                  <th>AD Id</th>
                  <th>AD Position</th>
                  <th>AD Duration</th>
                  <th>Payment Date</th>
                  <th>Expire Date</th>
                  <th>Amount</th>
                </tr>
              ) : (
                <tr>
                  <th>Company Name</th>
                  <th>Description</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                </tr>
              )}
            </thead>
            <tbody>
              {invoiceData?.reason === "MEMBER" ? (
                <tr>
                  <td>{invoiceData?.reason}</td>
                  <td>{invoiceData?.payment_date}</td>
                  <td>1 Year</td>
                  <td>{invoiceData?.amount}</td>
                </tr>
              ) : invoiceData?.reason === "EVENT" ? (
                <tr>
                  <td>{invoiceData?.reason}</td>
                  <td>{invoiceData?.event_name}</td>
                  <td>{checkDateDiff(invoiceData?.payment_date).formatDate}</td>
                  <td>{invoiceData?.amount}</td>
                </tr>
              ) : invoiceData?.reason === "ADVERTISEMENT" ? (
                <tr>
                  <td>{invoiceData?.reason}</td>
                  <td>{invoiceData?.ad_id}</td>
                  <td>{invoiceData?.position}</td>
                  <td>{invoiceData?.duration}</td>
                  <td>{checkDateDiff(invoiceData?.payment_date).formatDate}</td>
                  <td>
                    {checkDateDiff(invoiceData?.ads_expire_date).formatDate}
                  </td>
                  <td>{invoiceData?.amount}</td>
                </tr>
              ) : (
                <tr>
                  <td>{invoiceData?.companyName}</td>
                  <td>{invoiceData?.reason}</td>
                  <td>{checkDateDiff(invoiceData?.payment_date).formatDate}</td>
                  <td>{invoiceData?.amount}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <hr />
        {/* row 5 */}
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <div className="amount text-end">
              <h5>Total Amount</h5>
              <h6>{invoiceData?.amount}</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceReports;
