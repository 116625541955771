import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import {useInRouterContext} from "react-router";
import Tooltip from "components/Tooltip/Tooltip";
import {AiFillEye} from "react-icons/ai";
import AppModal from "components/modals/AppModal";
import ViewPoModal from "components/modals/ViewPoModal";
import {useViewData} from "stores/useViewData";
import checkDateDiff from "utils/checkDateDiff";
import {NavLink} from "react-router-dom";

const ViewPO = () => {
  const {setViewData, viewModal, setViewModal} = useViewData();

  const {data, loading, error} = useFetch("/po");
  useInRouterContext(error);
  const columns = [
    {
      text: "PO No",
      sort: true,
      dataField: "po_no",
      formatter: (col, row) => {
        return <NavLink to={`/dashboard/po-invoice/${col}`}>#{col}</NavLink>;
      },
    },
    {
      text: "Item Description",
      dataField: "item_desc",
    },
    {
      text: "Quantity",
      dataField: "quantity",
    },
    {
      text: "Unit Price",
      dataField: "unit_price",
    },

    {
      text: "Total Amount",
      sort: true,
      dataField: "amount",
    },
    {
      text: "PO Date",
      dataField: "po_date",
      formatter: (col, row) => checkDateDiff(row.po_date).formatDate,
      sort: true,
    },
    {
      text: "Actions",
      dataField: "visible",
      formatter: (col, row) => (
        <div className="btn-group text-white" role="group">
          <Tooltip title="View Member">
            <span
              className="vm_btn btn success text-white"
              onClick={() => setViewData(row)}
            >
              <AiFillEye />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  if (loading) return <DashLoading />;

  return (
    <div>
      <Breadcrumbs current="View PO">View Purchase Order</Breadcrumbs>
      <AppModal
        show={viewModal}
        changeShow={setViewModal}
        title="Purchase Order"
      >
        <ViewPoModal />
      </AppModal>
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
              pagination={pagination(data?.appData?.length)}
              sort={{sortCaret}}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewPO;
