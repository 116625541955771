import React from "react";
import "./contact.css";
import {FaAddressCard} from "react-icons/fa";
import {HiMail} from "react-icons/hi";
import {BsTelephoneFill} from "react-icons/bs";
import {Link, NavLink} from "react-router-dom";
import {FaGlobeAfrica} from "react-icons/fa";
import {MdTouchApp} from "react-icons/md";
import useView from "hooks/useView";

const Contact = () => {
  const view = useView();
  return (
    <div className={`contact ${view}`}>
      <div className="container">
        <h3 className="mb-5">
          <span>Contact</span> us
        </h3>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.841699291148!2d80.15623107494592!3d13.109212987218934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5263bb9d61a83f%3A0x96f0c5b88a6e1b03!2s39%2F324%2C%20Sidco%20Industrial%20Estate%2C%20Ambattur%2C%20Chennai%2C%20Tamil%20Nadu%20600053!5e0!3m2!1sen!2sin!4v1673249741333!5m2!1sen!2sin"
            title="map"
          />
        </div>
        <div className="row mt-5 align-items-center bg-white shadow rounded-4 py-3 py-md-5 px-2 px-md-4">
          <div className="col-sm-12 col-md-12 col-lg-8 ">
            <div className="form">
              <h3>Need Any Help ?</h3>
              <form>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Name"
                  />
                  <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Name"
                  />
                  <label htmlFor="floatingPassword">Email</label>
                </div>
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Name"
                  />
                  <label htmlFor="floatingPassword">Mobile Number</label>
                </div>
                <div className="form-floating">
                  <textarea
                    type="text"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Message"
                  />
                  <label htmlFor="floatingPassword">Message</label>
                </div>
              </form>
              <NavLink to="/" className="btn btn-outline-success">
                Contact Now
              </NavLink>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 ">
            <div className="content">
              <h4 className="my-4 my-lg-0 py-4 py-lg-0">
                <MdTouchApp className="me-3" />
                Get in Touch
              </h4>

              <div className="d-flex mt-3 mt-lg-5">
                <FaAddressCard className="me-4" />
                <p className=" ">
                  No.39, SIDCO Industrials,
                  <br />
                  Estate Ambattur
                  <br />
                  Chennai-600 098. INDIA
                </p>
              </div>
              <Link to="/about">
                <HiMail className="me-4" />
                 sicbma@gmail.com
              </Link>
              <br />
              <br />
              <Link to="/about" className="mt-5">
                <BsTelephoneFill className="me-4 " />
                +91 44 43023020
              </Link>
              <br />
              <br />
              <Link to="/about" className="mt-5">
                <FaGlobeAfrica className="me-4 " />
                www.scibma.com
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
