/* eslint-disable no-unused-vars */
import React from "react";
import { Modal } from "react-bootstrap";
import AdsSlider from "./AdsSlider";
import { MdClose } from "react-icons/md";
import useFetch from "hooks/useFetch";
import useInternalServer from "hooks/useInternalServer";

const MainPageAds = (props) => {
  const { data, error } = useFetch("/ads/public?position=Web Page Popup");
  useInternalServer(error);

  return (
    <Modal show={props.show} centered backdrop="static">
      <div
        className="close_modal_btn cursor-pointer"
        onClick={() => props.changeShow(false)}
      >
        <MdClose className="d-flex" />
      </div>
      <AdsSlider data={data?.appData} />
    </Modal>
  );
};

export default MainPageAds;
