import React from "react";
import DashLoading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import "./ViewGuests.css";
import { SERVER_PUBLIC } from "config/app-config";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import AppModal from "components/modals/AppModal";
import MemberDelete from "components/modals/MemberDelete";
import { useDeleteCancelUser } from "stores/useDeleteCancelUser";
import { AiFillEye } from "react-icons/ai";
import img from "assets/img/profile.jpg";
import sortCaret from "../sortCaret/sortCaret";
import TableSearch from "../TableSearch/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "utils/pagination";
import Tooltip from "components/Tooltip/Tooltip";
import { useViewData } from "stores/useViewData";
import GuestView from "components/modals/GuestView";

const ViewGuests = () => {
  const { data, loading, error } = useFetch("/guests");
  useInternalServer(error);
  const navigate = useNavigate();
  const { deleteConfirmModal, setDeleteConfirmModal, handleClick, handleDelete } = useDeleteCancelUser();
  const { setViewData, viewModal, setViewModal } = useViewData();

  if (loading) return <DashLoading />;

  const handleMakeMember = (phone) => {
    localStorage.setItem("member-phone", phone);
    navigate("/dashboard/join-member");
  };

  const columns = [
    {
      text: "Profile",
      dataField: "profile_img",
      formatter: (col, row) => (
        <div className="profileImgTable">
          <img src={row.profile_img ? `${SERVER_PUBLIC}/profile_pictures/${row.profile_img}` : img} alt="" className="img-fluid" />
          <a href={row.profile_img ? `${SERVER_PUBLIC}/profile_pictures/${row.profile_img}` : img} target="_blank" rel="noreferrer">
            view
          </a>
        </div>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Industry",
      dataField: "category_name",
      sort: true,
    },
    {
      text: "Mobile",
      dataField: "phone",
      sort: true,
    },
    {
      text: "Actions",
      dataField: "visible",
      formatter: (col, row) => (
        <div className="btn-group" role="group">
          <Tooltip title="View Guest">
            <span className="vm_btn btn success text-white" onClick={() => setViewData(row)}>
              <AiFillEye />
            </span>
          </Tooltip>
          <Tooltip title="Delete ">
            <span
              className="vm_btn btn danger text-white"
              onClick={() => {
                handleClick(row.id, "delete");
              }}
            >
              <RiDeleteBinLine />
            </span>
          </Tooltip>
          {row?.member_id ? (
            <NavLink
              to="/dashboard/subscription"
              className="vm_btn btn success text-white"
              onClick={() => {
                localStorage.setItem("member-id", row?.member_id);
              }}
            >
              Make Payment
            </NavLink>
          ) : (
            <span onClick={() => handleMakeMember(row?.phone)} className="vm_btn btn bg-warning text-white">
              Make as Member
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="viewguest">
      <Breadcrumbs current="View Guest">View Guest</Breadcrumbs>
      <AppModal show={deleteConfirmModal} changeShow={setDeleteConfirmModal} title="Delete Confirmation">
        <MemberDelete title="Do you want Delete Guest !" setShow={setDeleteConfirmModal} handleDelete={() => handleDelete("delete-member")} />
      </AppModal>
      <AppModal show={viewModal} changeShow={setViewModal} title="Guest Details">
        <GuestView />
      </AppModal>
      <ToolkitProvider keyField="id" data={data?.appData ? data?.appData : []} columns={columns} search>
        {(props) => (
          <>
            <TableSearch {...props.searchProps} />
            <BootstrapTable {...props.baseProps} wrapperClasses="table-responsive bg-white shadow-sm" pagination={pagination(data?.appData?.length)} sort={{ sortCaret }} />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ViewGuests;

// const columns = [
//   {
//     name: "Profile",
//     selector: (row) => (
//       <div className="imgbx">
//         <img
//           src={
//             row.profile_img
//               ? `${SERVER_PUBLIC}/users/${row.profile_img}`
//               : img
//           }
//           alt=""
//           className="img-fluid w-25"
//         />
//         <a
//           href={
//             row.profile_img
//               ? `${SERVER_PUBLIC}/users/${row.profile_img}`
//               : img
//           }
//           target="_blank"
//           rel="noreferrer"
//         >
//           view
//         </a>
//       </div>
//     ),
//   },
//   {
//     name: "Name",
//     selector: (row) => toFirstCapital(row.name),
//     sortable: true,
//   },
//   {
//     name: "Industry",
//     selector: (row) => row.category_name,
//     sortable: true,
//     hide: Media.MD,
//   },
//   {
//     name: "Mobile",
//     selector: (row) => row.phone,
//     sortable: true,
//   },
//   {
//     name: "Actions",
//     selector: (row) => (
//       <div>
//         <span className="vm_btn text-primary me-3 cursor-pointer">
//           <AiFillEye
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="View"
//           />
//         </span>
//         <span
//           className="vm_btn text-danger cursor-pointer"
//           onClick={() => {
//             handleClick(row.id, "delete");
//           }}
//         >
//           <RiDeleteBinLine
//             data-toggle="tooltip"
//             data-placement="bottom"
//             title="Delete"
//           />
//         </span>
//         {row?.member_id ? (
//           <NavLink
//             to="/dashboard/subscription"
//             className="ms-3 app_btn sm success cursor-pointer"
//           >
//             Make Payment
//           </NavLink>
//         ) : (
//           <span
//             onClick={() => handleMakeMember(row?.phone)}
//             className="ms-3 app_btn sm success cursor-pointer"
//           >
//             Make as Member
//           </span>
//         )}
//       </div>
//     ),
//   },
// ];
