import React from "react";
import Table from "react-bootstrap/Table";
import "./invoice.css";
import {BsFillTelephoneFill} from "react-icons/bs";
import {AiFillMail} from "react-icons/ai";
import {FaGlobe} from "react-icons/fa";
import {ImLocation} from "react-icons/im";

const Invoice = () => {
  return (
    <div className="invoice bg-white rounded">
      <div className="row">
        <div className="col-6">
          <div className="logo">
            <h3>SCIBMA</h3>
          </div>
        </div>
        <div className="col-6">
          <div className="information ">
            <a href="/">
              <BsFillTelephoneFill /> +918767857097
            </a>
            <br />
            <a href="/">
              <AiFillMail /> scibma@gmail.com
            </a>
            <br />
            <a href="/">
              <FaGlobe />
              Scibma.com
            </a>
            <br />
            <a href="/">
              <ImLocation /> Madurai
            </a>
          </div>
        </div>
      </div>
      <hr />
      {/* row 2 */}
      <div className="row">
        <div className="col-1"></div>
        <div className="col-2">
          <div className="detail">
            <h6>Invoice No</h6>
            <p>01</p>
          </div>
        </div>
        <div className="col-2">
          <div className="detail">
            <h6>Invoice Date</h6>
            <p>01/09/2022</p>
          </div>
        </div>
        <div className="col-2">
          <div className="detail">
            <h6>Due Date</h6>
            <p>04/12/2022</p>
          </div>
        </div>
        <div className="col-2">
          <div className="detail">
            <h6>Payment Status</h6>
            <p>Unpaid</p>
          </div>
        </div>
        <div className="col-2">
          <div className="detail">
            <h6>Amount</h6>
            <p>17500</p>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
      {/* row 3 */}
      <div className="row mt-5">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <div className="billto">
            <h5>Bill To</h5>
            <p>ABIRAMI PAPERS</p>
            <p>5/502,kambar street, Anna nagar,Madurai-655 020</p>
            <p>ramdhanush972003@gmail.com</p>
            <p className="phone">
              Phone:<span className="ms-1">908756635</span>
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <div className="shipto">
            <h5>Ship To</h5>
            <p>ABIRAMI PAPERS</p>
            <p>5/502,kambar street, Anna nagar,Madurai-655 020</p>
            <p>ramdhanush972003@gmail.com</p>
            <p className="phone">
              Phone:<span className="ms-1">908756635</span>
            </p>
          </div>
        </div>
      </div>
      {/* row 4 */}
      <div className="total">
        <Table bordered responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Detail</th>
              <th>Rate</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Test</td>
              <td>4,000</td>
              <td>6</td>
              <td>24,000</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Invoice;
