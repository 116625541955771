import React from "react";
import img1 from "assets/img/classified_bg.png";
import "./Classified.css";
import useView from "hooks/useView";

const Classified = () => {
  const view = useView();
  return (
    <div className={`classi ${view}`}>
      <div className="container">
        <h3 className="mb-5">
          Our <span>Classifieds</span>
        </h3>
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="text">
                <p>
                  Our organization introduces a user-friendly
                  <span>"Classified Page"</span>
                  to facilitate all the members sharing their products, and
                  services to form a common network. The major advantage of this
                  portal is not only the existing/ fresh members can post their
                  classifieds but also a common user/visitor of the website can
                  share some business-related advertisements.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="position-relative come">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <h4>Login Page</h4>
                <p>400 x 650</p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="position-relative come">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <h4>Web Page</h4>
                <p>400 x 500</p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="position-relative come">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <h4>Dashboard</h4>
                <p>400 x 500</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classified;
