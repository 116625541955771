import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBVHuGD0qzFkByYdQLQkAYeK61qLjyoVRs",
  authDomain: "mms-application-7c163.firebaseapp.com",
  projectId: "mms-application-7c163",
  storageBucket: "mms-application-7c163.appspot.com",
  messagingSenderId: "162723675020",
  appId: "1:162723675020:web:36cec238d9992f9323f4a9",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();

export { auth, firebase };
