import create from "zustand";

export const useViewData = create((set) => ({
  viewModal: false,
  viewData: null,
  setViewModal: (value = false) => set({ viewModal: value }),
  setViewData: (data) =>
    set((state) => {
      state.viewModal = true;
      return { viewData: data };
    }),
}));
