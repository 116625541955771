import React from "react";
import Header from "components/main/header/Header";
import "./ClassifiedPage.css";
import { IoArrowRedoSharp } from "react-icons/io5";
import { FaEthereum } from "react-icons/fa";
import img1 from "assets/img/classified_bg.png";
import useView from "hooks/useView";

const ClassifiedPage = () => {
  const view = useView();
  return (
    <>
      <Header title="CLASSIFIEDS" />
      <div className={`classified ${view}`}>
        <div className="container">
          <div className="row mt-lg-3">
            <div className="col-12 col-lg-6">
              <div className="content shadow p-4 rounded">
                <h3 className="mt-3">CLASSIFIED</h3>
                <p>
                  Our organization introduces a user-friendly
                  <span> "Classified Page" </span>
                  to facilitate all the members sharing their products, and
                  services to form a common network. The major advantage of this
                  portal is not only the existing/ fresh members can post their
                  classifieds but also a common user/visitor of the website can
                  share some business-related advertisements.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="picture">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <div className="inside">
                  <h5>Login Page</h5>
                  <h6>400 X 600</h6>
                </div>
              </div>
            </div>
          </div>
          {/* row 2 */}

          <div className="row mt-lg-3">
            <div className="col-12 col-lg-6">
              <div className="picture">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <div className="inside">
                  <h5>Dashboard</h5>
                  <h6>400 X 600</h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="benefits shadow p-4 rounded">
                <h3>
                  WHAT ARE THE BENEFITS OF POSTING CLASSIFIED ON THIS PAGE?
                </h3>
                <p>
                  If you are a person running a business and need a popular
                  network to exhibit your products/services, then this
                  classified portal can be the right solution. You can showcase
                  your products, represent their market trends, and choose
                  potential customers based on their requirements. The business
                  may be of any type, a manufacturing unit, IT Software, or
                  healthcare, but the portal can help you. Advertise your
                  business derivatives on our platform to attract a great number
                  of customers.
                </p>
              </div>
            </div>
          </div>

          {/* row 3 */}

          <div className="row mt-lg-3">
            <div className="col-12 col-lg-6">
              <div className="offers shadow p-4 rounded">
                <h3>ATTRACTIVE OFFERS TO ALL THE MEMBERS</h3>
                <p>
                  If you are an active member of the corrugated box industry,
                  then you can avail of some of the special discounts to post
                  your classifieds on the page. You can exhibit your products to
                  a wide range of audiences.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="picture pic3">
                <img className="d-block w-100" src={img1} alt="First slide" />
                <div className="inside">
                  <h5>Web Page</h5>
                  <h6>400 X 600</h6>
                </div>
              </div>
            </div>
          </div>
          {/* row 4 */}

          <div className="row mt-lg-3">
            <div className="col-12 col-lg-6">
              <div className="rules shadow p-4 rounded">
                <h3>RULES & REGULATIONS FOR POSTING CLASSIFIEDS</h3>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    Listings irrelevant to business and genuine products are
                    restricted.
                  </p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>
                    The classifieds posted by a user will be moved to the
                    approval phase. The admin after analyzing the advertisement
                    will grant the approval. (Less than 48 hours)
                  </p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <IoArrowRedoSharp />
                  </span>
                  <p>Phony images/ content are strictly prohibited.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="payment shadow p-4 rounded">
                <h3>PAYMENT OPTIONS</h3>
                <p>
                  There are different categories of payments depending upon the
                  type of webpage used for publishing the advertisement. The
                  user can actively post their classified in engaging areas like
                </p>
                <div className="d-flex icon">
                  <span>
                    <FaEthereum />
                  </span>
                  <p>Home Page</p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <FaEthereum />
                  </span>
                  <p>Login Page</p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <FaEthereum />
                  </span>
                  <p>Pop-up notifications</p>
                </div>
                <div className="d-flex icon">
                  <span>
                    <FaEthereum />
                  </span>
                  <p>Dashboard</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassifiedPage;
