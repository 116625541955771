import React, {useState, useEffect} from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {AiOutlineCloudUpload} from "react-icons/ai";
import privateAPI from "api/privateAPI";
import {alertPromise} from "components/toast/appAlert";
import useUrlQuery from "hooks/useUrlQuery";
import {useNavigate} from "react-router";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";

const CreateCommittee = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const cid = useUrlQuery("cid");
  const navigate = useNavigate();
  const [committee, setCommittee] = useState({
    name: "",
    posting: "",
    email: "",
    phone: "",
    address: "",
    city: "",
  });
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");

  useEffect(() => {
    async function loadCommittee() {
      const {data} = await privateAPI.get(`/committee?cid=${cid}`);
      const cinfo = data?.appData[0];
      setOldImage(cinfo.profile_image);
      setCommittee((state) => ({...state, ...cinfo}));
    }
    if (cid) loadCommittee();
    else {
      setCommittee({
        name: "",
        posting: "",
        email: "",
        phone: "",
        address: "",
        city: "",
      });
    }
  }, [cid]);

  const handleOnChange = (e) => {
    const {name, value} = e.target;
    setCommittee({
      ...committee,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const committeeData = new FormData();
    committeeData.append("image", image);
    committeeData.append("name", committee.name);
    committeeData.append("posting", committee.posting);
    committeeData.append("email", committee.email);
    committeeData.append("phone", committee.phone);
    committeeData.append("address", committee.address);
    committeeData.append("city", committee.city);

    let res = null;
    if (cid) {
      committeeData.append("oldImage", oldImage);
      res = privateAPI.put(`/committee/${cid}`, committeeData);
    } else res = privateAPI.post("/committee", committeeData);

    await alertPromise(res);
    navigate("/dashboard/view-committee");
  };

  return (
    <div className="createmember">
      <Breadcrumbs current="Create Committee Member">
        {cid ? "Update Committee Member" : "Create Committee Member"}
      </Breadcrumbs>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="content">
              <div className="uploadimg bg-white py-2 rounded">
                <label className="filebutton">
                  <AiOutlineCloudUpload />
                  <br />
                  <small className="app_label">
                    Upload Member Profile Photo
                  </small>
                  <span>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      name="imageFile"
                      required={cid ? false : true}
                      className="app_input"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </span>
                </label>
              </div>
              <div className="bg-white px-3 py-2 mt-2">
                <label className="app_label">Name</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="name"
                  value={committee.name || ""}
                  onChange={handleOnChange}
                />
                <label className="app_label">Position</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="posting"
                  value={committee.posting || ""}
                  onChange={handleOnChange}
                />
                <label className="app_label">E-mail</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="email"
                  value={committee.email || ""}
                  onChange={handleOnChange}
                />
                <label className="app_label">Mobile</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="phone"
                  value={committee.phone || ""}
                  onChange={handleOnChange}
                />
                <label className="app_label">Address</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="address"
                  value={committee.address || ""}
                  onChange={handleOnChange}
                />
                <label className="app_label">City</label>
                <input
                  type="text"
                  required={cid ? false : true}
                  className="app_input"
                  name="city"
                  value={committee.city || ""}
                  onChange={handleOnChange}
                />

                <div className="text-start py-2">
                  <button type="submit" className="app_btn primary">
                    Create Member
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="ads">
              <AdsSlider data={adsInfo?.data?.appData} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateCommittee;
