import React from "react";
import Box from "../Box/Box";
import {FaBoxes} from "react-icons/fa";
import {SiGoogleads} from "react-icons/si";
import {BsFillCalendarEventFill} from "react-icons/bs";
import {HiUserGroup} from "react-icons/hi";
import {HiSpeakerphone} from "react-icons/hi";
import {SiFiles} from "react-icons/si";
import BarChart from "../Chart/BarChart";
import {UserData} from "../Chart/TestData";
import {chartOne} from "./ChartConfig";
import {PolarArea} from "react-chartjs-2";
import AdsSlider from "components/adsModals/AdsSlider";
import useFetch from "hooks/useFetch";
import DashLoading from "components/loading/DashLoading";
import useInternalServer from "hooks/useInternalServer";
import MemberTable from "../MemberTable";
import AdsTable from "../AdsTable";
import EventTable from "../EventTable";

const AdminDash = () => {
  const adsInfo = useFetch("/ads/public?position=Dashboard Fixed");
  const {data, loading, error} = useFetch(`/dashboard/admin`);
  useInternalServer(error);
  const adminCount = data?.appData;
  console.log(adminCount);

  const chartOneData = chartOne(UserData);
  const polardata = {
    labels: ["Member", "Guest", "Advertiser"],
    datasets: [
      {
        label: "Total Count",
        data: [45, 78, 37],
        backgroundColor: ["#ff5b5b", "#ff8acc", "#f5c851"],
      },
    ],
  };

  if (loading) return <DashLoading />;
  return (
    <>
      <div className="guestdash">
        <div className="boxrow1">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Total Events"
                count={adminCount?.totalEvents?.data?.[0]?.total_events}
                body="Events"
                icon={<BsFillCalendarEventFill />}
                color="icon"
                to="/dashboard/view-events"
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Members"
                count={adminCount?.totalMembers?.data?.[0]?.total_members}
                body="Members"
                icon={<SiGoogleads />}
                color="icon green"
                to="/dashboard/view-members"
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Total Guest"
                count={adminCount?.totalUsers?.data?.[0]?.total_users}
                body="Guests"
                icon={<SiFiles />}
                color="icon yellow"
                to="/dashboard/view-guest"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Box
                title="Total Ads"
                count={adminCount?.totalAds?.data?.[0]?.total_ads}
                body="Advertiser"
                icon={<HiSpeakerphone />}
                color="icon green"
                to="/dashboard/view-ads"
              />
            </div>
          </div>
        </div>

        {/* row 2 */}

        <div className="boxrow2 mt-lg-4">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="left">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <Box
                      title="Committee"
                      count={
                        adminCount?.totalCommittee?.data?.[0]?.total_committee
                      }
                      body="Members"
                      icon={<HiUserGroup />}
                      color="icon pink"
                      to="/dashboard/view-committee"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <Box
                      title="Productions"
                      count={
                        adminCount?.totalNonMebers?.data?.[0]?.total_nonmebers
                      }
                      body="Mills"
                      icon={<FaBoxes />}
                      color="icon"
                      to="/dashboard/admin/view-mill"
                    />
                  </div>
                </div>
                <div className="pie mt-4">
                  <div className="bg-white rounded-0 chart">
                    <MemberTable data={adminCount?.lastMembers?.data} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="ads h-100 mt-sm-2 mt-md-2">
                <AdsSlider data={adsInfo?.data?.appData} />
              </div>
            </div>
          </div>
        </div>

        {/* row4 */}
        <div className="boxrow2 mt-lg-4">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <EventTable data={adminCount?.lastEvents?.data} />
            </div>
            <div className="col-sm-12 col-lg-6">
              <AdsTable data={adminCount?.lastAds?.data} />
            </div>
          </div>
        </div>

        {/* row4 */}
        {/* <div className="chart">
          <div className="row mt-4">
            <div className="col-sm-12 col-lg-6">
              <div className="bg-white p-3 rounded-2">
                <BarChart chartData={chartOneData} />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 mt-3 mt-lg-0">
              <div className="bg-white p-3 rounded-2 polar text-center">
                <PolarArea data={polardata} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AdminDash;
