import React from "react";
import "./aboutpage.css";
import img1 from "assets/img/abtpage-bg.png";
import img3 from "assets/img/abtpage-box1.png";
import img4 from "assets/img/box-2.png";
import img5 from "assets/img/abtpage-box2.png";
import {MdDoubleArrow} from "react-icons/md";
import bg from "assets/img/abtpage_bg.png";
import Header from "components/main/header/Header";
import useView from "hooks/useView";

const AboutPage = () => {
  const view = useView();
  return (
    <>
      <Header title="About us" />
      <div className={`abtpage ${view}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <img src={img1} alt="logo" className="img-fluid mt-5" />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="content mt-5">
                <div className="row  align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="cotton-image">
                      <img src={img3} alt="2" className="img-fluid" />
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="cotton-box">
                      <p>
                        South India Corrugated Box Manufacturer's Association is
                        an official part of the Corrugated Box Manufacturer's
                        Association for states like Tamil Nadu and Pondicherry.
                        Our prime aim is to develop our position in the market
                        through various activities like information exchange,
                        professional events, and publications that motivate our
                        members to compete in an evolving industry and an
                        environment of global business.
                      </p>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row  align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="cotton-image mt-3">
                      <img src={img4} alt="2" className="img-fluid" />
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="cotton-box mt-3">
                      <p>
                        To get connected with governments to discuss the
                        beneficial measures, and taxation, to the members of
                        corrugated boxes.To assist and guide to organize various
                        classes for sharing the knowledge connected to
                        corrugated boxes.To receive and accept contributions,
                        support, donation, funds, or gift from any Association
                        member
                      </p>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row  align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="cotton-image mt-3">
                      <img src={img5} alt="2" className="img-fluid" />
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="cotton-box mt-3">
                      <p>
                        To frame professional trade practices and apply those
                        conditions in the process of manufacturing from
                        suppliers & consumers.To appoint employees to implement
                        the Association process, to pay wages, bonuses,
                        incentives, and allowances.To regulate the standards
                        required for raw materials, processed goods, and
                        relevant products of the industry by the ISI or other
                        authorities and government agencies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-para mt-5 ">
            <h1 className="text-center">
              <span>OUR</span> VISION <span>&</span> MISSION
            </h1>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <img src={bg} alt="logo" className="img-fluid h-100" />
              </div>
              <div className="col-sm-12 col-md-8">
                <div className="text bg-white rounded-2 p-3">
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To develop the passion of those who got connected with box
                      making industry with the paper corrugating box in the
                      distribution & production of packaging materials.
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To provide the required support to begin an organization
                      for the production of corrugated boxes and to set up
                      markets
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To make complete research on markets nationally and
                      globally, for corrugated boxes
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To get connected with governments to discuss the
                      beneficial measures, and taxation, to the members of
                      corrugated boxes.
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To maintain a complete database and other information
                      related to corrugating boxes
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To assist and guide to organize various classes for
                      sharing the knowledge connected to corrugated boxes
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To launch knowledge-sharing sectors like libraries,
                      schools, institutions, workshops, and seminars, that could
                      be beneficial for people in corrugating box manufacturing.
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To receive and accept contributions, support, donation,
                      funds, or gift from any Association member
                    </p>
                  </div>
                  <div className="d-flex txt">
                    <span>
                      <MdDoubleArrow />
                    </span>
                    <p>
                      To allocate special scholarships or funds to candidates
                      pursuing training in the field.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
