import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import BootstrapTooltip from "react-bootstrap/Tooltip";

const Tooltip = (props) => {
  const renderTooltip = (attProps) => (
    <BootstrapTooltip id="button-tooltip" {...attProps}>
      {props.title}
    </BootstrapTooltip>
  );
  return (
    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
      {props.children}
    </OverlayTrigger>
  );
};

export default Tooltip;
