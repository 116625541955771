import React from "react";
import Header from "components/main/header/Header";
import "./committeepage.css";
import img from "assets/img/profile.jpg";
import useView from "hooks/useView";
import Table from "react-bootstrap/Table";
import Loading from "components/loading/Loading";
import useInternalServer from "hooks/useInternalServer";
import useFetch from "hooks/useFetch";
import CardPagination from "components/CardPagination/CardPagination";
import {SERVER_PUBLIC} from "config/app-config";

const CommitteePage = () => {
  const view = useView();
  const {data, loading, error} = useFetch(`/committee`);
  console.log(data);
  useInternalServer(error);
  if (loading) return <Loading />;
  return (
    <>
      <Header title="Committee" />
      <div className={`committee ${view} container`}>
        <div className="row pb-4 px-1 px-lg-3">
          <CardPagination items={data?.appData} itemsPerPage={8}>
            <Committee />
          </CardPagination>
        </div>
      </div>
    </>
  );
};

const Committee = (props) => {
  console.log(props.currentItems);
  return (
    <>
      {props?.currentItems?.map((committee, i) => (
        <div className="col-sm-12 col-md-6 col-lg-6 " key={i}>
          <div className="bg-white mt-3 rounded  p-lg-3">
            <div className="row">
              <div className="col-sm-12 col-lg-3">
                <div className="text-center">
                  <img
                    src={
                      committee.profile_image
                        ? `${SERVER_PUBLIC}/committee_profile/${committee.profile_image}`
                        : img
                    }
                    alt=""
                    className="img-fluid"
                  />
                  <h5>{committee?.name}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-lg-9">
                <div className="content">
                  <span className="badge bg-danger">{committee.posting}</span>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="body">{committee.email}</td>
                      </tr>
                      <tr>
                        <td className="body">{committee.phone}</td>
                      </tr>
                      <tr>
                        <td className="body">{committee.address}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CommitteePage;

/*
<div className={`committee ${view}`}>
  <div className="container">
    <div className="row mt-2">
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="row">
          <div className="col-sm-12 col-lg-3">
            <div className="text-center">
              <img src={img} alt="" className="img-fluid" />
              <h5>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.name)}
              </h5>
              <p>
                {data?.appData[0] &&
                  toFirstCapital(data?.appData[0]?.posting)}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-lg-9">
            <div className="content">
              <Table borderless>
                <tbody>
                  <tr>
                    <td className="head">Company </td>
                    <td className="body">Sri Packaging</td>
                  </tr>
                  <tr>
                    <td className="head">Email</td>
                    <td className="body">
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.email)}
                    </td>
                  </tr>
                  <tr>
                    <td className="head">Mobile </td>
                    <td className="body">
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.phone)}
                    </td>
                  </tr>
                  <tr>
                    <td className="head">Address</td>

                    <td className="body">
                      {" "}
                      {data?.appData[0] &&
                        toFirstCapital(data?.appData[0]?.address)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */
