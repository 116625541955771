import React from "react";
import Table from "react-bootstrap/Table";
import "../MainDash/Tables/MyTables.css";
import checkDateDiff from "utils/checkDateDiff";

const EventTable = ({data}) => {
  return (
    <div className="mytable h-100">
      <h6 className="mb-3">Events</h6>
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Venue</th>
            <th>Address</th>
            <th>Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td>{items.name}</td>
              <td>{items.venue}</td>
              <td>{items.venue_address}</td>
              <td>{items.price}</td>
              <td>{checkDateDiff(items.date).formatDate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default EventTable;
