import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loader">
      <AiOutlineLoading3Quarters className="spin" />
      <span className="badge danger mt-3">Loading ....</span>
    </div>
  );
};

export default Loading;
