import React from "react";
import Table from "react-bootstrap/Table";
import "../MainDash/Tables/MyTables.css";

const MemberTable = ({data}) => {
  return (
    <div className="mytable ">
      <h6 className="mb-3">Members</h6>
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Company Name</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td>{items.user_name}</td>
              <td>{items.email}</td>
              <td>{items.company_name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MemberTable;
