import privateAPI from "../../api/privateAPI";
import { RAZORPAY_ID } from "../../config/app-config";
import { alertPromise } from "../../components/toast/appAlert";

const makePayment = async (
  Razorpay,
  subscriptionId,
  amount,
  member_id,
  changePayId = null,
  updateRole = null,
  updatePaymentDate = null,
  navigate
) => {
  const order = await privateAPI.post("/make-payment", {
    amount,
    reason: "MEMBER",
    productId: member_id,
  });
  const RazorpayOptions = {
    key: RAZORPAY_ID,
    amount: amount + "00",
    currency: "INR",
    name: "SICBMA",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: order.id,
    handler: async (res) => {
      const { razorpay_payment_id } = res;
      const paymentRes = privateAPI.post("/member-payments", {
        payment_id: razorpay_payment_id,
        member_id: member_id,
        subscriptionId,
      });
      const { data } = await alertPromise(paymentRes);
      changePayId && changePayId(razorpay_payment_id);
      updatePaymentDate(data?.payment_date);
      updateRole && updateRole(["2233", "1122"]);
      navigate("/dashboard");
    },
    prefill: {
      name: "Piyush Garg",
      email: "youremail@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzpay = new Razorpay(RazorpayOptions);
  rzpay.open();
};

export default makePayment;
