import paginationFactory from "react-bootstrap-table2-paginator";

const pagination = (totalData) => {
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 30,
      },
      {
        text: "All",
        value: totalData ? totalData : 0,
      },
    ],
  };

  return paginationFactory(options);
};

export default pagination;
